import React, { useEffect, useState } from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    Checkbox,
    FormGroup,
    Dialog,
    DialogTitle,
    Box,
    Tooltip,
    DialogActions,
    IconButton,
    createTheme,
    ThemeProvider,
    FormControl
} from '@mui/material';
// import FeedbackIcon from '@mui/icons-material/Feedback';
import { deactivateSessionForSlate, getSession, sendChatMessage, updateSlateConfig, uploadFile } from '../whiteboard/src/service/slateService';
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from 'uuid';
import { convertTo24HourFormatForDate } from '../components/utils';
import MicIcon from '@mui/icons-material/Mic';
import { FRONTEND_URL, THEME_COLOR, THEME_HOVER_COLOR, UPLOAD_API_URL } from '../whiteboard/src/config/constants';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import CustomLoader from '../components/CustomLoader/CustomLoader.jsx';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { convertTimeToLocal } from '../components/helperFunctions.jsx';

var chunks = []

const theme1 = createTheme({
    components: {
        // MuiIconButton: {
        //     styleOverrides: {
        //         sizeMedium: {
        //             color,
        //         }
        //     }
        // },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: `${THEME_COLOR} !important`,

                    }
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: THEME_COLOR,
                    fontWeight: 600,
                }
            }
        },
        MuiPickersPopper: {
            styleOverrides: {
                root: {
                    color: THEME_COLOR,
                    fontWeight: 600
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: THEME_COLOR,
                    fontWeight: 400
                },
                shrink: {
                    color: `${THEME_COLOR} !important`,
                    fontWeight: 400
                },
            }
        },

    },

});

const EndSessionStepperForm = (props) => {
    const [activeStep, setActiveStep] = useState(0);
    const [feedback, setFeedback] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedNoOptions, setSelectedNoOptions] = useState([]);
    const [otherReason, setOtherReason] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [recordingTime, setRecordingTime] = React.useState(0);
    const [recordingInterval, setRecordingInterval] = React.useState(null);
    const [isRecording, setIsRecording] = React.useState(false);
    const [selectedHomeworkOption, setSelectedHomeworkOption] = useState('');
    const [selectedHomeworkCompletionOption, setSelectedHomeworkCompletionOption] = useState('');
    const [homeworkNotGivenReason, setHomeworkNotGivenReason] = useState('')
    const [homeworkDescription, setHomeworkDescription] = useState('')
    const mediaRecorderRef = React.useRef(null);
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null)

    useEffect(() => {
        if (recordingTime > 300) {
            stopRecording()
        }
    }, [recordingTime])

    const handleNext = () => {
        if (selectedOption !== '') {
            setActiveStep((prevStep) => prevStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const getSessionByID = async (whiteboardID, accessToken) => {
        let token = accessToken;

        let res, status
        try {
            res = await getSession(token, whiteboardID)
            status = res.status
        }
        catch (err) {
            console.log(err)
            status = err.response.status
        }
        if (status === 200) {
            //console.log(res.data)
            return res.data
        }



        // console.log(userData.data.user.id)

    }

    const handleSubmit = async () => {
        // Perform API call using Axios and pass form data

        if (selectedOption === 'yes' && feedback.trim() === '') {
            alert('Feedback is mandatory. Please provide feedback.');
            return;
        }

        if (selectedOption === 'yes' && selectedHomeworkOption === '') {
            alert('Please answer if the homework was given or not.');
            return;
        }
        if (selectedOption === 'yes' && selectedHomeworkCompletionOption === '') {
            alert('Please answer if the previous homework was completed or not.');
            return;
        }
        if (selectedOption === 'yes' && (selectedHomeworkOption === 'no' && homeworkNotGivenReason === '')) {
            alert('Please specify why the homework was not given.');
            return;
        }
        if (selectedOption === 'yes' && (selectedHomeworkOption === 'yes' && homeworkDescription === '')) {
            alert('Please give a brief description of the homework.');
            return;
        }

        if (selectedOption === 'no' && selectedNoOptions.length === 0) {
            alert('Please select the reason for the session being unsuccessful.');
            return;
        }

        if (selectedOption === 'no' && selectedNoOptions.includes('other') && otherReason.trim() === '') {
            alert('Other field cannot be empty when selecting "Other". Please provide a reason.');
            return;
        }
        handleNext()
        if (props.isCustomToolbar) {
            await props.uploadWhiteboardData()
        }
        else {
            if (props.whiteBoardRef) {
                await props.whiteBoardRef.current.uploadWhiteboardData()
            }
        }

        var inputDate2 = new Date();
        // console.log(inputDate2)
        const messageToSend2 = 'Session: ' + props.slateRef.current.title + ' ended at: ' + (inputDate2).toLocaleDateString() + " " + convertTo24HourFormatForDate(inputDate2)
        // console.log(messageToSend2)
        let status2, res2;
        let messageId2 = uuidv4()
        try {
            res2 = await sendChatMessage(props.accessToken, props.slateID, messageToSend2, null, 'text', null, messageId2)
            status2 = res2.status
        }
        catch (err) {
            console.log(err)
            status2 = err.response.status
        }
        // console.log(res);
        if (status2 === 200) {

        }

        const formData = {
            option: selectedOption,
            feedback: feedback,
            selectedNoOptions: selectedNoOptions,
            otherReason: otherReason,
        };
        let SlateIDObject = {}
        if (selectedOption === 'yes') {
            let notesTemp;
            if (selectedHomeworkOption === 'yes') {
                notesTemp = feedback + ';$%$%$ Homework given to student: ' + selectedHomeworkOption + ';$%$%$ Homework Description: ' + homeworkDescription + ";$%$%$ Did the student complete the previous homework: " + selectedHomeworkCompletionOption
            }
            else {
                notesTemp = feedback + ';$%$%$ Homework given to student: ' + selectedHomeworkOption + ';$%$%$ Reason for not giving homework: ' + homeworkNotGivenReason + ";$%$%$ Did the student complete the previous homework: " + selectedHomeworkCompletionOption
            }


            SlateIDObject = {
                "slateId": props.slateID,
                "sessionId": props.whiteBoardID,
                'notes': notesTemp,
                "issue": "NONE"
            }
            let session = await getSessionByID(props.whiteBoardID, props.accessToken)
            // console.log(session.start_time)
            let inputDate = new Date(session.start_time);
            let current
            let messageToSend = 'Session summary: $%$%$' + "Start Time: " + (inputDate).toLocaleDateString() + " " + convertTo24HourFormatForDate(inputDate) + '    ' + '$%$%$' + "End Time: " + (inputDate2).toLocaleDateString() + " " + convertTo24HourFormatForDate(inputDate2) + '    ' + '$%$%$' + notesTemp + '    ' + '$%$%$' + 'Class Notes: ' + FRONTEND_URL + 'downloadCanvas/' + props.whiteBoardID;
            // console.log(messageToSend)
            let status1, res1;
            let messageId = uuidv4()
            try {
                res1 = await sendChatMessage(props.accessToken, props.slateID, messageToSend, null, 'session_feedback', null, messageId)
                status1 = res1.status
            }
            catch (err) {
                console.log(err)
                status1 = err.response.status
            }
            // console.log(res);
            if (status1 === 200) {

            }
            let formattedDate;
            if (date) {
                const newDate = new Date(date)
                const day = String(newDate.getDate()).padStart(2, '0'); // Ensures two digits
                const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                const year = newDate.getFullYear();
                formattedDate = `${day}/${month}/${year}`;
            }
            else {
                formattedDate = null
            }

            const slateConfigUpdates = [
                {
                    "path": ["nextClass", "date"],
                    "value": formattedDate
                },
                {
                    "path": ["nextClass", "time"],
                    "value": time
                },
                {
                    "path": ["last_session"],
                    "value": props.whiteBoardID
                },
                {
                    "path": ["session_state"],
                    "value": window.lesson.sessionState
                }
            ];
            console.log('updating config', slateConfigUpdates)
            await updateSlateConfig(props.slateID, slateConfigUpdates)
            console.log('updated')
        }
        else {
            let issueTemp = ''
            if (selectedNoOptions.includes('other')) {
                issueTemp = otherReason
            }
            else if (selectedNoOptions.includes('audio_video_not_working')) {
                issueTemp = 'Audio/Video was not working properly'
            }
            else if (selectedNoOptions.includes('whiteboard_related_issue')) {
                issueTemp = 'There were some issues with the whiteboard'
            }
            else if (selectedNoOptions.includes('student_no_show')) {
                issueTemp = 'Student did not turn up'
            }
            SlateIDObject = {
                "slateId": props.slateID,
                "sessionId": props.whiteBoardID,
                'notes': "NONE",
                "issue": issueTemp
            }
            let session = await getSessionByID(props.whiteBoardID, props.accessToken)
            let inputDate = new Date(session.start_time);
            let messageToSend = 'Session Unsuccessful: $%$%$' + "Start Time: " + (inputDate).toLocaleDateString() + " " + convertTo24HourFormatForDate(inputDate) + '    ' + '$%$%$' + issueTemp
            // console.log(messageToSend)
            let status1, res1;
            let messageId = uuidv4()
            try {
                res1 = await sendChatMessage(props.accessToken, props.slateID, messageToSend, null, 'session_feedback', null, messageId)
                status1 = res1.status
            }
            catch (err) {
                console.log(err)
                status1 = err.response.status
            }
            // console.log(res);
            if (status1 === 200) {

            }
            const slateConfigUpdates = [
                {
                    "path": ["last_session"],
                    "value": props.whiteBoardID
                },
                {
                    "path": ["session_state"],
                    "value": window.lesson.sessionState
                }
            ];
            console.log('updating config', slateConfigUpdates)
            await updateSlateConfig(props.slateID, slateConfigUpdates)
            console.log('updated')
        }



        // console.log(SlateIDObject)
        let status, res;
        try {
            res = await deactivateSessionForSlate(props.accessToken, SlateIDObject)
            status = res.status
        }
        catch (err) {
            console.log(err)
            status = err.response.status
        }
        // console.log(res);
        if (status === 200) {
            // setTimeout(() => {
            //   window.top.close()
            // }, 1500);
            // setTimeout(() => {
            window.open(FRONTEND_URL + 'slateDashboard', '_self')
            // }, 2000)

        }
        else if (status === 401) {
            alert("You are not authorized to End Session")
        }
        else {
            console.log(status);

        }
        // console.log(formData);
        props.setSessionReport(false);
    };

    const radioOptions = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
    ];

    const multiSelectOptions = [
        { value: 'student_no_show', label: 'Student did not turn up' },
        { value: 'audio_video_not_working', label: 'Audio/Video not working' },
        { value: 'whiteboard_related_issue', label: 'Whiteboard related issue' },
        { value: 'other', label: 'Other' },
    ];

    const homeworkOptions = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
    ];

    const homeworkCompletionOptions = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
        { value: 'N/A', label: 'N/A' },
    ];

    const handleMessageSend = async (messageId, messageType, fileName, fileLink, totalAudioDuration) => {

        let token = props.accessToken
        let textValue = totalAudioDuration + ''
        let status, res;
        try {
            res = sendChatMessage(token, props.slateID, textValue, fileName, messageType, fileLink, messageId)
            status = res.status
        }
        catch (err) {
            // console.log(err)
            status = err.response.status
        }
        if (status === 200) {

            // console.log(messageObject)
            // console.log(messageArray)

        }

    }

    const handleDate = (value) => {
        // const newDate = new Date(value)
        // convertTimeToLocal(value)
        // console.log(newDate)
        setDate(value)
    }

    const startRecording = async () => {
        //   console.log("starting recording")
        chunks = []
        let stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
        setIsRecording(true);
        var start = 0;
        var timer = setInterval(() => {
            start += 1;
            setRecordingTime(start);
        }, 1000);
        setRecordingInterval(timer);


        let options = {
            type: 'audio',
            timeSlice: 10000,
            mimeType: 'audio/wav'
        };
        try {
            mediaRecorderRef.current = new MediaRecorder(stream);
            // console.log('media recorder')
        } catch (e0) {
            // console.log('Unable to create MediaRecorder with options    Object: ', e0);
            try {
                options = { mimeType: 'video/webm,codecs=vp9' };
                mediaRecorderRef.current = new MediaRecorder(stream, options);
            } catch (e1) {
                // console.log('Unable to create MediaRecorder');
                try {
                    options = 'video/vp8'; // Chrome 47
                    mediaRecorderRef.current = new MediaRecorder(stream, options);
                } catch (e2) {
                    alert('MediaRecorder is not supported by this browser');
                    return;
                }
            }
        }

        mediaRecorderRef.current.ondataavailable = (event) => {
            // console.log('ondataavailable')
            if (event.data && event.data.size > 0) {
                // console.log('pushing data')
                chunks.push(event.data)
            }
        };
        // console.log("now register on data available")
        mediaRecorderRef.current.start(100);


    }

    const stopRecording = async () => {
        // console.log("we are stopping the recording now so the chunks will again start from 1 once we starrt the recording");
        setIsRecording(false);
        // setIsAudioNoteLoading(true)
        clearInterval(recordingInterval);
        let totalAudioDuration = recordingTime
        setRecordingTime(0)
        let audioFileId = uuidv4()
        const uploadFileName = 'audio_note_' + props.slateID + '_' + audioFileId + '.wav';
        // console.log("file name " + uploadFileName)
        // console.log(chunks)
        if (chunks.length > 1) {
            const blobObj = new Blob(chunks, { type: "audio/mp3" });
            // console.log(blobObj)
            const voiceNoteDataObj = new File([blobObj], uploadFileName, { type: "audio/mp3" });
            const audioData = new FormData();
            audioData.append('file', voiceNoteDataObj, uploadFileName);

            let messageId = uuidv4()
            let status, res;
            try {
                res = await uploadFile(props.accessToken, audioData);
                status = res.status
            }

            catch (err) {
                console.log(err);
                status = err.response.status;
            }
            // console.log(index)
            if (status === 200) {
                let fileLink = UPLOAD_API_URL + 'uploads/' + props.clientID + '/' + uploadFileName
                // setIsChatLoading(false)
                handleMessageSend(messageId, 'audio', uploadFileName, fileLink, totalAudioDuration)
            }
            else {
                console.log(status)
            }
            // handleMessageSend('audio', uploadFileName, audio)
        }
        mediaRecorderRef.current.stop();
    }

    const stepComponents = [
        // Step 1
        <div>
            <Typography sx={{ fontSize: '1.1rem', marginBottom: '5px' }}>
                Was the session conducted successfully?
            </Typography>
            <RadioGroup
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}

            >
                {radioOptions.map((option) => (
                    <FormControlLabel key={option.value} value={option.value} control={<Radio
                        sx={{
                            '&.Mui-checked': {
                                color: THEME_COLOR,
                            },
                        }}
                    />} label={option.label} />
                ))}
            </RadioGroup>
        </div>,
        // Step 2
        selectedOption === 'yes' ? (
            <div>
                <Typography sx={{ fontSize: '1.1rem', marginBottom: '5px' }}>
                    Which topics were covered during the session?
                </Typography>
                <TextField
                    // label="Feedback"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderRadius: '5px',
                            },
                            '&:hover fieldset': {
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: `${THEME_COLOR} !important`,
                            },
                        },
                    }}
                    multiline
                    rows={4}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    fullWidth
                    onFocus={() => {
                        props.isTextFocused.current = true;
                    }}
                    onBlur={() => {
                        props.isTextFocused.current = false;
                    }}
                />
                {/* Homework given */}
                <Typography variant="h6" sx={{ fontSize: '1.1rem', marginBottom: '0px', marginTop: '15px' }}>
                    Was any homework given to the student?
                </Typography>
                <RadioGroup
                    value={selectedHomeworkOption}
                    onChange={(e) => setSelectedHomeworkOption(e.target.value)}
                    sx={{ display: 'flex', flexDirection: 'row' }}
                >
                    {homeworkOptions.map((option) => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio
                                sx={{
                                    '&.Mui-checked': {
                                        color: THEME_COLOR,
                                    },
                                }}
                            />}
                            label={option.label}
                        />
                    ))}
                </RadioGroup>
                {selectedHomeworkOption === 'no' && (
                    <div style={{ marginTop: '5px' }}>
                        {/* <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                                Describe the reason:
                            </Typography> */}
                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderRadius: '5px',
                                    },
                                    '&:hover fieldset': {
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: `${THEME_COLOR} !important`,
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: { color: THEME_COLOR },
                            }}
                            label="Specify the reason for not giving the homework"
                            value={homeworkNotGivenReason}
                            onChange={(e) => setHomeworkNotGivenReason(e.target.value)}
                            multiline
                            rows={4}
                            fullWidth
                            onFocus={() => {
                                props.isTextFocused.current = true;
                            }}
                            onBlur={() => {
                                props.isTextFocused.current = false;
                            }}
                        />
                    </div>
                )}
                {selectedHomeworkOption === 'yes' && (
                    <div style={{ marginTop: '5px' }}>
                        {/* <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                                Describe the reason:
                            </Typography> */}
                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderRadius: '5px',
                                    },
                                    '&:hover fieldset': {
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: `${THEME_COLOR} !important`,
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: { color: THEME_COLOR },
                            }}
                            label="Brief description of homework"
                            value={homeworkDescription}
                            onChange={(e) => setHomeworkDescription(e.target.value)}
                            multiline
                            rows={4}
                            fullWidth
                            onFocus={() => {
                                props.isTextFocused.current = true;
                            }}
                            onBlur={() => {
                                props.isTextFocused.current = false;
                            }}
                        />
                    </div>
                )}
                {/* Homework completion */}

                <div>
                    <Typography sx={{ fontSize: '1.1rem', marginBottom: '0px', marginTop: '15px' }}>
                        Did the student complete their previous homework?
                    </Typography>
                    <RadioGroup
                        value={selectedHomeworkCompletionOption}
                        onChange={(e) => setSelectedHomeworkCompletionOption(e.target.value)}
                        sx={{ display: 'flex', flexDirection: 'row' }}
                    >
                        {homeworkCompletionOptions.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio
                                    sx={{
                                        '&.Mui-checked': {
                                            color: THEME_COLOR,
                                        },
                                    }}
                                />}
                                label={option.label}
                            />
                        ))}
                    </RadioGroup>
                </div>

                {/* <Typography sx={{ fontSize: '1.1rem', marginBottom: '15px', marginTop: '15px' }}>
                    Schedule Next Session :
                </Typography>

                <Box sx={{ width: '100%', marginBottom: '15px', marginTop: '5px' }} >
                    <ThemeProvider theme={theme1}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            localeText={{
                                okButtonLabel: <span style={{ color: THEME_COLOR }}>Ok</span>,
                                clearButtonLabel: <span style={{ color: THEME_COLOR }}>Clear</span>
                            }}
                        >
                            <DatePicker
                                value={date}
                                label="Date"
                                sx={{
                                    minWidth: '100%',
                                    width: '100%',
                                    color: THEME_COLOR,
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#A2A2A2 !important',

                                            borderRadius: '5px',

                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'black !important',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: `${THEME_COLOR} !important`,
                                        },

                                    },

                                }}
                                views={['day', 'month', 'year']}
                                slotProps={{

                                    actionBar: {
                                        actions: ['clear', 'accept'],

                                    },

                                }}
                                onChange={handleDate}
                            />
                        </LocalizationProvider>
                    </ThemeProvider>
                </Box>
                <Box sx={{ width: '100%', marginBottom: '15px', marginTop: '5px' }} >
                    <TextField
                        id="date"
                        type="time"
                        label='Time'
                        value={time}
                        sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: '5px',

                                    height: '60px',
                                },
                                '&:hover fieldset': {
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: `${THEME_COLOR} !important`,
                                },
                            },
                            '&.Mui-focused fieldset': {

                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: { color: THEME_COLOR }
                        }}
                        onChange={(e) => {
                            setTime(e.currentTarget.value)
                        }}
                    />
                </Box> */}

                {/* <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'right', marginTop: '10px' }}>
                    {!isRecording ? <Tooltip title="Start recording">
                        <>
                            <IconButton sx={{
                                display: 'flex',
                                backgroundColor: 'green',
                                color: '#ffffff',
                                padding: '10px',
                                // borderRadius: '10px',
                                // boxShadow: 1, zIndex: 100,
                                textTransform: 'none',
                                fontSize: "0.85rem",
                                // marginLeft: '10px',
                                "&:hover": { backgroundColor: "green" }
                            }}
                                onClick={startRecording}
                                size="small"
                            >
                                <MicIcon />
                            </IconButton>
                        </>
                    </Tooltip> :
                        <Tooltip title="Stop recording">
                            <>
                                <Button
                                    sx={{
                                        display: 'flex',
                                        animationName: '$blinker',
                                        animationDuration: '1s',
                                        animationTimingFunction: 'linear',
                                        animationIterationCount: 'infinite',
                                        color: "#ffffff",
                                        backgroundColor: "red",
                                        padding: '7px 6px',
                                        // borderRadius: '10px',
                                        // boxShadow: 1,
                                        // zIndex: 100,
                                        fontSize: { xs: "0.75rem", sm: "0.75rem", md: "0.85rem", lg: "0.85rem" },
                                        textTransform: 'none',
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                        "&:hover": { backgroundColor: "rgb(255,0,0,0.6)" }
                                    }}
                                    onClick={stopRecording}
                                >
                                    {" " + recordingTime + ' '}&nbsp;<PauseCircleOutlineIcon />
                                </Button>
                            </>
                        </Tooltip>}
                </Box> */}

            </div>
        ) : (
            selectedOption === 'no' && (
                <div>
                    <Typography sx={{ fontSize: '1.2rem', marginBottom: '10px' }}>
                        Did you face any of the following issues?
                    </Typography>
                    <RadioGroup
                        value={selectedNoOptions[0] || ''}
                        onChange={(e) => setSelectedNoOptions([e.target.value])}
                    >
                        {multiSelectOptions.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio
                                    sx={{
                                        '&.Mui-checked': {
                                            color: THEME_COLOR,
                                        },
                                    }}
                                />}
                                label={option.label}
                            />
                        ))}
                    </RadioGroup>
                    {selectedNoOptions.includes('other') && (
                        <div>
                            <Typography sx={{ fontSize: '1.1rem', marginBottom: '5px' }}>
                                Describe the reason:
                            </Typography>
                            <TextField
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '5px',
                                        },
                                        '&:hover fieldset': {
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: `${THEME_COLOR} !important`,
                                        },
                                    },
                                }}
                                InputLabelProps={{
                                    style: { color: THEME_COLOR },
                                }}
                                label="Specify other reason"
                                value={otherReason}
                                onChange={(e) => setOtherReason(e.target.value)}
                                multiline
                                rows={4}
                                fullWidth
                                onFocus={() => {
                                    props.isTextFocused.current = true;
                                }}
                                onBlur={() => {
                                    props.isTextFocused.current = false;
                                }}
                            />
                        </div>
                    )}
                </div>
            )
        ),
    ];

    const stepComponents2 = [
        // Step 1
        <div>
            <Typography sx={{ fontSize: '1.1rem', marginBottom: '5px' }}>
                Was the session conducted successfully?
            </Typography>
            <RadioGroup
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}

            >
                {radioOptions.map((option) => (
                    <FormControlLabel key={option.value} value={option.value} control={<Radio
                        sx={{
                            '&.Mui-checked': {
                                color: THEME_COLOR,
                            },
                        }}
                    />} label={option.label} />
                ))}
            </RadioGroup>
        </div>

        ,

        // Step 2
        selectedOption === 'yes' ? (
            <div>
                <Typography sx={{ fontSize: '1.1rem', marginBottom: '5px' }}>
                    Which topics were covered during the session?
                </Typography>
                <TextField
                    // label="Feedback"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderRadius: '5px',
                            },
                            '&:hover fieldset': {
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: `${THEME_COLOR} !important`,
                            },
                        },
                    }}
                    multiline
                    rows={4}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    fullWidth
                    onFocus={() => {
                        props.isTextFocused.current = true;
                    }}
                    onBlur={() => {
                        props.isTextFocused.current = false;
                    }}
                />
                <Typography sx={{ fontSize: '1.1rem', marginBottom: '0px', marginTop: '15px' }}>
                    Was any homework given to the student?
                </Typography>
                <RadioGroup
                    value={selectedHomeworkOption}
                    onChange={(e) => setSelectedHomeworkOption(e.target.value)}
                    sx={{ display: 'flex', flexDirection: 'row' }}
                >
                    {homeworkOptions.map((option) => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio
                                sx={{
                                    '&.Mui-checked': {
                                        color: THEME_COLOR,
                                    },
                                }}
                            />}
                            label={option.label}
                        />
                    ))}
                </RadioGroup>
                {/* Homework completion */}
                {selectedHomeworkOption === 'no' && (
                    <div style={{ marginTop: '5px' }}>
                        {/* <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                                Describe the reason:
                            </Typography> */}
                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderRadius: '5px',
                                    },
                                    '&:hover fieldset': {
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: `${THEME_COLOR} !important`,
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: { color: THEME_COLOR },
                            }}
                            label="Specify the reason for not giving the homework"
                            value={homeworkNotGivenReason}
                            onChange={(e) => setHomeworkNotGivenReason(e.target.value)}
                            multiline
                            rows={4}
                            fullWidth
                            onFocus={() => {
                                props.isTextFocused.current = true;
                            }}
                            onBlur={() => {
                                props.isTextFocused.current = false;
                            }}
                        />
                    </div>
                )}
                {selectedHomeworkOption === 'yes' && (
                    <div style={{ marginTop: '5px' }}>
                        {/* <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                                Describe the reason:
                            </Typography> */}
                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderRadius: '5px',
                                    },
                                    '&:hover fieldset': {
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: `${THEME_COLOR} !important`,
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: { color: THEME_COLOR },
                            }}
                            label="Brief description of homework"
                            value={homeworkDescription}
                            onChange={(e) => setHomeworkDescription(e.target.value)}
                            multiline
                            rows={4}
                            fullWidth
                            onFocus={() => {
                                props.isTextFocused.current = true;
                            }}
                            onBlur={() => {
                                props.isTextFocused.current = false;
                            }}
                        />
                    </div>
                )}
                <div>
                    <Typography sx={{ fontSize: '1.1rem', marginBottom: '0px', marginTop: '15px' }}>
                        Did the student complete their previous homework?
                    </Typography>
                    <RadioGroup
                        value={selectedHomeworkCompletionOption}
                        onChange={(e) => setSelectedHomeworkCompletionOption(e.target.value)}
                        sx={{ display: 'flex', flexDirection: 'row' }}
                    >
                        {homeworkCompletionOptions.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio
                                    sx={{
                                        '&.Mui-checked': {
                                            color: THEME_COLOR,
                                        },
                                    }}
                                />}
                                label={option.label}
                            />
                        ))}
                    </RadioGroup>
                </div>
                {/* <Typography sx={{ fontSize: '1.1rem', marginBottom: '15px', marginTop: '15px' }}>
                    Schedule Next Session :
                </Typography>

                <Box sx={{ width: '100%', marginBottom: '15px', marginTop: '5px' }} >
                    <ThemeProvider theme={theme1}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            localeText={{
                                okButtonLabel: <span style={{ color: THEME_COLOR }}>Ok</span>,
                                clearButtonLabel: <span style={{ color: THEME_COLOR }}>Clear</span>
                            }}
                        >
                            <DatePicker
                                value={date}
                                label="Date"
                                sx={{
                                    minWidth: '100%',
                                    width: '100%',
                                    color: THEME_COLOR,
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#A2A2A2 !important',

                                            borderRadius: '5px',

                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'black !important',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: `${THEME_COLOR} !important`,
                                        },

                                    },

                                }}
                                views={['day', 'month', 'year']}
                                slotProps={{

                                    actionBar: {
                                        actions: ['clear', 'accept'],

                                    },

                                }}
                                onChange={handleDate}
                            />
                        </LocalizationProvider>
                    </ThemeProvider>
                </Box>
                <Box sx={{ width: '100%', marginBottom: '15px', marginTop: '5px' }} >
                    <TextField
                        id="date"
                        type="time"
                        label='Time'
                        value={time}
                        sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: '5px',

                                    height: '60px',
                                },
                                '&:hover fieldset': {
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: `${THEME_COLOR} !important`,
                                },
                            },
                            '&.Mui-focused fieldset': {

                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: { color: THEME_COLOR }
                        }}
                        onChange={(e) => {
                            setTime(e.currentTarget.value)
                        }}
                    />
                </Box> */}
            </div>
        ) : (
            selectedOption === 'no' && (
                <div>
                    <Typography sx={{ fontSize: '1.2rem', marginBottom: '10px' }}>
                        Did you face any of the following issues?
                    </Typography>
                    <RadioGroup
                        value={selectedNoOptions[0] || ''}
                        onChange={(e) => setSelectedNoOptions([e.target.value])}
                    >
                        {multiSelectOptions.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio
                                    sx={{
                                        '&.Mui-checked': {
                                            color: THEME_COLOR,
                                        },
                                    }}
                                />}
                                label={option.label}
                            />
                        ))}
                    </RadioGroup>
                    {selectedNoOptions.includes('other') && (
                        <div>
                            <Typography sx={{ fontSize: '1.1rem', marginBottom: '5px' }}>
                                Describe the reason:
                            </Typography>
                            <TextField
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '5px',
                                        },
                                        '&:hover fieldset': {
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: `${THEME_COLOR} !important`,
                                        },
                                    },
                                }}
                                InputLabelProps={{
                                    style: { color: THEME_COLOR },
                                }}
                                label="Specify other reason"
                                value={otherReason}
                                onChange={(e) => setOtherReason(e.target.value)}
                                multiline
                                rows={4}
                                fullWidth
                                onFocus={() => {
                                    props.isTextFocused.current = true;
                                }}
                                onBlur={() => {
                                    props.isTextFocused.current = false;
                                }}
                            />
                        </div>
                    )}
                </div>
            )
        ),
    ];

    const contentOfForm1 = <><DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', color: THEME_COLOR, fontSize: '2rem', fontWeight: 550 }}>
        Session Feedback
        {activeStep !== stepComponents.length && <IconButton
            sx={{ color: 'black', position: 'absolute', right: '5px', top: "5px", zIndex: 10000 }}
            onClick={() => {
                props.setSessionReport(false)
            }}
            size="small">
            <CloseIcon />
        </IconButton>}
    </DialogTitle>
        <Box sx={{ padding: activeStep === stepComponents.length ? '40px 60px' : '0px 60px 20px' }}>
            {activeStep === stepComponents.length ? (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ marginBottom: '10px', color: '#000000', textAlign: 'center' }}>
                        Thank you for your feedback!<br></br>
                        Ending the session...
                    </Typography>
                    <CustomLoader />
                </div>
            ) : (
                <div>
                    <Typography sx={{ marginBottom: '20px' }}>{stepComponents[activeStep]}</Typography>
                </div>
            )}
        </Box>
        <DialogActions>
            {activeStep !== stepComponents.length && (
                <Button disabled={activeStep === 0} onClick={handleBack} sx={{ marginRight: '10px', color: THEME_COLOR }}>
                    Back
                </Button>
            )}
            {activeStep === stepComponents.length ? (
                <></>
            ) : (
                <Button variant="contained" onClick={activeStep === stepComponents.length - 1 ? handleSubmit : handleNext} sx={{ backgroundColor: THEME_COLOR, '&:hover': { backgroundColor: THEME_HOVER_COLOR, boxShadow: 'none' } }}>
                    {activeStep === stepComponents.length - 1 ? 'Submit and End Session' : 'Next'}
                </Button>
            )}
        </DialogActions>
    </>

    const contentOfForm2 = <><DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', color: THEME_COLOR, fontSize: '2rem', fontWeight: 550 }}>
        Session Report
        {activeStep !== stepComponents2.length && <IconButton
            sx={{ color: 'black', position: 'absolute', right: '5px', top: "5px", zIndex: 10000 }}
            onClick={() => {
                props.setSessionReport(false)
            }}
            size="small">
            <CloseIcon />
        </IconButton>}
    </DialogTitle>
        <Box sx={{ padding: activeStep === stepComponents.length ? '40px 60px' : '20px 60px 40px' }}>
            {activeStep === stepComponents2.length ? (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ marginBottom: '10px', color: '#000000', textAlign: 'center' }}>
                        Thank you for your feedback!<br></br>
                        Ending the session...
                    </Typography>
                    <CustomLoader />
                </div>
            ) : (
                <div>
                    <Typography sx={{ marginBottom: '20px' }}>{stepComponents2[activeStep]}</Typography>
                </div>
            )}
        </Box>
        <DialogActions>
            {activeStep !== stepComponents2.length && (
                <Button disabled={activeStep === 0} onClick={handleBack} sx={{ marginRight: '10px', color: THEME_COLOR }}>
                    Back
                </Button>
            )}
            {activeStep === stepComponents2.length ? (
                <></>
            ) : (
                <Button variant="contained" onClick={activeStep === stepComponents2.length - 1 ? handleSubmit : handleNext} sx={{ backgroundColor: THEME_COLOR, '&:hover': { backgroundColor: THEME_HOVER_COLOR, boxShadow: 'none' } }}>
                    {activeStep === stepComponents2.length - 1 ? 'Submit and End Session' : 'Next'}
                </Button>
            )}
        </DialogActions>
    </>
    return (
        <div>
            {/* {props.sessionReport && <Tooltip title='Ask for feedback'>
                <IconButton
                    sx={{ color: THEME_COLOR, position: 'fixed', left: '15px', top: '10%', zIndex: 100 }}
                    size="large"
                    onClick={handleOpenDialog}>
                    <FeedbackIcon />
                </IconButton>
            </Tooltip>} */}
            <Dialog open={props.sessionReport}  >
                {true ? contentOfForm1 : contentOfForm2}
            </Dialog>
        </div>
    );
};

export default EndSessionStepperForm;
