import React from "react"
import Whiteboard from "../../whiteboard";
import { getLastUpdatedEventsInAscending } from "../../whiteboard/src/utils";

import { IconButton } from "@mui/material";
import Forward5Icon from '@mui/icons-material/Forward5';
import Replay5Icon from '@mui/icons-material/Replay5';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import moment from "moment";
import * as fabric from 'fabric'
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { getWhiteboardAudioBlob } from "../../services/recordingService";
import { v4 as uuidv4 } from 'uuid';
import { THEME_COLOR } from "../../whiteboard/src/config/constants";


const getQueryObject = (search) => {
   var query;
   var queryFields
   if (search && search.length > 0) {
      query = search.substr(1);
      queryFields = query.split("&")
   }
   var queryObject = {}
   if (queryFields && queryFields.length > 0) {
      for (var queryField of queryFields) {
         var arr = queryField.split("=")
         if (arr && arr.length === 2) {
            queryObject[arr[0]] = arr[1];
         }
      }
   }
   return queryObject;
}


class WhiteBoardPlayer extends React.Component {
   constructor(props) {
      super(props);
      let currentUrl = window.location.href;
      let index = currentUrl.indexOf('?');
      const search = currentUrl.substr(index);
      const queryObject = getQueryObject(search);
      this.state = {
         whiteBoardID: queryObject["whiteBoardID"],
         clientID: queryObject["clientID"],
         accessToken: queryObject["accessToken"],
         whiteBoardEvents: [],
         playedDuration: 0,
         whiteBoardEventsWaitingTimes: [],
         totalDuration: 0,
         canvasImageObjects: [],
         totalDuration: 1,
         isPlaying: false,
         audioTimeIntervals: [],
         blobsGroupedByBlobIDInSequence: [],
         isAudioLoaded: false,
         isEventsLoaded: false,
         eventsWaitingTime: [],
         playedEventsWithoutTimeout: false,
         enablePlayButton: false,
         audioTimeout: {},
         whiteBoardEventsTimeouts: [],
         playInterval: null,
         selectedAudioRecordingsIndex: 0,
         urlBlobBatchID: queryObject["blobBatchID"],
         loadedUrlBlobBatchID: false,
         isPlayingEventsWithoutTimout: false,
         isLoading: false
      }
      this.play = this.play.bind(this);
      this.pause = this.pause.bind(this);
      this.increasePlayDuration = this.increasePlayDuration.bind(this);
      this.playEventsWithoutTimeout = this.playEventsWithoutTimeout.bind(this);
      this.handleChangeAudioRecording = this.handleChangeAudioRecording.bind(this);
      this.forwardPlay = this.forwardPlay.bind(this);
      this.reversePlay = this.reversePlay.bind(this);
      this.changePlayedDuration = this.changePlayedDuration.bind(this);
   }

   componentDidMount() {
      const whiteBoard = this._whiteBoard;
      // whiteBoard.handleChangeLoading(true);
      async function loadAudio() {

         let audioRecordingBlobs;
         //Inside try catch to check whether the events are in minio or in s3

         try {
            audioRecordingBlobs = await this.getAudioRecordingBlobsByRoomNameFromDB(this.state.whiteBoardID, this.state.clientID, this.state.accessToken);
         }
         catch (err) {
            // audioRecordingBlobs = await this.getAudioRecordingBlobsByRoomNameAndClientId(this.state.whiteBoardID,this.state.clientID);
         }

         //var audioRecordingBlobs =
         // console.log("audioRecordingBlobs" + audioRecordingBlobs.length)
         var blobsGroupedByBlobIDInSequence = await this.getBlobsGroupedByBlobIDInSequence(audioRecordingBlobs);
         var audioTimeIntervals = this.getAudioTimeIntervals(blobsGroupedByBlobIDInSequence);
         var totalDuration = 0;

         if (blobsGroupedByBlobIDInSequence.length > 0) {
            totalDuration = blobsGroupedByBlobIDInSequence[0].duration;
         }
         // console.log("total duration is " + totalDuration + "audio time ");
         this.setState({
            audioTimeIntervals: audioTimeIntervals,
            blobsGroupedByBlobIDInSequence: blobsGroupedByBlobIDInSequence,
            isAudioLoaded: true,
            totalDuration: totalDuration,
         });

      }
      async function loadEvents() {
         //Inside try catch to check whether the events are in db or in dynamo
         this.setState({
            isLoading: true
         })
         var allEvents;
         // whiteBoard.getCanvasFromPreviousWhiteboard( this.state.accessToken)
         try {

            allEvents = await whiteBoard.getAllEventsFromDB(this.state.whiteBoardID, "server1.slatemates.in", this.state.accessToken,);
         }
         catch (err) {
            allEvents = await whiteBoard.getAllEvents(this.state.whiteBoardID);
         }
         // console.log('alleventf', allEvents)
         // let eventDataOfFirstEvent = JSON.parse(allEvents[0].eventData);
         // console.log('alleventf', eventDataOfFirstEvent)
         let response = await whiteBoard.getUrlOfPreviousWhiteboard(this.state.accessToken)
         // console.log('alleventf', response.previousWhiteboardUrl)
         const eventTypePreviousWhiteboard = 'file:added'
         let objectIDofPreviousWhiteboard = uuidv4();
         const eventDataPreviousWHiteboard = {
            'canvasFileUrl': response.previousWhiteboardUrl,
            'canvasFileUrl2': response.previousWhiteboardUrl2,
            'timestamp': response.sessionStartTime,
            'objectIdSuffix': ''
         }
         allEvents.unshift({
            'eventType': eventTypePreviousWhiteboard,
            'eventData': JSON.stringify(eventDataPreviousWHiteboard),
            'objectID': objectIDofPreviousWhiteboard,
            'clientID': this.state.clientID,
            'whiteBoardID': this.state.whiteBoardID
         })
         console.log('alleventf', allEvents)
         var imageObjects = await whiteBoard.getCanvasImageObjects(allEvents);
         this.setState({
            canvasImageObjects: imageObjects,
            whiteBoardEvents: allEvents,
            isEventsLoaded: true,
            isLoading: false
         })
      }
      loadAudio.bind(this)();
      loadEvents.bind(this)();

   }

   componentDidUpdate(prevProps, prevState) {
      // const whiteBoard = this._whiteBoard;
      if (prevState.isEventsLoaded !== this.state.isEventsLoaded) {
         console.log("whiteBoardPlayer:whiteboardEvents", this.state.whiteBoardEvents, this.state.canvasImageObjects)
      }
      if (prevState.isAudioLoaded !== this.state.isAudioLoaded) {
         console.log("whiteBoardPlayer:audio", this.state.audioTimeIntervals, this.state.blobsGroupedByBlobIDInSequence)
      }
      console.log("whiteBoardPlayer:componentDidUpdate state", this.state)
      // var isEventsAndAudioLoadedPrevState = prevState.isAudioLoaded && prevState.isEventsLoaded;
      var isEventsAndAudioLoaded = this.state.isEventsLoaded && this.state.isAudioLoaded;
      var playedEventsWithoutTimeout = this.state.playedEventsWithoutTimeout;
      const { playedDuration, totalDuration } = this.state;
      if (isEventsAndAudioLoaded && this.state.urlBlobBatchID && !this.state.loadedUrlBlobBatchID) {
         const { blobsGroupedByBlobIDInSequence, urlBlobBatchID } = this.state;
         var foundBlobSeq = false;
         for (var i = 0; i < blobsGroupedByBlobIDInSequence.length; i++) {
            if (blobsGroupedByBlobIDInSequence[i].blobBatchID === urlBlobBatchID) {
               this.setState({
                  selectedAudioRecordingsIndex: i,
                  totalDuration: blobsGroupedByBlobIDInSequence[i].duration,
                  loadedUrlBlobBatchID: true,
               })
               foundBlobSeq = true;
            }
         }
         if (!foundBlobSeq)
            this.setState({ loadedUrlBlobBatchID: true, blobsGroupedByBlobIDInSequence: [], totalDuration: 0 });
      }
      else if (isEventsAndAudioLoaded) {
         // whiteBoard.handleChangeLoading(false);
         console.log("whiteBoardPlayer:componentDidUpdate eventsloaded", isEventsAndAudioLoaded, playedEventsWithoutTimeout)
         if (playedEventsWithoutTimeout && !this.state.enablePlayButton)
            this.setState({ enablePlayButton: true })
         if (!playedEventsWithoutTimeout && playedDuration < totalDuration && !this.state.isPlayingEventsWithoutTimout) {
            console.log("whiteBoardPlayer:componentDidUpdate calling playEventsWithoutTimeout")
            this.setState({ isPlayingEventsWithoutTimout: true });
            this.playEventsWithoutTimeout();
         }
      }

      if (this.state.blobsGroupedByBlobIDInSequence.length === 0) {
         return;
      }
      if (this.state.playedDuration >= this.state.totalDuration) {
         clearInterval(this.state.playInterval)
         this.setState({ playedDuration: 0, playInterval: null, isPlaying: false, playedEventsWithoutTimeout: false })
      }
      if (this.state.selectedAudioRecordingsIndex !== prevState.selectedAudioRecordingsIndex) {
         this.setState({ isPlaying: false }, this.pause());
         this.setState({ playedDuration: 0, playInterval: null, playedEventsWithoutTimeout: false })
      }
      if (!this.state.playedEventsWithoutTimeout && this.state.whiteBoardEvents.length > 0) {
         console.log("whiteBoardPlayer:componentDidUpdate disabling playbutton");
         if (this.state.enablePlayButton) {
            this.setState({ enablePlayButton: false })
         }

      }
   }

   getTotalDuration(audioIntervals) {
      var totalDuration = 0;
      for (var audioInterval of audioIntervals) {
         const { startTime, endTime } = audioInterval;
         var startTimeInMs = moment(startTime).valueOf();
         var endTimeInMs = moment(endTime).valueOf();
         totalDuration += endTimeInMs - startTimeInMs;
      }
      return totalDuration;

   }

   addHours(date, hours) {
      date.setTime(date.getTime() + hours * 60 * 60 * 1000);

      return date;
   }

   async getAudioRecordingBlobsByRoomNameFromDB(roomName, clientId, accessToken) {

      var blobsArray = []
      var result = [];
      console.log("room name " + roomName)
      let status, res;

      try {
         res = await getWhiteboardAudioBlob(accessToken, roomName);;
         status = res.status
      }

      catch (err) {
         console.log(err);
         status = err.response.status;
      }
      // console.log(index)
      if (status === 200) {
         // console.log(res)
         result = res.data.whiteBoardAudioBlobs
         for (let index = 0; index < result.length; index++) {
            blobsArray.push({
               id: result[index].id,
               roomName: result[index].whiteboard_id,
               blobLink: result[index].recording_file_link,
               duration: result[index].duration,
               blobBatchNumber: result[index].blob_batch_number,
               blobBatchID: result[index].blob_batch_id,
               clientID: result[index].client_id,
               recordingStartTime: result[index].recording_start_time,
               createdAt: result[index].created_at

            })

         }
      }
      else {
         console.log(status)
         // if (status === 400)
         //    alert('No Uploads Yet')
      }



      const blobArrayWithSameClientID = blobsArray.filter((obj) => {
         return obj.clientID === clientId
      })
      console.log("blobsArray is from db", blobArrayWithSameClientID);
      return blobArrayWithSameClientID;

   }






   async getBlobsGroupedByBlobIDInSequence(blobs) {
      var blobSequence = [];
      var lastBlobBatchID = null;
      for (var blob of blobs) {
         if (blob.blobBatchID !== lastBlobBatchID) {
            let blobData = await fetch(blob.blobLink).then(r => r.blob());
            blobSequence.push({
               recordingStartTime: blob.recordingStartTime,
               duration: blob.duration,
               blobBatchID: blob.blobBatchID,
               blobs: [blobData]
            })
         }
         else {
            let blobData = await fetch(blob.blobLink).then(r => r.blob());
            blobSequence[blobSequence.length - 1].duration += blob.duration;
            blobSequence[blobSequence.length - 1].blobs.push(blobData);
         }
         lastBlobBatchID = blob.blobBatchID;
      }
      //console.log("blobsequence is",blobSequence)
      return blobSequence;
   }

   getAudioTimeIntervals(sequencedBlobsArray) {
      var timePeriods = []
      for (var sequencedBlob of sequencedBlobsArray) {
         var endTimeMoment = moment(sequencedBlob.recordingStartTime).add(sequencedBlob.duration, 'milliseconds');
         timePeriods.push({
            startTime: sequencedBlob.recordingStartTime,
            endTime: endTimeMoment.toISOString(),
         })
      }
      return timePeriods;
   }

   getEventsWaitingTime() {
      var totaltime = 0;
      var eventsIndex = 0;
      const audioTimeIntervals = this.state.audioTimeIntervals;
      const whiteBoardEvents = this.state.whiteBoardEvents;
      var eventsWaitingTime = []
      for (var audioTimeInterval of audioTimeIntervals) {
         var { startTime, endTime } = audioTimeInterval;
         var startTimeInMs = moment(startTime).valueOf()
         var endTimeInMs = moment(endTime).valueOf()
         while (eventsIndex < whiteBoardEvents.length) {
            var eventData = JSON.parse(whiteBoardEvents[eventsIndex].eventData);
            var eventTimeInMs = moment(eventData.timestamp).valueOf()
            if (eventTimeInMs < startTimeInMs) {
               eventsWaitingTime.push(totaltime);
               eventsIndex++;
            }
            else if (eventTimeInMs > startTimeInMs && eventTimeInMs < endTimeInMs) {
               eventsWaitingTime.push(totaltime + eventTimeInMs - startTimeInMs);
               eventsIndex++;
            }
            else {
               break;
            }
         }
         totaltime += endTimeInMs - startTimeInMs;
      }
      return eventsWaitingTime;
   }


   async playEventsWithoutTimeout() {
      console.log("whiteBoardPlayer:playEventsWithoutTimeout", this.state.isPlaying)
      if (this.state.enablePlayButton) {
         this.setState({ enablePlayButton: false });
      }
      const whiteBoard = this._whiteBoard;
      const canvas = this._whiteBoard._sketch._fc;
      const sketch = this._whiteBoard._sketch;
      sketch.clear();
      const whiteBoardEvents = this.state.whiteBoardEvents;
      const { playedDuration, blobsGroupedByBlobIDInSequence, selectedAudioRecordingsIndex } = this.state;
      if (blobsGroupedByBlobIDInSequence.length <= selectedAudioRecordingsIndex)
         return;
      const recording = blobsGroupedByBlobIDInSequence[selectedAudioRecordingsIndex]
      const recordingStartTime = recording.recordingStartTime;
      var playedTimeMoment = moment(recordingStartTime);
      playedTimeMoment.add(playedDuration, 'milliseconds')
      var playedTime = playedTimeMoment.toISOString();
      //when we have no whiteboard events and we might have a recording
      if (whiteBoardEvents.length === 0) {
         this.setState({ playedEventsWithoutTimeout: true })
         return;
      }

      var firstEventData = JSON.parse(whiteBoardEvents[0].eventData)

      var eventsStartTime = firstEventData.timestamp;
      var lastestEventsInTheInterval = getLastUpdatedEventsInAscending(whiteBoardEvents, eventsStartTime, playedTime);
      var eventsIndex = lastestEventsInTheInterval.length - 1;
      while (eventsIndex >= 0) {
         if (lastestEventsInTheInterval[eventsIndex].eventType === "canvas:objects:cleared") {
            break;
         }
         eventsIndex--;
      }
      eventsIndex = Math.max(0, eventsIndex)

      for (let i = eventsIndex; i < lastestEventsInTheInterval.length; i++) {
         let whiteBoardEvent = lastestEventsInTheInterval[i];
         let eventData = JSON.parse(whiteBoardEvent.eventData);
         let eventTimeMoment = moment(eventData.timestamp);
         if (eventTimeMoment.isSameOrBefore(playedTimeMoment)) {
            await new Promise(async (resolveP, rejectP) => {
               if (eventData.type === "image") {
                  //getting images from http instead of https to prevent cors error in chrome
                  let imageUrl = eventData.src;

                  const oImg = await fabric.FabricImage.fromURL(imageUrl, { crossOrigin: 'anonymous' });
                  //console.log("promise",eventData.left,eventData.top)
                  let scaledJson = whiteBoard.scaleJSON(eventData);
                  oImg.set({
                     ...scaledJson,
                     initialState: true,
                  })
                  if (whiteBoardEvent.eventType === "object:added") {
                     canvas.add(oImg);
                     if (oImg.type === "image") {
                        if (oImg.isPdf)
                           canvas.sendObjectToBack(oImg)
                     }
                  }
                  else if (whiteBoardEvent.eventType === "object:modified")
                     whiteBoard._modify(oImg)
                  else if (whiteBoardEvent.eventType === "object:removed")
                     whiteBoard._remove(oImg)
                  resolveP();
               }
               else {
                  await whiteBoard.processReceivedEvent(whiteBoardEvent, true);
                  resolveP();
               }
            })
         }
         else {
            break;
         }
      }
      console.log("whiteBoardPlayer:playedEventsWithoutTimeout");
      this.setState({ playedEventsWithoutTimeout: true, isPlayingEventsWithoutTimout: false })
   }

   reversePlay() {

      var playedDuration = this.state.playedDuration;
      this.setState({ playedDuration: Math.max(playedDuration - 5000, 0) })
      this.setState({ isPlaying: false, playedEventsWithoutTimeout: false }, this.pause());
   }

   changePlayedDuration(e, newValue) {

      var playedDuration = newValue * this.state.totalDuration * 0.01;
      console.log("changedPlayedDuration", newValue, playedDuration)
      this.setState({ playedDuration: playedDuration })
      this.setState({ isPlaying: false, playedEventsWithoutTimeout: false }, this.pause());
   }

   forwardPlay() {

      var playedDuration = this.state.playedDuration;
      this.setState({ playedDuration: playedDuration + 5000 })
      this.setState({ isPlaying: false, playedEventsWithoutTimeout: false }, this.pause());
   }

   handleChangeAudioRecording(e) {
      var selectedAudioRecordingsIndex = e.target.value;
      const { blobsGroupedByBlobIDInSequence } = this.state;
      this.setState({
         selectedAudioRecordingsIndex: selectedAudioRecordingsIndex,
         totalDuration: blobsGroupedByBlobIDInSequence[selectedAudioRecordingsIndex].duration
      })

   }


   async play() {
      const { blobsGroupedByBlobIDInSequence } = this.state;
      if (blobsGroupedByBlobIDInSequence.length === 0)
         return;
      //await this.playEventsWithoutTimeout(0,audioRecording.recordingStartTime);
      var playedDuration = this.state.playedDuration;
      var whiteBoardEventsTimeouts = this.playWhiteBoardEvents();
      var audioTimeout = this.playAudioRecording();
      var playInterval = this.increasePlayDuration(playedDuration);
      this.setState({
         whiteBoardEventsTimeouts: whiteBoardEventsTimeouts,
         audioTimeout: audioTimeout,
         playInterval: playInterval
      })


   }

   increasePlayDuration(duration) {
      if (!this.state.isPlaying)
         return;
      var totalDuration = duration;
      var interval = setInterval(() => {
         totalDuration += 1000;
         this.setState({ playedDuration: totalDuration })
      }, 1000)
      return interval;
   }



   playWhiteBoardEvents() {
      const canvas = this._whiteBoard._sketch._fc;
      if (!this.state.isPlaying)
         return [];
      const { blobsGroupedByBlobIDInSequence, selectedAudioRecordingsIndex, whiteBoardEvents, playedDuration } = this.state;
      if (blobsGroupedByBlobIDInSequence.length === 0)
         return;
      const recording = blobsGroupedByBlobIDInSequence[selectedAudioRecordingsIndex]
      var recordingStartTime = recording.recordingStartTime;
      var eventsStartTimeMoment = moment(recordingStartTime)
      eventsStartTimeMoment.add(playedDuration, 'milliseconds');
      var eventsEndTimeMoment = moment(recordingStartTime)
      eventsEndTimeMoment.add(recording.duration, 'milliseconds');
      var eventsStartTime = eventsStartTimeMoment.toISOString();
      var eventsEndTime = eventsEndTimeMoment.toISOString();
      var whiteBoardEventsInTheInterval = this.getWhiteBoardEventsInTheInterval(whiteBoardEvents, eventsStartTime, eventsEndTime)
      // console.log('alleventf', whiteBoardEventsInTheInterval)
      var timeouts = [];
      const whiteBoard = this._whiteBoard;
      // const canvas = whiteBoard._sketch._fc;
      for (let whiteBoardEvent of whiteBoardEventsInTheInterval) {
         let eventData = JSON.parse(whiteBoardEvent.eventData);
         let eventTimeMoment = moment(eventData.timestamp);
         let waitingTime = eventTimeMoment.valueOf() - eventsStartTimeMoment.valueOf();
         if (eventData.type === "image") {
            const callTimeoutFunc = (obj, whiteBoardEvent) => (
               setTimeout(() => {
                  var newImageObject = fabric.util.object.clone(obj);
                  if (whiteBoardEvent.eventType === "object:added") {

                     whiteBoard._sketch._fc.add(newImageObject);
                     if (newImageObject.isPdf)
                        canvas.sendObjectToBack(newImageObject)
                  }
                  else if (whiteBoardEvent.eventType === "object:modified")
                     whiteBoard._modify(newImageObject)
                  else if (whiteBoardEvent.eventType === "object:removed")
                     whiteBoard._remove(newImageObject)
                  //canvas.add(newImageObject);
               },
                  waitingTime)
            )
            timeouts.push(callTimeoutFunc(this.getImageObjectForWhiteBoardEvent(whiteBoardEvent), whiteBoardEvent))
         }
         else {
            const callTimeoutFunc = (obj) => (
               setTimeout(async () => { await whiteBoard.processReceivedEvent(obj, true); },
                  waitingTime)
            )
            timeouts.push(callTimeoutFunc(whiteBoardEvent))
         }
      }
      return timeouts;
   }

   getImageObjectForWhiteBoardEvent(whiteBoardEvent) {
      const { canvasImageObjects } = this.state;
      var eventData = JSON.parse(whiteBoardEvent.eventData);
      for (var obj of canvasImageObjects) {
         if (obj.objectID === eventData.objectID && eventData.timestamp === obj.timestamp) {
            return obj;
         }
      }
      return {}
   }

   getWhiteBoardEventsInTheInterval(whiteBoardEvents, startTime, endTime) {
      var startTimeMoment = moment(startTime);
      var endTimeMoment = moment(endTime);
      var whiteBoardEventsInTheInterval = []
      for (var whiteBoardEvent of whiteBoardEvents) {
         var eventData = JSON.parse(whiteBoardEvent.eventData)
         var eventTimeMoment = moment(eventData.timestamp);
         if (eventTimeMoment.isAfter(startTimeMoment) && eventTimeMoment.isBefore(endTimeMoment)) {
            whiteBoardEventsInTheInterval.push(whiteBoardEvent);
         }
      }
      return whiteBoardEventsInTheInterval;
   }

   playAudioRecording() {
      console.log("whiteBoardPlayer:pause:audio", this.state.isPlaying)
      if (!this.state.isPlaying)
         return [];
      const { blobsGroupedByBlobIDInSequence, selectedAudioRecordingsIndex, playedDuration } = this.state;
      //console.log("whiteBoardPlayer:blobseq",blobsGroupedByBlobIDInSequence,selectedAudioRecordingsIndex)
      const audioRecording = blobsGroupedByBlobIDInSequence[selectedAudioRecordingsIndex];
      var newblob = new Blob(audioRecording.blobs, {
         type: 'audio/wav'
      });
      var url = window.URL.createObjectURL(newblob)
      var audioEl = document.createElement("audio");
      audioEl.src = url;
      const callTimeoutFunc = (audioEl, currentTime, waitingTime) => (
         setTimeout(() => {
            audioEl.currentTime = (currentTime / 1000);
            audioEl.play();
         }, [waitingTime])
      )
      var audioTimeout = {
         audioEl: audioEl,
         timeout: callTimeoutFunc(audioEl, playedDuration, 0),
      }

      return audioTimeout;
   }

   pause() {
      const { whiteBoardEventsTimeouts, audioTimeout } = this.state;
      clearInterval(this.state.playInterval);
      var { audioEl, timeout } = audioTimeout;
      clearTimeout(timeout);
      if (audioEl && audioEl.pause)
         audioEl.pause();
      for (var i = 0; i < whiteBoardEventsTimeouts.length; i++) {
         clearTimeout(whiteBoardEventsTimeouts[i]);
      }
      this.setState({ audioTimeout: {}, whiteBoardEventsTimeouts: [] });
   }

   render() {
      const { whiteBoardID,
         clientID,
         isPlaying,
         playedDuration,
         totalDuration,
         blobsGroupedByBlobIDInSequence,
         selectedAudioRecordingsIndex,
         urlBlobBatchID
      } = this.state;
      console.log("playedDuratio is ", playedDuration, totalDuration)
      if (!whiteBoardID)
         return <p style={{ margin: "15px" }}>Please provide whiteBoardID</p>
      if (!clientID)
         return <p style={{ margin: "15px" }}>Please provide clientID</p>
      //return <p>{"wid: "+whiteBoardID+" cid "+clientID}</p>
      return (
         <Box sx={{ height: '100vh' }}>
            <Grid
               sx={{ height: { lg: '80%', md: '80%', sm: '76%', xs: '76%', xl: '80%' } }}>
               <Whiteboard
                  isPlayer={true}
                  whiteBoardID={whiteBoardID}
                  ref={(c) => this._whiteBoard = c}
                  isWhiteboardPlayerLoading={this.state.isLoading}
               />
            </Grid>
            <Grid
               container
               sx={{ height: { lg: '20%', md: '20%', sm: '21%', xs: '21%', xl: '20%' } }}
               justifyContent='center'
               textAlign='center'>

               <Slider
                  value={(playedDuration * 100) / totalDuration}
                  onChange={this.changePlayedDuration}
                  style={{ display: "block", width: "98%", marginLeft: "auto", marginRight: "auto", color: THEME_COLOR }}
               />
               <IconButton
                  sx={{ color: THEME_COLOR }}
                  onClick={this.reversePlay}
                  disabled={!this.state.enablePlayButton}
                  size="large">
                  <Replay5Icon />
               </IconButton>
               <IconButton
                  sx={{ color: THEME_COLOR }}
                  onClick={() => {
                     if (isPlaying) {
                        this.setState({ isPlaying: false, playedEventsWithoutTimeout: false }, this.pause);
                     }
                     else {
                        this.setState({ isPlaying: true }, this.play);
                     }
                  }}
                  disabled={!this.state.enablePlayButton}
                  size="large">
                  {!isPlaying ? <PlayArrowIcon /> : <PauseIcon />}
               </IconButton>
               <IconButton
                  sx={{ color: THEME_COLOR }}
                  onClick={this.forwardPlay}
                  disabled={!this.state.enablePlayButton}
                  size="large"><Forward5Icon />
               </IconButton>
               <IconButton
                  sx={{ color: THEME_COLOR }}
               >
                  {(playedDuration / 1000) + "/" + (totalDuration / 1000)} &nbsp;
               </IconButton>
               {!Boolean(urlBlobBatchID) &&
                  <Select native
                     onChange={this.handleChangeAudioRecording}
                     value={selectedAudioRecordingsIndex}
                     sx={{
                        height: '7.5vh',

                        color: `${THEME_COLOR} !important`,
                        '.MuiOutlinedInput-notchedOutline': {
                           borderColor: `${THEME_COLOR} !important`,
                        },
                        '&.Mui-focused fieldset': {
                           borderColor: `${THEME_COLOR} !important`,
                        },
                        '.MuiSvgIcon-root ': {
                           fill: `${THEME_COLOR} !important`,
                        }
                     }}
                  >
                     {blobsGroupedByBlobIDInSequence.map((obj, ind) =>
                        <option value={ind}>{"recording - " + ind + "," + obj.recordingStartTime.slice(0, 10) + ",duration: " + (obj.duration / 1000) + "s"}</option>)}
                  </Select>
               }
            </Grid>
         </Box>
      );
   }
}

export default (WhiteBoardPlayer);