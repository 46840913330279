import React from "react";
import { CarouselLayout, FocusLayoutContainer, LiveKitRoom, MediaDeviceMenu, PreJoin, RoomAudioRenderer } from "@livekit/components-react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import {

  GridLayout,
  useTracks,
  TrackToggle,
  FocusLayout,

} from '@livekit/components-react';
import './customStyles.css';
import './stylesOldLivekit.css';
import { RoomEvent, Track } from 'livekit-client';
import '@livekit/components-styles';
import { Tooltip, useMediaQuery } from "@mui/material";
import { Typography, Button, IconButton, Box, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import { faSquare, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';
import CallEndIcon from '@mui/icons-material/CallEnd';
import CallIcon from '@mui/icons-material/Call';
import { CustomParticipantTile } from "./participant/CustomParticipantTile";
import { CAMERA_DISABLED_EVENT_NAME, CAMERA_ENABLED_EVENT_NAME, FRONTEND_URL, MIC_DISABLED_EVENT_NAME, MIC_ENABLED_EVENT_NAME, SCREENSHARING_DISABLED_EVENT_NAME, SCREENSHARING_ENABLED_EVENT_NAME, THEME_COLOR, WHITEBOARD_TOOLBAR_COLOR } from "../../whiteboard/src/config/constants";
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import EndSessionStepperForm from "../../classroom2/EndSessionStepperForm";
import { useAtom, useAtomValue } from "jotai";
import { isCameraEnabledAtom } from "../../components/jotai/atom/isCameraEnabledAtom";
import { isMicEnabledAtom } from "../../components/jotai/atom/isMicEnabledAtom";
import { isScreenSharingEnabledAtom } from "../../components/jotai/atom/isScreenSharingEnabledAtom";
import { recordSessionEvents } from "../../components/helperFunctions";
import { isVideoTileFullscreenAtom } from "../../components/jotai/atom/isVideoTileFullscreenAtom";
import { CustomCarouselLayout } from "./layout/CustomCarouselLayout";

async function getToken(roomname, userName) {
  if (roomname && userName) {
    const res = await fetch("https://" + "av.teachmatter.com/getRoomToken/" + roomname + '/' + userName + '/');
    const data = await res.json()
    // console.log("livekit token " + data.token)
    return data.token
  }

}

const LiveKitNew = ({ userName,
  recordingTimeRef,
  whiteBoardID,
  isLiveKitIntegrated,
  slateID,
  accessToken,
  isTeacher,
  isPrejoin,
  slateRef,
  isTextFocused,
  sendLessonEventViaWebSocket,
  clientID,
  // isLivekitFullscreen,
  whiteBoardRef,
  isStartCallButton,
  setIsStartCallButton
}) => {
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down(500));
  // console.log(isMobile, 'ismobile')
  if (window.innerHeight && window.innerHeight <= 480) {
    isMobile = true;
  }
  const [maximize, setMaximize] = useState(isMobile ? false : true)

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const search = useLocation()
  const [token, setToken] = useState(null)
  const query = new URLSearchParams(useLocation().search);
  const [connect, setConnect] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isFullscreen, setIsFullscreen] = useAtom(isVideoTileFullscreenAtom)
  const [openPrejoinBox, setOpenPrejoinBox] = useState(false)
  const [isAudioEnabled, setIsAudioEnabled] = useState(false)
  const [isVideoEnabled, setIsVideoEnabled] = useState(false)
  const [initialAudioDeviceId, setInitialAudioDeviceId] = useState()
  const [initialVideoDeviceId, setInitialVideoDeviceId] = useState()
  const [focusedScreenShareTrack, setFocusedScreenShareTrack] = useState()

  const [endSessionBox, setEndSessionBox] = React.useState(false);
  const [closeSessionBox, setCloseSessionBox] = React.useState(false);
  const [sessionReport, setSessionReport] = React.useState(false)

  const isCameraEnabled = useAtomValue(isCameraEnabledAtom)
  const isMicEnabled = useAtomValue(isMicEnabledAtom)
  const isScreenSharingEnabled = useAtomValue(isScreenSharingEnabledAtom)

  var url = 'wss://server1.slatemates.in'
  // console.log(url)

  useEffect(() => {
    (async () => {

      if (!isLiveKitIntegrated) {
        console.log('ebe ', isLiveKitIntegrated, whiteBoardID, userName)
        let tokenFromServer
        const roomID = query.get('roomID');
        const userID = query.get('userID');
        tokenFromServer = await getToken(roomID, userID)
        setToken(tokenFromServer)
        if (!isPrejoin) {
          setConnect(true)
        }
      }

      // console.log(tokenFromServer)

    })
      ()
  }, [query])

  useEffect(() => {
    if (isLiveKitIntegrated && !isStartCallButton) {
      initializeCall()
    }
    // console.log(tokenFromServer)

  }, [isLiveKitIntegrated])

  const initializeCall = async () => {

    // console.log('livekit brio')
    let tokenFromServer
    console.log('ebe ', isLiveKitIntegrated, whiteBoardID, userName, isStartCallButton)
    tokenFromServer = await getToken(whiteBoardID, userName)
    setToken(tokenFromServer)
    if (!isPrejoin) {
      setConnect(true)
    }

  }


  const handleDisconnect = () => {
    setConnect(false);
    setIsConnected(false);
  };

  let endSessionButton = <>
    <Tooltip title={"End Class"}>
      <button
        className="lk-button"
        onClick={() => {
          setEndSessionBox(true)
        }}
      >
        <CallEndIcon sx={{ color: 'red' }} />
      </button>
    </Tooltip>
    <Dialog
      open={endSessionBox}
      onClose={() => {
        setEndSessionBox(false)
      }}
    >
      <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', color: '#B33A3A' }} >
        Warning!
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontSize: "1rem" }}>Are you sure you want to end the session?</Typography>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            sx={{ width: "50%", color: THEME_COLOR }}
            onClick={async () => {
              setSessionReport(true)
              setEndSessionBox(false)
              if (!window.lesson.isAudioMuted) {
                if (window.lesson.isAudioMuted !== undefined) {
                  if (document.getElementById('mic-button-livekit')) {
                    document.getElementById('mic-button-livekit').click()
                  }
                }
              }
              // const SlateIDObject = {
              //   "slateId": slateID
              // }
              // // console.log(SlateIDObject)
              // let status, res;
              // try {
              //   res = await deactivateSessionForSlate(accessToken, SlateIDObject)
              //   status = res.status
              // }
              // catch (err) {
              //   console.log(err)
              //   status = err.response.status
              // }
              // // console.log(res);
              // if (status === 200) {
              //   // setTimeout(() => {
              //   //   window.top.close()
              //   // }, 1500);

              // }
              // else if (status === 401) {
              //   alert("You are not authorized to End Session")
              // }
              // else {
              //   console.log(status);

              // }

            }}
          >
            Yes
          </Button>
          <Button
            sx={{ width: "50%", color: THEME_COLOR }}
            onClick={(e) => {
              setEndSessionBox(false)
            }}
          >
            No
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
    <EndSessionStepperForm
      setSessionReport={setSessionReport}
      sessionReport={sessionReport}
      slateID={slateID}
      whiteBoardID={whiteBoardID}
      accessToken={accessToken}
      isTextFocused={isTextFocused}
      clientID={clientID}
      slateRef={slateRef}
      whiteBoardRef={whiteBoardRef}
    />
  </>

  let exitSessionButton = <>
    <Tooltip title={"Exit Class"}>
      <button
        className="lk-button"
        onClick={() => {
          setCloseSessionBox(true)
        }}>
        <CallEndIcon sx={{ color: 'red' }} />
      </button>
    </Tooltip>
    <Dialog
      open={closeSessionBox}
      onClose={() => {
        setCloseSessionBox(false)
      }}
    >
      <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', color: '#B33A3A' }} >
        Warning!
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontSize: "1rem" }}>Are you sure you want to exit the session?</Typography>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            sx={{ width: "50%", color: THEME_COLOR }}
            onClick={async () => {
              setCloseSessionBox(false)
              window.open(FRONTEND_URL, '_self')
            }}
          >
            Yes
          </Button>
          <Button
            sx={{ width: "50%", color: THEME_COLOR }}
            onClick={(e) => {
              setCloseSessionBox(false)
            }}
          >
            No
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  </>

  return (<>
    {token && <Box className='container'
      sx={!isFullscreen ? { padding: '10px', backgroundColor: WHITEBOARD_TOOLBAR_COLOR, zIndex: 1000, overflow: 'hidden', borderRadius: '10px', width: { xs: '250px', sm: '250px', md: '250px', lg: '300px' }, maxHeight: { xs: '70%', sm: '75%', md: '75%', lg: '80%' }, position: 'absolute', top: isLiveKitIntegrated ? '90px' : 0, right: '10px' } :
        { backgroundColor: WHITEBOARD_TOOLBAR_COLOR, zIndex: 1051, width: '100%', height: '100%', position: 'absolute', top: 0, overflow: 'hidden' }
      }
      onMouseOver={() => {
        window.whiteboardScrollDisable = true
      }}
      onMouseOut={() => {
        window.whiteboardScrollDisable = false
      }}
    >

      <div className="livekit-room-custom" style={{ height: '100%', width: '100%', padding: 0 }}>
        {isConnected && <div style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          justifyContent: 'space-between',
          alignItems: 'left',
          marginBottom: '5px',
        }}>
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto auto',
            alignItems: 'left',
            paddingLeft: '0',
            gap: '16px'
          }}>
            <div style={{ display: 'flex', flexDirection: 'row' }} >
              <button
                id="fullScreenButton"
                className="iconButton"
                disabled={isFullscreen}
                onClick={() => {
                  setIsFullscreen(true)
                  setMaximize(true)
                  if (isTeacher) {
                    let lessonEvent = {
                      eventType: 'followScreenEvent',
                      eventData: {
                        'livekitState': 'fullscreen',
                        'sentBy': userName
                      }
                    }
                    sendLessonEventViaWebSocket(lessonEvent)
                  }
                }}
              >
                <FontAwesomeIcon height={32} icon={faSquare} />
              </button>
              <button
                id="minimizeScreenButton"
                className="iconButton"
                disabled={!isFullscreen}
                onClick={() => {
                  setIsFullscreen(false)
                  if (isTeacher) {
                    let lessonEvent = {
                      eventType: 'followScreenEvent',
                      eventData: {
                        'livekitState': 'sideview',
                        'sentBy': userName
                      }
                    }
                    sendLessonEventViaWebSocket(lessonEvent)
                  }

                }}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <FontAwesomeIcon height={32} icon={faThLarge} />
                &nbsp;
                {isFullscreen && <Typography sx={{ fontSize: '0.75rem', color: '#000000', fontWeight: 560, paddingTop: 0 }}>
                  Back to whiteboard
                </Typography>}
              </button>
            </div>

          </div>
        </div>}
        <LiveKitRoom
          data-lk-theme="default"
          token={token}
          serverUrl={url}
          connect={connect}
          onConnected={() => {

            setIsConnected(true)
          }}
          onDisconnected={handleDisconnect}
          onError={(e) => {
            console.log('errorlivekit', e)
            if (!window.livekitErrorShown) {
              alert("Unable to connect your call. Please ensure that all other tabs of the same session are closed and then reload this page")
            }
            window.livekitErrorShown = true
          }}
          audio={isAudioEnabled} //if true the audio starts automatically
          video={isVideoEnabled}//if true the video start automatically
        >
          <Box sx={{ backgroundColor: WHITEBOARD_TOOLBAR_COLOR, height: '80%', width: '100%', minHeight: !isConnected ? 0 : maximize ? '3vh' : "10px" }}>
            {isFullscreen ?
              <GridlLayoutInFullView
                focusedScreenShareTrack={focusedScreenShareTrack}
                setFocusedScreenShareTrack={setFocusedScreenShareTrack}
                slateRef={slateRef}
                accessToken={accessToken}
                whiteBoardID={whiteBoardID}
                userName={userName}
                sendLessonEventViaWebSocket={sendLessonEventViaWebSocket}
                isTeacher={isTeacher}
              /> :
              <CarouselLayoutInSideView
                sendLessonEventViaWebSocket={sendLessonEventViaWebSocket}
                focusedScreenShareTrack={focusedScreenShareTrack}
                setFocusedScreenShareTrack={setFocusedScreenShareTrack}
                setIsFullscreen={setIsFullscreen}
                userName={userName ? userName : query.get('userID')}
                slateRef={slateRef}
                accessToken={accessToken}
                whiteBoardID={whiteBoardID}
                maximize={maximize}
                setMaximize={setMaximize}
                isTeacher={isTeacher}
              />}
            <RoomAudioRenderer />
          </Box>

          {maximize && <Box sx={{ backgroundColor: WHITEBOARD_TOOLBAR_COLOR, justifyContent: 'center', textAlign: 'center', height: '7.5%', width: '100%', paddingTop: '10px', display: 'flex', flexDirection: 'row' }}>

            {!isConnected ? <>
              {isPrejoin && <button className="lk-button"
                onClick={() => {
                  setOpenPrejoinBox(true)
                  // setConnect(!connect)
                }}>
                <CallIcon sx={{ fontSize: '1.2rem', padding: "2px 1px 0", color: 'green' }} />  &nbsp;{'Start call'}
              </button>}
            </> : <>
              {isMicEnabled && <Box sx={{ maxWidth: '25%', display: 'flex', flexDirection: 'row', marginRight: '15px', padding: '15px 10px' }}>
                <div className='custom-audio-video-button' onClick={() => {
                  if (!window.isAudioPressed) {
                    const time1 = moment().toISOString();
                    console.log('audio button pressed first time at', time1)
                    recordSessionEvents(accessToken, whiteBoardID, clientID, MIC_ENABLED_EVENT_NAME, null)
                  }
                  window.isAudioPressed = true;
                }}>
                  <TrackToggle
                    id='mic-button-livekit'
                    source={Track.Source.Microphone}
                    onChange={(isAudiotrue) => {
                      // console.log(isAudiotrue)

                      if (isAudiotrue) {
                        window.lesson.isAudioMuted = false;
                        // console.log('setting last unmuted when button currently unmuted')
                        let currentMoment = moment()
                        if (recordingTimeRef && recordingTimeRef.current) {
                          recordingTimeRef.current.lastUnmutedTime = currentMoment.toISOString();
                        }
                        if (window.isAudioPressed) {
                          console.log('audio turned on at', moment().toISOString())
                          recordSessionEvents(accessToken, whiteBoardID, clientID, MIC_ENABLED_EVENT_NAME, null)
                        }

                      }
                      else {
                        window.lesson.isAudioMuted = true;
                        // console.log(' setting last muted when button currently muted')
                        let currentMoment = moment()
                        if (recordingTimeRef && recordingTimeRef.current) {
                          recordingTimeRef.current.lastMutedTime = currentMoment.toISOString();
                        }
                        if (window.isAudioPressed) {
                          console.log('audio turned off at', moment().toISOString())
                          recordSessionEvents(accessToken, whiteBoardID, clientID, MIC_DISABLED_EVENT_NAME, null)
                        }
                      }
                    }} />
                </div>
                <div style={{ position: 'relative' }}>
                  <MediaDeviceMenu
                    initialSelection={initialAudioDeviceId}
                    kind="audioinput" />
                </div>
              </Box>}
              {isCameraEnabled && <Box sx={{ maxWidth: '25%', display: 'flex', flexDirection: 'row', marginRight: '15px', padding: '15px 10px' }}>
                <div className='custom-audio-video-button' onClick={() => {
                  if (!window.isVideoPressed) {
                    const time1 = moment().toISOString();
                    console.log('video button pressed first time at', time1)
                    recordSessionEvents(accessToken, whiteBoardID, clientID, CAMERA_ENABLED_EVENT_NAME, null)
                  }
                  window.isVideoPressed = true;
                }}>
                  <TrackToggle
                    id='camera-button-livekit'
                    source={Track.Source.Camera}
                    onChange={(isVideotrue) => {
                      // console.log(isVideotrue)

                      if (isVideotrue) {
                        window.lesson.isVideoMuted = false;
                        if (window.isVideoPressed) {
                          console.log('video turned on at', moment().toISOString())
                          recordSessionEvents(accessToken, whiteBoardID, clientID, CAMERA_ENABLED_EVENT_NAME, null)
                        }

                      }
                      else {
                        window.lesson.isVideoMuted = true;
                        if (window.isVideoPressed) {
                          console.log('video turned off at', moment().toISOString())
                          recordSessionEvents(accessToken, whiteBoardID, clientID, CAMERA_DISABLED_EVENT_NAME, null)
                        }
                        // console.log(' setting last muted when button currently muted')
                      }
                    }}
                  />
                </div>
                <div style={{ position: 'relative' }}>
                  <MediaDeviceMenu
                    initialSelection={initialVideoDeviceId}
                    kind="videoinput"

                  />
                </div>
              </Box>}
              {isScreenSharingEnabled && <Box sx={{ maxWidth: '20%', marginRight: '15px', padding: '20px 10px' }}
                onClick={() => {
                  if (isTeacher) {
                    // console.log('track toggle called on click')
                    if (window.lesson.isScreenShareButton) {
                      let lessonEvent = {
                        eventType: 'followScreenEvent',
                        eventData: {
                          'livekitState': 'sideview',
                          'sentBy': userName
                        }
                      }
                      console.log('screensharing:off')
                      recordSessionEvents(accessToken, whiteBoardID, clientID, SCREENSHARING_DISABLED_EVENT_NAME, null)
                      sendLessonEventViaWebSocket(lessonEvent)
                    }
                    else {
                      console.log('screensharing:on')
                      recordSessionEvents(accessToken, whiteBoardID, clientID, SCREENSHARING_ENABLED_EVENT_NAME, null)
                      let lessonEvent = {
                        eventType: 'followScreenEvent',
                        eventData: {
                          'livekitState': 'fullscreen',
                          'sentBy': userName
                        }
                      }
                      sendLessonEventViaWebSocket(lessonEvent)
                    }
                  }
                  else {
                    if (window.lesson.isScreenShareButton) {
                      console.log('screensharing:off')
                      recordSessionEvents(accessToken, whiteBoardID, clientID, SCREENSHARING_DISABLED_EVENT_NAME, null)
                    }
                    else {
                      console.log('screensharing:on')
                      recordSessionEvents(accessToken, whiteBoardID, clientID, SCREENSHARING_ENABLED_EVENT_NAME, null)
                    }
                  }
                  // console.log('screensharing:toggled')


                }}
              >
                <TrackToggle
                  source={Track.Source.ScreenShare}
                  onChange={(isScreensharetrue) => {
                    // console.log(isScreensharetrue)
                    if (isTeacher) {
                      // console.log('track toggle called')
                      if (isScreensharetrue) {
                        window.lesson.isScreenShareButton = true;
                        // if (window.isScreensharingPressed) {
                        //   console.log('screen turned on at', moment().toISOString())
                        //   recordSessionEvents(accessToken, whiteBoardID, clientID, 'screensharing:enabled', null)
                        // }
                      }
                      else {
                        window.lesson.isScreenShareButton = false;
                        // if (window.isScreensharingPressed) {
                        //   console.log('screen turned off at', moment().toISOString())
                        //   recordSessionEvents(accessToken, whiteBoardID, clientID, 'screensharing:disabled', null)
                        // }
                      }
                    }

                  }}
                  captureOptions={{ audio: true, selfBrowserSurface: 'include' }}
                />
              </Box>}
              <Box sx={{ maxWidth: '20%', padding: '20px 10px' }}>
                {isLiveKitIntegrated ? <>
                  {isTeacher && endSessionButton}
                  {!isTeacher && exitSessionButton}
                </> :
                  <Box sx={{ maxWidth: '25%', padding: '17.5px 10px' }}>
                    <button className="lk-button" onClick={() => setConnect(!connect)}>
                      <Tooltip title='End Call'>
                        <CallEndIcon sx={{ color: 'red' }} />
                      </Tooltip>
                    </button>
                  </Box>

                }
              </Box>


            </>}
          </Box>}
          {/* <Stage /> */}
        </LiveKitRoom>

      </div>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openPrejoinBox}
        // onClose={() => { setOpenPrejoinBox(false) }}
        fullScreen={fullScreen}
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogTitle sx={{ backgroundColor: '#111', justifyContent: 'center', textAlign: 'center', position: 'relative' }}>
          <Typography sx={{ fontSize: '2.25rem', fontWeight: '600', color: THEME_COLOR }}>
          </Typography>

          <IconButton
            sx={{ color: '#fff', position: 'absolute', right: '5px', top: "5px", zIndex: 10000 }}
            onClick={() => {
              setOpenPrejoinBox(false)
            }}
            size="small">
            <CloseIcon sx={{ fontSize: '1rem' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: '#111', color: '#fff' }} >
          <div className="custom-prejoin-dialog">
            <PreJoin
              joinLabel="Start Call"
              defaults={{
                username: isLiveKitIntegrated ? userName : query.get('userID')
              }}
              onSubmit={(values) => {
                // console.log(values)
                setInitialAudioDeviceId(values.audioDeviceId)
                setInitialVideoDeviceId(values.videoDeviceId)
                setIsAudioEnabled(values.audioEnabled)
                setIsVideoEnabled(values.videoEnabled)
                setConnect(true)
                setOpenPrejoinBox(false)
              }}

            />
          </div>
        </DialogContent>

      </Dialog>
    </Box>}

    {isLiveKitIntegrated && isStartCallButton && <Box className='container'
      sx={!isFullscreen ? { padding: '10px', backgroundColor: WHITEBOARD_TOOLBAR_COLOR, zIndex: 1000, overflow: 'hidden', borderRadius: '10px', width: { xs: '250px', sm: '250px', md: '250px', lg: '300px' }, maxHeight: { xs: '70%', sm: '75%', md: '75%', lg: '80%' }, position: 'absolute', top: isLiveKitIntegrated ? '90px' : 0, right: '10px' } :
        { backgroundColor: WHITEBOARD_TOOLBAR_COLOR, zIndex: 1051, width: '100%', height: '100%', position: 'absolute', top: 0, overflow: 'hidden' }
      }
      onMouseOver={() => {
        window.whiteboardScrollDisable = true
      }}
      onMouseOut={() => {
        window.whiteboardScrollDisable = false
      }}
    >
      <Button sx={{
        // height: '25px',
        display: 'flex',
        width: '100%',
        textTransform: 'none',
        background: '#111',
        color: '#ffffff',
        borderRadius: '5px',
        '&:hover': {
          background: '#111',
          boxShadow: 1,
        },
        marginRight: '10px'
      }}
        onClick={() => {
          initializeCall()
          setIsStartCallButton(false)
        }}
      >
        <CallIcon sx={{ fontSize: '1.2rem', padding: "1px 1px 0", color: 'green' }} />  &nbsp;{'Start call'}
      </Button>
    </Box>}

  </>
  );
};


function CarouselLayoutInSideView({ isTeacher, sendLessonEventViaWebSocket, setIsFullscreen, userName, focusedScreenShareTrack, setFocusedScreenShareTrack, slateRef, accessToken, whiteBoardID, maximize, setMaximize }) {
  // const [isScreenBeingShared, setIsScreenBeingShared] = useState(false)

  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { updateOnlyOn: [RoomEvent.ParticipantNameChanged], onlySubscribed: false },
  );
  // console.log('casmera tracks bro', tracks)
  // for (let index = 0; index < participants.length; index++) {
  //   const participant = participants[index];
  //   console.log("participant" + JSON.stringify(participant))

  // }
  // console.log(tracks, 'yotracck1')
  tracks.sort(function (a, b) {
    // console.log(a.participant.identity)
    if (a.participant.identity == b.participant.identity) return 0;
    if (a.participant.identity == userName) return -1;
    if (b.participant.identity == userName) return 1;

    if (a.participant.identity < b.participant.identity)
      return -1;
    if (a.participant.identity > b.participant.identity)
      return 1;
    return 0;
  });
  const screenShareTrack = useTracks([Track.Source.ScreenShare]);
  // console.log(screenShareTrack)
  // console.log(tracks, 'yotracck')
  // useEffect(() => {
  //   // console.log(screenShareTrack, 'equals', userName)
  //   if (screenShareTrack.length > 0) {
  //     if (checkIfScreenShareIsOfTheSameClient(screenShareTrack, userName)) {
  //       setIsScreenBeingShared(true)
  //     }
  //     else {
  //       setIsScreenBeingShared(false)
  //     }
  //   }
  //   else {
  //     setIsScreenBeingShared(false)
  //   }
  // }, [screenShareTrack])

  // const checkIfScreenShareIsOfTheSameClient = (screenShareTrack, userName) => {

  //   for (let i = 0; i < screenShareTrack.length; ++i) {
  //     // console.log(screenShareTrack[i].participant.identity, 'equals', userName, screenShareTrack[0].participant.identity !== userName)
  //     if (screenShareTrack[i].participant.identity !== userName) {
  //       return true
  //     }
  //   }
  //   return false
  // }
  // console.log('window height', window.innerHeight)
  const checkIfTrackContainsRecordBot = (tracks) => {
    for (let i = 0; i < tracks.length; ++i) {
      if (tracks[i].participant.identity === 'recordbot') {
        return 3
      }
    }
    return 2
  }

  return <>
    {/* {isScreenBeingShared && <Button
      onClick={() => {
        setIsFullscreen(true)
      }}
      sx={{ width: '100%', textTransform: 'none', color: THEME_COLOR, height: '5vh' }}
    >
      View screen share
    </Button>} */}
    <Button sx={{
      position: 'absolute',
      top: -30,
      right: 0,
      color: 'white',
      background: "#1e1e1e",
      fontSize: '0.5rem',
      borderRadius: '5px',
      height: '25px',
      width: '5px',
      '&:hover': {
        background: "#434343"
      }
    }}
      onClick={() => {
        setMaximize(!maximize)
      }}
    >
      {maximize ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
    </Button>
    <Box
      sx={{
        display: maximize ? 'block' : 'none',
        height: tracks.length > checkIfTrackContainsRecordBot(tracks) ? { xs: '42.5vh', sm: window.innerHeight > 650 ? '65vh' : '30vh', md: window.innerHeight > 650 ? '55vh' : '42.5vh', lg: window.innerHeight > 650 ? '55vh' : window.innerHeight > 480 ? '42.5vh' : '30vh', xl: '60vh' } : window.innerHeight < 650 ? '30vh' : '100%',
        width: '100%',
        maxHeight: '500px',
      }}
    >
      <CustomCarouselLayout
        tracks={tracks}
        orientation="vertical"
        style={{ overflowY: tracks.length > checkIfTrackContainsRecordBot(tracks) ? 'scroll' : 'auto' }}
      >
        <CustomParticipantTile
          onParticipantClick={(particpantData) => {
            // console.log(particpantData)

            if (particpantData.track && !(particpantData.track.isMuted)) {
              // console.log('participant clicked', particpantData.participant.identity)
              if ((particpantData.track).source) {

                setFocusedScreenShareTrack({
                  'identity': particpantData.participant.identity,
                  'source': (particpantData.track).source
                })
                // console.log(focusedScreenShareTrack)
                if (isTeacher) {


                  let lessonEvent = {
                    eventType: 'participantClicked',
                    eventData: {
                      'nameOfClickedParticipant': particpantData.participant.identity,
                      'sentBy': userName,
                      'type': particpantData.track.source === Track.Source.Camera ? 'camera' : 'screenshare'
                    }
                  }
                  sendLessonEventViaWebSocket(lessonEvent)
                }
                setIsFullscreen(true)
                if (isTeacher) {
                  let lessonEvent = {
                    eventType: 'followScreenEvent',
                    eventData: {
                      'livekitState': 'fullscreen',
                      'sentBy': userName
                    }
                  }
                  sendLessonEventViaWebSocket(lessonEvent)
                }
              }
            }
          }}
          slateRef={slateRef}
          accessToken={accessToken}
          whiteBoardID={whiteBoardID}
          userName={userName}
          isSideView={true}
        />
      </CustomCarouselLayout>
    </Box>
  </>
}


function GridlLayoutInFullView({ isTeacher, sendLessonEventViaWebSocket, focusedScreenShareTrack, setFocusedScreenShareTrack, slateRef, accessToken, whiteBoardID, userName }) {
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { updateOnlyOn: [RoomEvent.TrackSubscribed], onlySubscribed: false },
  );

  const screenShareTrack = useTracks([Track.Source.ScreenShare]);
  const videoTracks = useTracks([Track.Source.Camera])
  // console.log(tracks)
  // console.log(screenShareTrack)

  const getFocusedTrack = (screenShareTrack, videoTracks, focusedScreenShareTrack) => {
    if (focusedScreenShareTrack) {
      if (focusedScreenShareTrack.source === 'screen_share') {
        // console.log('screenshare')
        for (let i = 0; i < screenShareTrack.length; ++i) {
          if ((screenShareTrack[i].participant).identity === focusedScreenShareTrack.identity) {
            return screenShareTrack[i]
          }
        }
        return screenShareTrack[0]
      }
      else if (focusedScreenShareTrack.source === 'camera') {
        for (let i = 0; i < videoTracks.length; ++i) {
          if ((videoTracks[i].participant).identity === focusedScreenShareTrack.identity) {
            return videoTracks[i]
          }
        }
        return videoTracks[0]
      }
    }
    else {
      if (screenShareTrack.length > 0) {
        return screenShareTrack[0]
      }
    }
  }

  console.log('Livekit rerendering')

  return <>
    {getFocusedTrack(screenShareTrack, videoTracks, focusedScreenShareTrack) ?
      <Box sx={{
        height: '100vh',
        width: '100%'
      }}>
        <FocusLayoutContainer>
          <Box
            sx={{
              display: 'block', height: tracks.length > 4 ? '90vh' : '100%', width: '100%'
            }}
          >
            <CarouselLayout tracks={tracks} orientation="vertical">
              <CustomParticipantTile
                onParticipantClick={(particpantData) => {
                  // console.log(particpantData)
                  if (particpantData.track && !(particpantData.track.isMuted)) {
                    // console.log('screen shared clicked', particpantData.participant.identity)

                    setFocusedScreenShareTrack({
                      'identity': particpantData.participant.identity,
                      'source': (particpantData.track).source
                    })
                    if (isTeacher) {
                      let lessonEvent = {
                        eventType: 'participantClicked',
                        eventData: {
                          'nameOfClickedParticipant': particpantData.participant.identity,
                          'sentBy': userName,
                          'type': particpantData.track.source === Track.Source.Camera ? 'camera' : 'screenshare'
                        }
                      }
                      sendLessonEventViaWebSocket(lessonEvent)
                    }
                  }
                }}
                slateRef={slateRef}
                accessToken={accessToken}
                whiteBoardID={whiteBoardID}
                userName={userName}
              />
            </CarouselLayout>
          </Box>
          {<Box
            sx={{
              display: 'block', height: '100%', width: '90%', marginLeft: '5%'
            }}
          ><FocusLayout trackRef={getFocusedTrack(screenShareTrack, videoTracks, focusedScreenShareTrack)} >
              <CustomParticipantTile
                slateRef={slateRef}
                accessToken={accessToken}
                whiteBoardID={whiteBoardID}
                userName={userName}
              />
            </FocusLayout>
          </Box>}
        </FocusLayoutContainer>
      </Box> :
      <GridLayout tracks={tracks}>
        <CustomParticipantTile slateRef={slateRef} accessToken={accessToken} whiteBoardID={whiteBoardID} userName={userName} />
      </GridLayout>}
  </>;
}

// function GridParticipants() {

//   const cameraTracks = useTracks();
//   console.log(cameraTracks)

//   return (
//     <Box sx={{
//       // position:'absolute',
//       display: 'grid',
//       gridTemplateColumns: cameraTracks.length > 1 ? (cameraTracks.length > 4 ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)') : 'repeat(1, 1fr)',
//       gap: '10px',
//       // // height: 'calc(100% - 10px)'
//       // maxHeight: '100%',
//       height: '80%'
//     }}>
//       <TrackLoop tracks={cameraTracks} >
//         <MyParticipantTile />
//       </TrackLoop>
//     </Box>
//   )


// }


// Custom participant tile for more control

// function MyParticipantTile() {
//   return (

//     <TrackRefContext.Consumer>
//       {(track) => track &&
//         <div style={{ height: '100%' }}>
//           <VideoTrack {...track} />
//         </div>}
//     </TrackRefContext.Consumer>

//   );
// }


// function Stage() {
//   const cameraTracks = useTracks([Track.Source.Camera]);
//   const screenShareTrack = useTracks([Track.Source.ScreenShare])[0];

//   return (
//     <>
//       {screenShareTrack && <ParticipantTile {...screenShareTrack} />}
//       <GridLayout tracks={cameraTracks}>
//         <TrackRefContext.Consumer>
//           {(track) => <ParticipantTile {...track} />}
//         </TrackRefContext.Consumer>
//       </GridLayout>
//     </>
//   );
// }


export default LiveKitNew