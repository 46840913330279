import React, { useEffect, useRef } from 'react';

const MathJaxComponent = ({ children }) => {
    const mathContainerRef = useRef(null);

    useEffect(() => {
        if (window.MathJax) {
            // Wait for MathJax to process the content
            window.MathJax.typesetPromise([mathContainerRef.current])
                .catch((err) => console.error('MathJax error:', err));
        }
    }, [children]);

    return (
        <div style={{ width: '100%' }} ref={mathContainerRef}>
            {children}
        </div>
    );
};

export default MathJaxComponent;