import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';

import MuiAlert from '@mui/material/Alert';

import ContentHeader from '../components/SearchContent/ContentHeader';
import ContentList from '../components/SearchContent/ContentList';
import CloseIcon from "@mui/icons-material/Close";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const Root = styled(Grid)(({ theme }) => ({
    height: '100vh',
    width: '100%',
    backgroundColor: '#f8f8f8',
    // overflow: 'hidden',
    display: 'flex',
    overscrollBehavior: 'contain',
    flexDirection: 'column',
}));


const SearchContent = ({ isWhiteboard, handleIsPdf, handlePptxDocs, addImage, setContentBox, uploadCanvasFromContentLibrary }) => {

    const [listArray, setListArray] = useState([]);
    const [isSearchPressed, setIsSearchPressed] = useState(false)


    useEffect(() => {


    }, [])








    return (
        <>
            {/* {isLoading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%', position: 'absolute', flexDirection: 'column' }}>
                <Typography variant='h6' sx={{ }}> Loading Slates ...</Typography>
                <CircularProgress sx={{ justifyContent: 'center', alignItems: 'center'}} />
            </Box> : */}
            <Root  >
                <>
                    {isWhiteboard && <IconButton
                        sx={{
                            color: 'black',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            zIndex: 10000
                        }}
                        onClick={() => {
                            setContentBox(false)
                            window.whiteboardScrollDisable = false
                        }}
                        size="small">
                        <CloseIcon />
                    </IconButton>}
                    <ContentHeader
                        array={listArray}
                        setArray={setListArray}
                        user={''}
                        isWhiteboard={isWhiteboard}
                        setIsSearchPressed={setIsSearchPressed}
                        isSearchPressed={isSearchPressed}
                    />

                    <ContentList
                        array={listArray}
                        setArray={setListArray}
                        user={''}
                        isWhiteboard={isWhiteboard}
                        handleIsPdf={handleIsPdf}
                        handlePptxDocs={handlePptxDocs}
                        addImage={addImage}
                        uploadCanvasFromContentLibrary={uploadCanvasFromContentLibrary}
                        setContentBox={setContentBox}
                        isSearchPressed={isSearchPressed}
                    />
                </>


            </Root>
            {/* } */}
        </>
    );
}


export default SearchContent;
