

import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IconButton, Box, Button, Typography, Dialog, FormControl, DialogTitle, DialogContent, Paper, InputBase, Divider } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import Grid from '@mui/material/Grid2';
import { THEME_COLOR, THEME_HOVER_COLOR } from "../whiteboard/src/config/constants";

const Weblink = forwardRef((props, ref) => {
    const [openWeblink, setOpenWeblink] = useState(false);
    const [openAsklink, setOpenAsklink] = useState(false);
    const [websiteLink, setWebsiteLink] = useState('')

    useImperativeHandle(ref, () => ({
        handleOpenWeblink,
        handleWebsitelink,
        checkWebsiteLinkOpen
    }));

    const handleOpenWeblink = (value) => {
        setOpenWeblink(value)

    }
    const handleWebsitelink = (value) => {
        setWebsiteLink(value)

    }

    const checkWebsiteLinkOpen = () => {
        if (openWeblink) {
            props.sendLessonEventViaWebSocket({
                eventType: 'handleWebLink',
                eventData: {
                    'openWeblink': true,
                    'sentBy': props.userName,
                    'websiteLink': websiteLink
                }
            })
        }

    }

    useEffect(() => {
        setTimeout(() => {
            props.sendLessonEventViaWebSocket({
                eventType: 'isWebLinkOpen',
                eventData: {
                    'sentBy': props.userName,
                }
            })
        }, [5000])
    }, [])


    return (
        <Box>
            {/* <Tooltip title='Open Google Doc' placement="right">
                <IconButton
                    id='slate-pad-button'
                    sx={{ position: 'fixed', left: '15px', top: '30%', color: "#4285F4", zIndex: 100 }}
                    onClick={() => {
                        setOpenAsklink(true)

                    }
                    }
                    size="medium">

                    <TextSnippetIcon sx={{ backgroundColor: "#27292C", color: '' }} />
                </IconButton>
            </Tooltip> */}
            <Dialog
                open={openAsklink}
                onClose={() => {
                    setOpenAsklink(false)
                }}
            >
                <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', position: 'relative' }}>
                    <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', color: THEME_COLOR }}>
                        Import from Google Docs
                    </Typography>
                    <Divider />
                    <Typography sx={{ fontSize: '0.85rem', fontWeight: '400', margin: "15px 0 5px", maxWidth: '400px' }}>
                        Paste a link to a document from Google Docs here. This document will only be visible to those with access.(Ensure that the link is public)
                    </Typography>
                    <IconButton
                        sx={{ color: 'black', position: 'absolute', right: '5px', top: "5px", zIndex: 10000 }}
                        onClick={() => {
                            setOpenAsklink(false)
                            setWebsiteLink('')
                        }}
                        size="medium">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        justifyContent='center'
                        textAlign='center'
                        sx={{ display: 'flex', flexDirection: 'column', minWidth: { xs: '300px', sm: '350px', md: '375px', lg: '375px' } }}
                        size={{
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12
                        }}>
                        <form onSubmit={e => { e.preventDefault() }}>
                            <Paper
                                component="form"
                                sx={{ boxShadow: 'none', borderRadius: '25px', p: '2px 4px', display: 'flex', alignItems: 'center', backgroundColor: '#f2f2f2' }}
                            >
                                <IconButton type="button" sx={{ p: '10px' }} disabled size='small'>
                                    <InsertLinkIcon sx={{ color: '#000000' }} />
                                </IconButton>
                                <InputBase
                                    autoFocus={true}
                                    sx={{ borderRadius: '10px', ml: 1, flex: 1, width: '100%', height: '50px', color: THEME_COLOR }}
                                    placeholder="Enter Link"
                                    value={websiteLink}
                                    onChange={(e) => {
                                        setWebsiteLink(e.target.value)
                                    }}
                                    onFocus={() => {
                                        props.isTextFocused.current = true;
                                    }}
                                    onBlur={() => {
                                        props.isTextFocused.current = false;
                                    }}
                                />

                            </Paper>
                            <FormControl sx={{ width: '60%', marginBottom: '15px', marginTop: '15px', }}>
                                <Button
                                    type='submit'
                                    sx={{
                                        borderRadius: '10px',
                                        width: '100%',
                                        height: '40px',
                                        textTransform: 'none',
                                        backgroundColor: THEME_COLOR,
                                        color: '#FFFFFF',
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                        '&:hover': {
                                            backgroundColor: THEME_HOVER_COLOR,
                                            boxShadow: 'none',
                                        },

                                    }}
                                    onClick={() => {
                                        setOpenAsklink(false)
                                        setOpenWeblink(true)
                                        props.sendLessonEventViaWebSocket({
                                            eventType: 'handleWebLink',
                                            eventData: {
                                                'openWeblink': true,
                                                'sentBy': props.userName,
                                                'websiteLink': websiteLink
                                            }
                                        })
                                    }}
                                >
                                    Open Link
                                </Button>
                            </FormControl>
                        </form>
                    </Grid>
                </DialogContent>

            </Dialog>
            {
                openWeblink &&
                <Draggable handle=".handle-Slatepad">
                    <Box sx={{
                        position: 'fixed',
                        left: "80px",
                        bottom: "15%",
                        boxShadow: "2",
                        borderRadius: '10px',
                        background: 'white',
                        height: { xs: '40%', sm: '50%', md: '75%', lg: '75%' },
                        width: { xs: '60%', sm: '45%', md: '30%', lg: '65%', xl: '60%' },
                        zIndex: 99,
                        // resize: 'both',
                        // overflow: 'auto',
                    }}>
                        <Grid className='handle-Slatepad' container sx={{
                            color: 'black',
                            height: { xs: '7.5%', sm: '5%', md: '5%', lg: '5%' },
                            position: 'sticky',
                            right: '5px',
                            top: "5px",
                            zIndex: 10000,
                            // backgroundColor: 'grey',
                            borderRadius: '10px 10px 0 0',
                            "&:hover": { cursor: "move" }
                        }} justifyContent='right' textAlign='right'>
                            <Typography sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem', lg: '1.25rem' }, color: "#ffffff", fontWeight: 600, width: '80%', textAlign: 'center', justifyContent: 'center', paddingRight: "10%" }}>
                                Google Docs
                            </Typography>
                        </Grid>
                        <IconButton
                            sx={{ color: 'black', position: 'absolute', top: 0, right: 0, zIndex: 10001, fontSize: '1rem' }}
                            onClick={() => {
                                // console.log('hey')
                                setOpenWeblink(false);
                                setWebsiteLink('')
                            }}
                            size="small">
                            <CloseIcon sx={{ fontSize: '1rem' }} />
                        </IconButton>
                        <iframe
                            title='Weblink-Whiteboard'
                            height="92.5%"
                            width="100%"
                            style={{ border: 'none', padding: '5px' }}
                            src={websiteLink}
                        >
                        </iframe>
                        <Grid className='handle-Slatepad' container sx={{
                            color: 'black',
                            height: '2.5%',
                            position: 'sticky',
                            right: '10px',
                            bottom: 0,
                            zIndex: 10000,
                            "&:hover": { cursor: "move" }
                        }} justifyContent='right' textAlign='right'></Grid>
                    </Box>
                </Draggable>
            }
        </Box>
    );
});

export default Weblink;