import * as React from 'react';
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Button, IconButton, DialogActions, Popover, Box, Tooltip, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import { FRONTEND_URL, THEME_COLOR, THEME_HOVER_COLOR } from '../../config/constants';
import CloseIcon from '@mui/icons-material/Close';
import { getSlate, moveHostToParticipant, moveParticipantToHost, removeHostFromSlate, removeParticipantFromSlate, updateSessionState } from '../../service/slateService';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import InputEmail from './InputEmail';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { capitalizeFirstAlphabet } from '../../../../components/helperFunctions';
import CreateAvatar from './CreateAvatar';

const EditSlate = ({ accessToken, slateID, isTextFocused, sendLessonEventViaWebSocket }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [hostEmailIDsList, setHostEmailIDsList] = useState([]);
    const [participantEmailIDsList, setParticipantEmailIDsList] = useState([]);
    const [displayHostList, setDisplayHostList] = useState([]);
    const [displayParticipantList, setDisplayParticipantList] = useState([]);
    const [openAddMemberForm, setOpenAddMemberForm] = useState(false)
    // const [isParticipantListEmpty, setIsParticipantListEmpty] = useState(false);
    const [openCopiedToClipboard, setOpenCopiedToClipboard] = useState(false);
    const [slate, setSlate] = useState(null)
    const [openPopup, setOpenPopup] = useState(false);
    const [memberType, setMemberType] = useState('')
    const [currentMember, setCurrentMember] = useState('')

    useEffect(() => {
        if (slate) {
            setDisplayHostList(removeDuplicates(slate.hostDetails))
            setDisplayParticipantList(removeDuplicates(slate.participantDetails))
            setHostEmailIDsList(createEmailIdListArray(removeDuplicates(slate.hostDetails)))
            setParticipantEmailIDsList(createEmailIdListArray(removeDuplicates(slate.participantDetails)))
        }
    }, [slate])

    useEffect(() => {
        initiliazeSlate()
    }, [openAddMemberForm])

    const initiliazeSlate = async () => {
        const tempSlate = await getSlateByID()
        setSlate(tempSlate)
    }

    const getSlateByID = async () => {
        let token = accessToken;
        if (token !== null) {
            const SlateIDObject = {
                "id": slateID
            }
            // console.log('yooru')
            let res, status
            try {
                res = await getSlate(token, SlateIDObject)
                status = res.status
            }
            catch (err) {
                //      console.log(err)
                if (err && err.response) {
                    status = err.response.status;
                }
            }
            if (status === 200) {
                //      console.log(res.data)
                return res.data
            }



            // console.log(userData.data.user.id)

        }
    }

    const createEmailIdListArray = (objectArray) => {
        const emailIdArray = []
        objectArray.map((element) => {
            emailIdArray.push(element.email)
        })
        return emailIdArray;
    }

    function removeDuplicates(arr) {
        return arr.filter((element, index, array) => array.findIndex(subElement => subElement.email === element.email) === index);

    }


    const getSessionLink = () => {

        return FRONTEND_URL + 'slate/' + slate.id + '?forwardLink=' + 'lesson'

    }

    const handleClose = () => {
        setOpenAddMemberForm(false);

    }

    const handleSetParticipant = (value) => {
        setParticipantEmailIDsList(value)
    }
    const handleSetHost = (value) => {
        setHostEmailIDsList(value)
    }


    const updateSessionStateandSendEvent = async (sessionStateData) => {
        let status, res;
        // console.log(sessionStateData)
        try {
            res = await updateSessionState(window.lesson.accessToken, window.lesson.session.id, JSON.stringify(sessionStateData), window.lesson.slate.id);
            status = res.status
        }
        catch (err) {
            console.log(err);
            status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            sendLessonEventViaWebSocket({
                eventType: 'sessionStateUpdated',
                eventData: {
                    'sentBy': window.lesson.user.id,
                    'sessionState': sessionStateData
                }
            })
            window.lesson.sessionState = sessionStateData
        }
        else {
            alert("Could not update permissions")
            console.log(status)
        }
    }

    const makeHostToParticipant = async () => {
        const SlateIDObject = {
            "slateId": slate.id,
            "userEmailId": currentMember.email
        }
        // console.log(SlateIDObject)
        let status, res;
        try {
            res = await moveHostToParticipant(accessToken, SlateIDObject)
            status = res.status
        }
        catch (err) {
            console.log(err)
            status = err.response.status
        }
        // console.log(res);
        if (status === 200) {
            // console.log(currentMember.email)
            const newEmailList = hostEmailIDsList.filter((data) => data !== currentMember.email);
            // console.log(newList)
            setHostEmailIDsList(newEmailList)
            setParticipantEmailIDsList(prevValue => [...prevValue, currentMember.email])

            const newList = displayHostList.filter((data) => data.email !== currentMember.email)
            setDisplayHostList(newList)
            setDisplayParticipantList(prevValue => [...prevValue, currentMember])
            // console.log(currentMember)
            let sessionStateData = JSON.parse(JSON.stringify(window.lesson.sessionState))
            let arr = sessionStateData.hosts.filter(obj => {
                return obj.id !== currentMember.user_id
            })
            sessionStateData.hosts = arr
            sessionStateData.participants.push(
                {
                    'id': currentMember.user_id,
                    'permissions': {
                        cameraEnabled: true,
                        deleteAllEnabled: false,
                        micEnabled: true,
                        panEnabled: false,
                        recordingEnabled: false,
                        screenSharingEnabled: true,
                        toolBarEnabled: true,
                        zoomEnabled: false
                    }
                }
            )
            // console.log(sessionStateData)
            updateSessionStateandSendEvent(sessionStateData)
        }
        else if (status === 401) {
            console.log(status);
        }
        else {
            console.log(status);

        }
    }
    const removeHost = async () => {
        const SlateIDObject = {
            "slateId": slate.id,
            "userEmailId": currentMember.email
        }
        // console.log(SlateIDObject)
        let status, res;
        try {
            res = await removeHostFromSlate(accessToken, SlateIDObject)
            status = res.status
        }
        catch (err) {
            console.log(err)
            status = err.response.status
        }
        // console.log(res);
        if (status === 200) {
            const newEmailList = hostEmailIDsList.filter((data) => data !== currentMember.email);
            setHostEmailIDsList(newEmailList)
            const newList = displayHostList.filter((data) => data.email !== currentMember.email);
            setDisplayHostList(newList)
            let sessionStateData = JSON.parse(JSON.stringify(window.lesson.sessionState))
            let arr = sessionStateData.hosts.filter(obj => {
                return obj.id !== currentMember.user_id
            })
            sessionStateData.hosts = arr
            updateSessionStateandSendEvent(sessionStateData)
        }
        else if (status === 401) {
            console.log(status);
        }
        else {
            console.log(status);

        }
    }
    const makeParticipantToHost = async () => {
        const SlateIDObject = {
            "slateId": slate.id,
            "userEmailId": currentMember.email
        }
        // console.log(SlateIDObject)
        let status, res;
        try {
            res = await moveParticipantToHost(accessToken, SlateIDObject)
            status = res.status
        }
        catch (err) {
            console.log(err)
            status = err.response.status
        }
        // console.log(res);
        if (status === 200) {
            const newEmailList = participantEmailIDsList.filter((data) => data !== currentMember.email);
            // console.log(newList)
            setParticipantEmailIDsList(newEmailList)
            setHostEmailIDsList(prevValue => [...prevValue, currentMember.email])

            const newList = displayParticipantList.filter((data) => data.email !== currentMember.email)
            setDisplayParticipantList(newList)
            setDisplayHostList(prevValue => [...prevValue, currentMember])

            let sessionStateData = JSON.parse(JSON.stringify(window.lesson.sessionState))
            const arr = sessionStateData.participants.filter(obj => {
                return obj.id !== currentMember.user_id
            })
            sessionStateData.participants = arr
            sessionStateData.hosts.push(
                {
                    'id': currentMember.user_id,
                    'permissions': {
                        cameraEnabled: true,
                        deleteAllEnabled: true,
                        micEnabled: true,
                        panEnabled: true,
                        recordingEnabled: true,
                        screenSharingEnabled: true,
                        toolBarEnabled: true,
                        zoomEnabled: true
                    }
                }
            )
            // console.log(sessionStateData)
            updateSessionStateandSendEvent(sessionStateData)
        }
        else if (status === 401) {
            console.log(status);
        }
        else {
            console.log(status);

        }
    }
    const removeParticipant = async () => {
        const SlateIDObject = {
            "slateId": slate.id,
            "userEmailId": currentMember.email
        }
        // console.log(SlateIDObject)
        let status, res;
        try {
            res = await removeParticipantFromSlate(accessToken, SlateIDObject)
            status = res.status
        }
        catch (err) {
            console.log(err)
            status = err.response.status
        }
        // console.log(res);
        if (status === 200) {
            const newEmailList = participantEmailIDsList.filter((data) => data !== currentMember.email);
            // console.log(newList)
            setParticipantEmailIDsList(newEmailList)
            const newList = displayParticipantList.filter((data) => data.email !== currentMember.email);
            setDisplayParticipantList(newList)
            let sessionStateData = JSON.parse(JSON.stringify(window.lesson.sessionState))
            const arr = sessionStateData.participants.filter(obj => {
                return obj.id !== currentMember.user_id
            })
            sessionStateData.participants = arr
            updateSessionStateandSendEvent(sessionStateData)
        }
        else if (status === 401) {
            console.log(status);
        }
        else {
            console.log(status);

        }
    }

    return (<>
        <Tooltip title='Add members'>
            <IconButton
                sx={{
                    textTransform: 'none',
                    color: "#000000",
                    marginRight: '20px',
                    borderRadius: '5px',
                    border: '1.5px solid #D3D3D3',
                    marginTop: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                    marginBottom: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                    width: { xs: '20px', sm: '40px', md: '40px', lg: '60px' }
                }}
                size='small'
                onClick={(event) => {

                    setOpenAddMemberForm(true)
                }}
            >
                <PersonAddAltIcon sx={{ fontSize: { xs: "0.75rem", sm: '1.5rem', md: "1.5rem", lg: '1.5rem', xl: '1.5rem' } }} />
            </IconButton>
        </Tooltip>
        <Dialog fullWidth maxWidth="sm" open={openAddMemberForm} onClose={() => { setOpenAddMemberForm(false) }} fullScreen={fullScreen} >
            <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', position: 'relative' }}>
                <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', color: THEME_COLOR }}>
                    Add Member(s)
                </Typography>

                <IconButton
                    sx={{ color: 'black', position: 'absolute', right: '5px', top: "5px", zIndex: 10000 }}
                    onClick={() => {
                        handleClose()
                    }}
                    size="small">
                    <CloseIcon sx={{ fontSize: '1rem' }} />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid
                    container
                    justifyContent='center'
                    textAlign='center'
                    sx={{ display: 'flex', flexDirection: 'column' }}
                    size={{
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12
                    }}>


                    <InputEmail
                        slate={slate}
                        hostList={hostEmailIDsList}
                        participantList={participantEmailIDsList}
                        setHostList={handleSetHost}
                        setParticipantList={handleSetParticipant}
                        accessToken={accessToken}
                        placeholderName='EmailID'
                        isTextFocused={isTextFocused}
                        setDisplayHostList={setDisplayHostList}
                        setDisplayParticipantList={setDisplayParticipantList}
                    />
                    {/* {isParticipantListEmpty && <Typography variant='body2' sx={{ color: 'red', marginBottom: '15px', paddingRight: "30px" }}>No participant added yet</Typography>} */}

                    <Box sx={{ display: 'flex', flexDirection: 'column', margin: '5px', flexWrap: 'wrap' }}>
                        {slate && <Grid container
                            sx={{
                                marginTop: '2.5px',
                                width: '100%',
                                padding: '2.5px 0'
                            }}
                            justifyContent='left' alignItems='left' textAlign='left'
                        >

                            <Grid
                                justifyContent='left'
                                alignItems='center'
                                textAlign='left'
                                sx={{
                                    whiteSpace: 'normal !important',
                                    wordWrap: 'break-word !important',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                                size={{
                                    xs: 8,
                                    sm: 8,
                                    md: 9,
                                    lg: 9
                                }}>
                                {slate && <CreateAvatar data={slate.creatorDetails} accessToken={accessToken} />}

                                <Typography sx={{
                                    fontSize: { xs: '0.6rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem', xl: '0.8rem' },
                                    color: '#828282',
                                    display: "inline-block",
                                    overflow: 'hidden',
                                    wordWrap: "break-word",
                                }}>
                                    <span style={{ color: "#4f4f4f" }}>
                                        {slate && slate.creatorDetails.firstName && slate.creatorDetails.lastName && slate.creatorDetails.firstName !== '' && slate.creatorDetails.lastName !== '' && (capitalizeFirstAlphabet(slate.creatorDetails.firstName) + ' ' + capitalizeFirstAlphabet(slate.creatorDetails.lastName))}
                                    </span>
                                    <br></br>{slate && slate.creatorDetails.email}
                                </Typography>
                            </Grid>
                            <Grid
                                justifyContent='right'
                                alignItems='center'
                                textAlign='right'
                                sx={{ display: 'flex', flexDirection: 'row' }}
                                size={{
                                    xs: 4,
                                    sm: 4,
                                    md: 3,
                                    lg: 3
                                }}>

                                <Typography sx={{ fontSize: '0.8rem', marginRight: '1rem', fontWeight: 700 }}>
                                    Creator
                                </Typography>

                            </Grid>

                        </Grid>}

                        {displayHostList.map((data, index) => {
                            return (
                                (<Grid container
                                    key={index}
                                    sx={{
                                        marginTop: '2.5px',
                                        width: '100%',
                                        padding: '2.5px 0'
                                    }}
                                    justifyContent='left' alignItems='left' textAlign='left'
                                >
                                    <Grid
                                        justifyContent='left'
                                        alignItems='center'
                                        textAlign='left'
                                        sx={{
                                            whiteSpace: 'normal !important',
                                            wordWrap: 'break-word !important',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}
                                        size={{
                                            xs: 8,
                                            sm: 8,
                                            md: 9,
                                            lg: 9
                                        }}>
                                        <CreateAvatar data={data} accessToken={accessToken} />
                                        <Typography sx={{
                                            fontSize: { xs: '0.6rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem', xl: '0.8rem' },
                                            color: '#828282',
                                            display: "inline-block",
                                            overflow: 'hidden',
                                            wordWrap: "break-word",
                                        }}>
                                            <span style={{ color: "#4f4f4f" }}>
                                                {data.firstName && data.lastName && data.firstName !== '' && data.lastName !== '' && (capitalizeFirstAlphabet(data.firstName) + ' ' + capitalizeFirstAlphabet(data.lastName))}
                                            </span>
                                            <br></br>
                                            {data.email}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        justifyContent='right'
                                        alignItems='center'
                                        textAlign='right'
                                        sx={{ display: 'flex', flexDirection: 'row' }}
                                        size={{
                                            xs: 4,
                                            sm: 4,
                                            md: 3,
                                            lg: 3
                                        }}>

                                        <Typography sx={{ fontSize: '0.8rem', fontWeight: 700 }}>
                                            Host
                                        </Typography>
                                        <IconButton
                                            sx={{
                                                textTransform: 'none',
                                                color: THEME_COLOR,
                                                '&:hover': {
                                                    color: THEME_COLOR,
                                                    boxShadow: 'none',
                                                },
                                                height: '0.8rem',
                                                width: '0.8rem',
                                                fontSize: '0.8rem'
                                            }}
                                            onClick={(event) => {
                                                setOpenPopup(event.currentTarget)
                                                setMemberType('host')
                                                setCurrentMember(data)
                                            }}
                                        >
                                            <ArrowDropDownIcon sx={{ fontSize: '1rem' }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>)
                            );
                        })}
                        {displayParticipantList.map((data, index) => {
                            return (
                                (<Grid container
                                    key={index}
                                    sx={{
                                        marginTop: '2.5px',
                                        width: '100%',
                                        padding: '2.5px 0'
                                    }}
                                    justifyContent='left' alignItems='left' textAlign='left'
                                >
                                    <Grid
                                        justifyContent='left'
                                        alignItems='center'
                                        textAlign='left'
                                        sx={{
                                            whiteSpace: 'normal !important',
                                            wordWrap: 'break-word !important',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}
                                        size={{
                                            xs: 8,
                                            sm: 8,
                                            md: 9,
                                            lg: 9
                                        }}>
                                        <CreateAvatar data={data} accessToken={accessToken} />
                                        <Typography sx={{
                                            fontSize: { xs: '0.6rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem', xl: '0.8rem' },
                                            color: '#828282',
                                            display: "inline-block",
                                            overflow: 'hidden',
                                            wordWrap: "break-word",
                                        }}>
                                            <span style={{ color: "#4f4f4f" }}>
                                                {data.firstName && data.lastName && data.firstName !== '' && data.lastName !== '' && (capitalizeFirstAlphabet(data.firstName) + ' ' + capitalizeFirstAlphabet(data.lastName))}
                                            </span>
                                            <br></br>
                                            {data.email}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        justifyContent='right'
                                        alignItems='center'
                                        textAlign='right'
                                        sx={{ display: 'flex', flexDirection: 'row' }}
                                        size={{
                                            xs: 4,
                                            sm: 4,
                                            md: 3,
                                            lg: 3
                                        }}>

                                        <Typography sx={{ fontSize: '0.8rem', fontWeight: 700 }}>
                                            Participant
                                        </Typography>
                                        <IconButton
                                            sx={{
                                                textTransform: 'none',
                                                color: THEME_COLOR,
                                                '&:hover': {
                                                    color: THEME_COLOR,
                                                    boxShadow: 'none',
                                                },
                                                height: '0.8rem',
                                                width: '0.8rem',
                                                fontSize: '0.8rem'
                                            }}
                                            onClick={(event) => {
                                                setOpenPopup(event.currentTarget)
                                                setMemberType('participant')
                                                setCurrentMember(data)
                                            }}
                                        >
                                            <ArrowDropDownIcon sx={{ fontSize: '1rem' }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>)
                            );
                        })}
                    </Box>
                    <Popover
                        open={openPopup}
                        anchorEl={openPopup}
                        onClose={() => setOpenPopup(false)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Box sx={{ backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column', borderRadius: '5%' }} >


                            <Button
                                sx={{
                                    backgroundColor: '#ffffff',
                                    color: '#adadad',
                                    textTransform: 'none',
                                    "&:hover": { backgroundColor: "#ffffff", boxShadow: '4' }

                                }}
                                onClick={() => {
                                    if (memberType === 'host') {
                                        makeHostToParticipant()
                                    }
                                    else {
                                        makeParticipantToHost()
                                    }
                                    setOpenPopup(false)
                                }}

                            >
                                {memberType === 'host' ? 'Make Participant' : 'Make Host'}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: '#ffffff',
                                    color: '#adadad',
                                    textTransform: 'none',
                                    "&:hover": { backgroundColor: "#ffffff", boxShadow: '4' }

                                }}
                                onClick={() => {
                                    if (memberType === 'host') {
                                        removeHost()
                                    }
                                    else {
                                        removeParticipant()
                                    }
                                    setOpenPopup(false)
                                }}

                            >
                                Remove
                            </Button>
                        </Box>

                    </Popover>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button sx={{

                    color: THEME_COLOR,
                    textTransform: "none",
                    fontWeight: 600,
                    '&:hover': {
                        backgroundColor: THEME_HOVER_COLOR,
                        boxShadow: 'none',
                    },

                }}
                    autoFocus
                    onClick={(event) => {
                        const url = getSessionLink();
                        navigator.clipboard.writeText(url)
                        setOpenCopiedToClipboard(event.currentTarget)
                        setTimeout(() => {
                            setOpenCopiedToClipboard(false)
                        }, 500)
                    }}>
                    Copy Link
                </Button>
            </DialogActions>
        </Dialog>
        <Popover
            open={openCopiedToClipboard}
            anchorEl={openCopiedToClipboard}
            onClose={() => {
                setOpenCopiedToClipboard(false)

            }}
            anchorOrigin={{
                vertical: 0,
                horizontal: 0,
            }}
        >
            <Box sx={{ backgroundColor: "#525252" }}>
                <Typography sx={{ fontSize: '0.8rem', color: '#FFFFFF', padding: '5px', fontWeight: 500 }}>
                    Copied!
                </Typography>
            </Box>

        </Popover>
    </>);
};

export default EditSlate;
