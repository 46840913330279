import React, { useEffect, useImperativeHandle, useRef, useState } from "react";

import { createTheme, ThemeProvider, Tooltip, CircularProgress, Typography, Box, } from "@mui/material";
import Grid from '@mui/material/Grid2';
import IconButton from "@mui/material/IconButton";


// import { createTheme, ThemeProvider } from "@mui/material/styles";

// import ClearIcon from "@mui/icons-material/Clear";

// import RemoveIcon from "@mui/icons-material/Remove";
import SketchField from "./src/components/SketchField2/SketchField";
import { v4 as uuidv4 } from 'uuid';
import { pdfjs, } from "react-pdf";
import { Image, Page, Document, pdf } from "@react-pdf/renderer";
import moment from "moment";
import * as fabric from 'fabric'
import { PeerBrush } from './PeerBrush'
import PanToolIcon from '@mui/icons-material/PanTool';
// import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import ToJSONFields from "./src/toJSONFields";
import { createIframeUsingJavascript, getLastUpdatedEventsInAscending } from "./src/utils";
import debounce from 'lodash.debounce';
import { DataPacket_Kind } from 'livekit-client';
import fastChunkString from '@shelf/fast-chunk-string';
import axios from 'axios';
import CustomToolbar from "./CustomToolbar";
import { getSessionsBySlate, listWhiteboardFiles, uploadFile, uploadWhiteboardFiles } from "./src/service/slateService";
import DownloadIcon from "@mui/icons-material/CloudDownload";
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { BACKEND_API_URL, MAX_ZOOM, MIN_ZOOM, PREVIOUS_WHITEBOARD_FILE_LOADED_EVENT_NAME, STREAM_FILE_SERVER, THEME_COLOR, UPLOAD_API_URL, WHITEBOARD_EVENTS_LOADED_EVENT_NAME } from "./src/config/constants";
import { getSessionStartTimeByID, getSlateTitleByID, recordSessionEvents } from "../components/helperFunctions";
import { getWhiteboardFileName, getYoutubeLinkId, isValidUrl } from "../components/utils";
import CustomLoader from '../components/CustomLoader/CustomLoader';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { getWhiteboardAudioBlob } from "../services/recordingService";
import WhiteboardPlayer from "./src/components/WhiteboardPlayer/WhiteboardPlayer";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const PREFIX = 'whiteboard2';
const classes = {
    canvasArea: `${PREFIX}-canvasArea`,
    iconButton: `${PREFIX}-iconButton`,
    addText: `${PREFIX}-addText`
};


// Storage.configure(awsmobile)

//pdfjs.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.5.136/pdf.min.mjs"
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;



// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.mjs',
//     import.meta.url,
//   ).toString();


const styles = {
    root: {
        padding: "3px",
        display: "flex",
        flexWrap: "wrap",
        margin: "10px 10px 5px 10px",
        justifyContent: "space-around",
    },
    gridList: {
        width: "100%",
        marginBottom: "24px",
    },
    gridTile: {
        backgroundColor: "#fcfcfc",
    },
    appBar: {
        // backgroundColor: "#311b92",
        // minHeight:"70px",

    },
    radioButton: {
        marginTop: "3px",
        marginBottom: "3px",
    },
    separator: {
        height: "42px",
        backgroundColor: "white",
    },
    iconButton: {
    },
    dropArea: {
        width: "100%",
        height: "64px",
        border: "2px dashed rgb(102, 102, 102)",
        borderStyle: "dashed",
        borderRadius: "5px",
        textAlign: "center",
        paddingTop: "20px",
    },
    activeStyle: {
        borderStyle: "solid",
        backgroundColor: "#eee",
    },
    rejectStyle: {
        borderStyle: "solid",
        backgroundColor: "#ffdddd",
    },
    card: {
        margin: "5px",
        padding: "5px",
        border: "0.1px solid #9fa3ab"
    },
    selected: {
        backgroundColor: "#532efe",
    },
    expandedCard: {
        position: "relative",
        bottom: "140px",
        padding: "5px",
        margin: "10px",
    }
};
//zoom factors
const zoomInFactor = 20 / 19;
const zoomOutFactor = 19 / 20;
const panFactorIcon = 1;
const panFactorArrowShortcuts = 0.25;
/**
 * Helper function to manually fire an event
 *
 * @param el the element
 * @param etype the event type
 */

var recordedBlobs = [];
var allClips = [];
var mediaRecorder;

function WhiteBoard(props, ref) {
    const { clientID, whiteBoardID } = props
    const theme = createTheme();
    // const longLiveKitMessageArr = useRef(new Map());
    const peerRefs = useRef(null);
    const myPathUUID = useRef(null);
    const isSearchFocus = useRef(null);
    const _customToolbar = useRef(null);
    const _sketch = useRef(null);
    const [drawings, setDrawings] = useState([]);
    const [controlledSize, setControlledSize] = useState(true);
    const [sketchWidth, setSketchWidth] = useState('100%');
    const [sketchHeight, setSketchHeight] = useState('100vh');
    const [text, setText] = useState("Type here!");
    // const [clientID, setClientID] = useState(props.clientID ? props.clientID : uuidv4());
    const [onCreateWhiteBoardEventSubscription, setOnCreateWhiteBoardEventSubscription] = useState(null);
    // const [whiteBoardID, setWhiteBoardID] = useState(props.whiteBoardID ? props.whiteBoardID : uuidv4());
    const [isPlayer, setIsPlayer] = useState(Boolean(props.isPlayer));
    const [canvasImageObjects, setCanvasImageObjects] = useState([]);
    const [timeouts, setTimeouts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [mouseDown, setMouseDown] = useState(false);
    const [shouldSendDataToAllPeers, setShouldSendDataToAllPeers] = useState(props.shouldSendDataToAllPeers);
    // const [databaseModifiedOrRemovedObjects, setDatabaseModifiedOrRemovedObjects] = useState([]);
    const [isPreviousWhiteboardFileLoading, setIsPreviousWhiteboardFileLoading] = useState(false);
    const [isFileDownloading, setIsFileDownloading] = useState(false);
    const [downloadingProgress, setDownloadingProgress] = useState(0);
    const [isCanvasInitialized, setIsCanvasInitialized] = useState(false);

    const whiteboardPlayerRef = useRef(null)
    const currentTool = useRef(null);
    const _selectedTool = useRef(null);
    const lineColorRef = useRef(null);
    const lineWidthRef = useRef(null);
    const highlighterWidthRef = useRef(null);
    const highlighterColorRef = useRef(null);
    const toolNameRef = useRef(null);
    const pageNumberRef = useRef(null)
    const databaseModifiedOrRemovedObjects = useRef([])

    useImperativeHandle(ref, () => ({
        _sketch,
        pasteImage,
        uploadWhiteboardData,
        currentTool,
        processReceivedEvent,
        findObjectAndGetPositionAndDimensions,
        receivedDataFromPeer,
        _customToolbar,
        getAllEventsFromDB
    }));






    const _save = () => {
        let drawingsTemp = drawings;
        drawingsTemp.push(_sketch.current.toDataURL());
        setDrawings(drawingsTemp)
    };

    const _add = async (object) => {
        // console.log(object);
        if (object.type === "image") {
            await _sketch.current.addImg(object.src, object.isPdf, object);
        }
        else {

            _sketch.current.addObj(object)
        }
    };

    const _modify = (object) => {
        //console.save(_sketch.current.toDataURL(), "toDataURL.png");
        // _sketch.current.addModifiedObjectToUndoList(object, true)
        _sketch.current.modifyObj(object, false, true)

    };


    const _remove = (object) => {
        //console.save(_sketch.current.toDataURL(), "toDataURL.png");
        _sketch.current.removeObj(object)

    };

    const _removeMe = (index) => {
        let drawingsT = drawings;
        drawingsT.splice(index, 1);
        setDrawings(drawingsT)
    };


    /**
     *
     * @param {object} object -contains client id of user calling  the clear function on the whiteboard
     * this function clears the canvas and resets it
     * Also this function checks for any opened iframes and closes them as the transparent objects associated with them have been deleted
     */
    const _clear = (object) => {
        const objects = _sketch.current._fc.current.getObjects().slice()
        for (const obj of objects) {
            if (obj.objectType === 'iframe') {
                let iframeElement = document.getElementById(obj.objectID)
                if (iframeElement) {
                    iframeElement.remove()
                }
            }
        }
        _sketch.current.clear(object);
        handleBackgroundColor('#F8F8F8');
        handleTotalNumberOfPages(1);
        handlePageNumber(1);
        _changeBackgroundColor({
            color: '#F8F8F8',
            clientID: clientID
        })
    };

    const _removeSelected = () => {
        _sketch.current.removeSelected();
    };

    const _changePan = (obj, firePanChangedEvent = true) => {
        return _sketch.current.changePan(obj, firePanChangedEvent);
    }

    const _onSketchChange = () => {
        // let prev = canUndo;
        // let now = _sketch.current.canUndo();
        // if (prev !== now) {
        //     setCanUndo(now)
        // }
    };


    /**
  * Add textbox to canvas using this function
  */
    const _addText = () => {
        if (_customToolbar && _customToolbar.current) {
            _customToolbar.current._selectTool({ target: { value: "Select" } })
        }
        // console.log("adding text");
        _sketch.current.addText("      ")
    };

    /**
     * Add polyline to canvas using this function
     */
    const _addPolyline = (color) => {
        // console.log(_sketch.current)
        _sketch.current.addPolyline(color)
    }

    /**
     *
     * @param {object} e - object contains background change data
     * this function sets a new background for the canvas
     */

    const _changeBackgroundColor = (e) => {
        _sketch.current.changeBackgroundColor(e);
        // console.log("changing bg color to", e)
        handleBackgroundColor(e.color)

        if (e.patternColor) {
            if (_customToolbar && _customToolbar.current) {
                _customToolbar.current.setPatternColor(e.patternColor)
            }


        }

        if (e.backgroundPattern) {
            // console.log(e.patternType)
            if (_customToolbar && _customToolbar.current) {
                _customToolbar.current.setBackgroundType(e.patternType)
            }
        }
        else {
            if (_customToolbar && _customToolbar.current) {
                _customToolbar.current.setBackgroundType('None')
            }


        }
    }


    const getDecimals = (n) => {
        // Note that maximumSignificantDigits defaults to 3 so your decimals will be rounded if not changed.
        const parts = n.toLocaleString('en-US', { maximumSignificantDigits: 18 }).split('.')
        return parts.length > 1 ? Number('0.' + parts[1]) : 0
    }

    const onObjectAdded = (e) => {
        // console.log("target" + e)
        let eventData = e.target.toObject(ToJSONFields)
        // console.log("event data on object added" + eventData)
        if (eventData.objectID === undefined || !eventData.objectID) {

            let tempObjectID = uuidv4()
            // console.log("setting object ID" + tempObjectID)
            e.target.set('objectID', tempObjectID);

        }
        // console.log("event data on object added" + JSON.stringify(eventData))



        if (eventData.clientID === undefined && !eventData.initialState && !eventData.peer) {
            // console.log("setting client ID" + clientID)
            e.target.set('clientID', clientID);
            e.target.set('containerWidth', _sketch.current._fc.current.getWidth());
            e.target.set('containerHeight', _sketch.current._fc.current.getHeight());
            // console.log(_sketch.current._fc.current.getWidth(), _sketch.current._fc.current.getHeight())
            let objectID = e.target.objectID;
            // console.log(objectID)
            eventData = e.target.toObject(ToJSONFields);
            // console.log(eventData)
            // console.log(JSON.stringify(eventData))
            let eventType = "object:added";
            let o = e.target
            setTimeout(() => {
                if (o.objectType === 'iframe') {
                    let positionDimensionObj = findObjectAndGetPositionAndDimensions(o.objectID)
                    // console.log(positionDimensionObj)
                    // console.log(o.left, o.top)
                    createIframeUsingJavascript(positionDimensionObj, objectID, o.mediaFileUrl, _sketch.current.changeObjectPositionOnDrag, o.iframeType, props.clientID, whiteBoardID, props.accessToken)
                    let removeObjectByIdRef = _sketch.current.removeObjectById
                    document.getElementById(objectID + '-close').addEventListener('click', function (e) {
                        e.preventDefault();
                        document.getElementById(objectID).remove()
                        removeObjectByIdRef(objectID)
                    }, false)
                }
            }, [500])

            // console.log(eventData)
            createWhiteBoardEventFunc(whiteBoardID, clientID, eventType, JSON.stringify(eventData), objectID)
        }
        e.target.set('initialState', false);

    }

    const onBeforePathCreated = (e) => {


        //This method is called for all path objects before they are made objects (for brush its just after mouse up)
        // give us way to access the object created by brush
        //Further this method is called when objects are created by peer brush
        //        console.log("here we set the object id to the id depending upon whether its a peer object or ours ");
        if (e.peer === true) {
            e.path.objectID = e.objectID
            e.path.peer = true
        }
        else {
            e.path.objectID = myPathUUID.current;
        }


        myPathUUID.current = null

    }

    const onObjectModified = (e) => {
        //is being called only when we do the modification through UI so we always assume that this modification
        // is done by the current client
        //console.log("object modified called")
        let objectID = e.target.objectID
        // console.log(e.target.objectID);
        e.target.set('clientID', clientID);
        e.target.set('containerWidth', _sketch.current._fc.current.getWidth());
        e.target.set('containerHeight', _sketch.current._fc.current.getHeight());
        // console.log(e.target)
        let eventData = e.target.toObject(ToJSONFields)
        // console.log(JSON.stringify(eventData))
        // console.error("modified",eventData.initialState)
        let eventType = "object:modified";
        //  console.log("modified" + JSON.stringify(e))
        if (!eventData.initialState)
            createWhiteBoardEventFunc(whiteBoardID, clientID, eventType, JSON.stringify(eventData), objectID)
        e.target.set('initialState', false);

        // send event and object to the backend if the client id is the same as this client id

    }

    const onBackgroundColorChanged = (e) => {
        // console.error("bgchnaged", e.initialState)
        if (e.clientID === clientID && !e.initialState) {
            var objectID = uuidv4();
            var eventData = { objectID: objectID, ...e }
            var eventType = "backgroundColor:changed";
            createWhiteBoardEventFunc(whiteBoardID, clientID, eventType, JSON.stringify(eventData), objectID)

        }
        //console.log("onBackgroundColorChanged",e);
    }


    const onMouseUp = (newObj) => {
        //console.error("mousup",newObj.initialState)
        // console.log(newObj)
        if (newObj && newObj.objectID && !newObj.initialState) {
            console.log("mouseUp called", newObj)
            var objectID = newObj.objectID
            //console.log(newObj.objectID);
            newObj.set('clientID', clientID);
            newObj.set('containerWidth', _sketch.current._fc.current.getWidth());
            newObj.set('containerHeight', _sketch.current._fc.current.getHeight());
            var eventData = newObj.toObject(ToJSONFields)
            //console.log(JSON.stringify(eventData))
            var eventType = "object:modified";
            //  console.log("modified" + JSON.stringify(e))
            createWhiteBoardEventFunc(whiteBoardID, clientID, eventType, JSON.stringify(eventData), objectID)
        }

    }
    const onObjectRemoved = (e) => {
        e.target.set("clientID", clientID);
        let eventData = e.target.toObject(ToJSONFields)
        //console.error("removed",eventData.initialState)
        let eventType = "object:removed";
        let objectID = eventData.objectID;
        // console.log('whiteboard obj data on removed', eventData)
        if (eventData.removedBy === clientID && objectID && !eventData.initialState) {

            createWhiteBoardEventFunc(whiteBoardID, clientID, eventType, JSON.stringify(eventData), objectID)
        }
        // console.log("removed" + JSON.stringify(e))
        // send event and object to the backend if the client id is the same as this client id

    }
    const onPathCreated = (e) => {
        // console.log("path created" + JSON.stringify(e))
    }
    const onPanChanged = (eventData) => {
        var eventType = "pan:changed";
        //console.error("pan",eventData.initialState)
        var objectID = uuidv4();
        if (eventData.clientID === clientID && !eventData.initialState) {
            createWhiteBoardEventFunc(whiteBoardID, clientID, eventType, JSON.stringify(eventData), objectID)
        }
    }


    const onNewZoomChanged = (eventData) => {
        var eventType = "newZoom:changed";
        // console.error("zoom", eventData)
        var objectID = uuidv4();
        if (eventData.clientID === clientID && !eventData.initialState) {
            createWhiteBoardEventFunc(whiteBoardID, clientID, eventType, JSON.stringify(eventData), objectID)
        }
    }



    const onObjectMoving = (e) => {
        // console.log("moving" + JSON.stringify(e))
    }

    const onCanvasCleared = (eventData) => {
        var eventType = "canvas:objects:cleared";
        var objectID = uuidv4();
        if (eventData.clientID === clientID && !eventData.initialState) {
            createWhiteBoardEventFunc(whiteBoardID, clientID, eventType, JSON.stringify(eventData), objectID)
        }
    }
    // _add = (object) => _sketch.current.add(object);

    /**
     *
     *@param {uuid} whiteBoardID - unique  id of the current session
     * @param {uuid} clientId  - unique id of the user
     * @param {string} eventType - type of event being created. eg: 'file:added'
     * @param {object} eData - all the data associated with the event
     * @param {uuid} objectID - unique id of the event
     * creates a whiteboard event which is sent to the other client as well as uploaded to the database so that
       it can be processed accordingly
     */
    const createWhiteBoardEventFunc = async (whiteBoardID, clientId, eventType, eData, objectID) => {
        //    console.log("🚀 ~ file: main.jsx createWhiteBoardEvent Mutation ~ SketchFieldDemo ~ whiteBoardID,clientID,eventType,eventData", whiteBoardID, clientId, eventType, eData)
        // console.log("object id", objectID)
        if (!props.isViewer && !props.isWhiteboardPlayer) {
            var timeStampedEventData = JSON.parse(eData);
            timeStampedEventData = { ...timeStampedEventData, timestamp: moment().toISOString() }
            timeStampedEventData = JSON.stringify(timeStampedEventData);
            var eventData = timeStampedEventData;
            var eventDataWithParams = "$whiteBoardID=" + whiteBoardID + "$" + "$clientID=" + clientId + "$"
                + "$eventType=" + eventType + "$" + "$objectID=" + objectID + "$" + "$eventData=" + eventData + "$$"
            var eventDataToSend = "$whiteBoardEvent$" + eventDataWithParams
            let encodedEventDataToSend = new TextEncoder().encode(eventDataToSend)
            // console.log("event data encoded size" + encodedEventDataToSend.length)
            // console.log("the event data is " + eventDataWithParams);

            // if (props.isPublishWhiteBoardDataViaLiveKit) {
            //     if (encodedEventDataToSend.length < 64000) {
            //         console.log("publish data via live kit");
            //         props.liveKitRoom.localParticipant.publishData(encodedEventDataToSend, DataPacket_Kind.RELIABLE);

            //     }
            //     else {
            //         //split the original string into multiple chunks and then send chunks
            //         let chunks = fastChunkString(eventDataWithParams, { size: 64000, unicodeAware: false });
            //         let messageKey = uuidv4();
            //         // console.log("the message key sent" + messageKey);
            //         for (let index = 0; index < chunks.length; index++) {

            //             const chunk = chunks[index];
            //             var chunkStartStr = "$whiteBoardEvent$" + "$messageKey=" + messageKey + "$" + "$totalChunks=" + chunks.length + "$" + "$chunkIndex=" + index + "$";
            //             var chunkToSend = chunkStartStr + "$chunkDataSeperator$" + chunk
            //             let encodedChunk = new TextEncoder().encode(chunkToSend);
            //             console.log("publish data via live kit");
            //             props.liveKitRoom.localParticipant.publishData(encodedChunk, DataPacket_Kind.RELIABLE);
            //             //console.log(chunkToSend);


            //         }


            //     }

            // }
            if (!props.isWhiteboardPlayer) {
                props.sendWhiteBoardEventViaWebSocket({
                    whiteBoardID, clientId, eventType, eventData, objectID
                })

            }
        }
    }

    /**
     *
     * @param {uuid} whiteBoardID - unique  id of the current session
     * @returns {array} - returns array of all the events for the specific whiteboardID from the database
     * the events fetched from the database are sorted by created at before they are returned
     */
    async function getAllEventsFromDB(whiteBoardID) {
        const allEvents = []
        console.log("getting cached events")
        const res = await axios.get(BACKEND_API_URL + 'whiteBoard/getCachedWhiteBoardEventsByWhiteBoardId', {
            params: {
                whiteBoardId: whiteBoardID,
                token: props.accessToken
            }

        });
        // console.log("db event call completed" + res);
        for (let index = 0; index < res.data.whiteBoardEvents.length; index++) {
            allEvents.push({
                id: res.data.whiteBoardEvents[index].id,
                whiteBoardID: res.data.whiteBoardEvents[index]["whiteboard_id"],
                clientId: res.data.whiteBoardEvents[index]["client_id"],
                eventData: res.data.whiteBoardEvents[index]["event_data"],
                eventType: res.data.whiteBoardEvents[index]["event_type"],
                objectID: res.data.whiteBoardEvents[index]["object_id"],
                createdAt: res.data.whiteBoardEvents[index]["created_at"],
                updatedAt: res.data.whiteBoardEvents[index]["updated_at"]
            })

        }

        // console.log('all events length', allEvents.length)
        allEvents.sort(function (a, b) {
            return new Date(a.createdAt) - new Date(b.createdAt);
        });
        return allEvents





    }

    /**
     *
     * @param {string} blob - contains the image url to be pasted to the canvas
     * @param {function} addImg -function of sketchfield to add image to canvas
     * @param {uuid} whiteBoardID - whiteboard id of current session
     * @param {boolean} isBlobBase64 - to check if blob is a  base 64 string or not
     * this function uploads the image to the server and calls addimage of sketchfield to add the given image to whiteboard
     */
    const pasteImage = async (blob, addImg, whiteBoardID, isBlobBase64) => {
        // if (file && file.size > 1000000) {
        //     handleChangeLoading(false);
        //     enqueueSnackbar("file size limit exceeded!(file size less than 1MB is allowed)", { variant: "error" });
        //     return
        // }
        _customToolbar.current.handleChangeUploading(true)
        if (blob) {
            if (isBlobBase64) {
                var block = blob.split(";");
                // Get the content type
                var contentType = block[0].split(":")[1];
                // get the real base64 content of the file
                var realData = block[1].split(",")[1];// In this case "iVBORw0KGg...."

                // Convert to blob
                blob = b64toBlob(realData, contentType);
            }
            // const fileLink = "https://" + awsmobile.aws_user_files_s3_bucket + ".s3.ap-south-1.amazonaws.com/public/";
            let storageFileLink;
            // const fileLink = "https://" + awsmobile.aws_user_files_s3_bucket + ".s3.ap-south-1.amazonaws.com/public/";
            var currentTime = moment().toISOString();
            let filename = currentTime;
            const imageData = new FormData();
            // console.log(blob)
            const myFile = new File([blob], filename + '.png', {
                type: blob.type,
            });
            imageData.append('file', myFile);
            let status, res;
            try {
                res = await uploadWhiteboardFiles(props.accessToken, whiteBoardID, 'images', imageData);
                status = res.status
            }

            catch (err) {
                console.log(err);
                status = err.response.status;
            }
            // console.log(index)
            if (status === 200) {
                // alert('file uploaded')
            }
            else {
                console.log(status)
            }
            storageFileLink = UPLOAD_API_URL + "uploads/whiteBoardStorage/" + whiteBoardID + '/images/' + filename + '.png'
            // console.log("storage file link is " + storageFileLink);



            await addImg(storageFileLink, false)

        }
        // document.getElementById('image-to-upload').value = null;
        _customToolbar.current.handleChangeUploading(false);

    }

    const processReceivedEvent = async (whiteBoardEvent, initialState = false) => {
        // console.log(whiteBoardEvent)
        if (!props.isWhiteboardPlayer && !(whiteBoardEvent.clientId !== clientID || initialState)) return;
        // console.log("whiteBoard:processReceivedEvent", whiteBoardEvent.eventType, initialState);
        if (whiteBoardEvent.eventType === "file:added") {
            let eventdata = JSON.parse(whiteBoardEvent.eventData);
            // console.log(eventdata.canvasFileUrl)
            processCanvasToWhiteboardEvent(eventdata.canvasFileUrl, eventdata.objectIdSuffix)
        }
        if (whiteBoardEvent.eventType === "newZoom:changed") {
            let eventdata = JSON.parse(whiteBoardEvent.eventData);
            // console.log(eventdata)
            if (eventdata.zoomLevel >= MIN_ZOOM && eventdata.zoomLevel <= MAX_ZOOM) {
                handleCanvasZoomLevel(eventdata.zoomLevel)
            }
            _sketch.current.newZoom(eventdata, false)
        }
        if (whiteBoardEvent.eventType === "object:added") {
            //console.log(whiteBoardEvent.clientId);
            //if(!initialState)
            //  console.log("object added  by other client",whiteBoardEvent);
            //else
            //  console.log("object added on initializing",whiteBoardEvent)
            // console.log("object received" );
            // console.log(whiteBoardEvent.eventData);
            let scaledJson = scaleJSON(JSON.parse(whiteBoardEvent.eventData));
            scaledJson = {
                ...scaledJson,
                initialState: initialState,
            }


            _add(scaledJson);
        }
        if (whiteBoardEvent.eventType === "object:modified") {
            let scaledJson = scaleJSON(JSON.parse(whiteBoardEvent.eventData));
            scaledJson = {
                ...scaledJson,
                initialState: initialState,
            }
            _modify(scaledJson);
        }
        if (whiteBoardEvent.eventType === "object:removed") {
            console.log('obj removed received')
            let json = JSON.parse(whiteBoardEvent.eventData)

            json = {
                ...json,
                initialState: initialState,
            }
            _remove(json);
            if (json.objectType === 'iframe') {
                // console.log('iframe deleted')
                document.getElementById(json.objectID).remove()
            }
        }
        if (whiteBoardEvent.eventType === "canvas:objects:cleared") {
            let json = JSON.parse(whiteBoardEvent.eventData)
            json = {
                ...json,
                initialState: initialState,
            }
            _clear(json);
        }
        if (whiteBoardEvent.eventType === "backgroundColor:changed") {

            let json = JSON.parse(whiteBoardEvent.eventData)
            json = {
                ...json,
                initialState: initialState,
            }
            _changeBackgroundColor(json);
        }
        if (whiteBoardEvent.eventType === "pan:changed") {

            let obj = JSON.parse(whiteBoardEvent.eventData);
            //_sketch.current._fc.current.absolutePan(point)
            let { offsetWidth = 1, clientHeight = 1 } = _sketch.current._container.current;
            let { containerWidth = 1, containerHeight = 1 } = obj;
            let wfactor = (offsetWidth / containerWidth);
            let hfactor = (clientHeight / containerHeight);
            let viewportTransform = obj.viewportTransform;
            viewportTransform[4] = viewportTransform[4] * wfactor;
            viewportTransform[5] = viewportTransform[5] * hfactor;
            let scaledJson = {
                ...obj,
                viewportTransform: viewportTransform,
                initialState: initialState
            }
            _changePan(scaledJson);
        }

    }

    /**
 *
 * @param {uuid} slateID - slateID is the id of the slate which contains all the sessiopns for a specific slate
 * @param {string} accessToken - used to access data from database
 * @returns {array} - returns the list of all the sessions for a particular slateID
 */

    const getSessionsList = async (slateID, accessToken) => {
        // console.log(accessToken);

        const SlateIDObject = {
            "slateId": slateID
        }
        let res, status;
        try {
            res = await getSessionsBySlate(accessToken, SlateIDObject)
            status = res.status
        }
        catch (err) {
            console.log(err)
            status = err.response.status
        }

        if (status === 200) {

            // console.log(res.data.sessions)
            return res.data.sessions
        }
        else {
            return ''
        }

    };

    /**
     *
     * @param {uuid} slateID - slateID is the id of the slate which contains all the sessiopns for a specific slate
     * @param {string} accessToken - used to access data from database
     * @returns {uuid} - returns the id of the previous session
     */

    const getPreviousWhiteboard = async (slateID, accessToken, whiteBoardID) => {
        const sessionsArray = await getSessionsList(slateID, accessToken);

        // Sort the sessions by start_time in ascending order (oldest first, latest last)
        sessionsArray.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));

        // Iterate to find the session just before the provided whiteBoardID
        for (let i = 0; i < sessionsArray.length; i++) {
            if (sessionsArray[i].id === whiteBoardID) {
                // Return the session before the current one (i - 1) if it exists
                if (i - 1 >= 0) {
                    return sessionsArray[i - 1];
                } else {
                    return null; // No session before this one
                }
            }
        }

        // If whiteBoardID is not found in the array, return null
        return null;
    };

    useEffect(() => {
        // console.log("the component is mounting");
        // longLiveKitMessageArr.current = [];

        const keydownListener = (keyDownEvent) => {
            const { key } = keyDownEvent;
            // console.log(props.isAddMessageFocusRef)
            if (!isSearchFocus.current && !props.isTextFocused.current) {


                if (_sketch.current && _sketch.current._fc.current.getActiveObject() && _sketch.current._fc.current.getActiveObject().type === "i-text")
                    return

                if (!props.isViewer) {
                    if (keyDownEvent.shiftKey && keyDownEvent.key === 'R') {
                        _customToolbar.current.setLineColor("#C91500");
                    }

                    if (keyDownEvent.shiftKey && keyDownEvent.key === 'B') {
                        _customToolbar.current.setLineColor("#2100F1")
                    }

                    if (keyDownEvent.shiftKey && keyDownEvent.key === 'P') {
                        _customToolbar.current.setLineColor("#9900EF")
                    }
                    if (keyDownEvent.shiftKey && keyDownEvent.key === 'G') {
                        _customToolbar.current.setLineColor("#0F531A")
                    }
                    if (keyDownEvent.shiftKey && keyDownEvent.key === 'Y') {
                        _customToolbar.current.setLineColor("#FFFA00")
                    }
                    if (key === 'N' || key === 'n') {
                        document.getElementById('clipping-button').click()
                    }

                    if (key === "Delete")
                        _removeSelected();

                    if (key === "t" || key === 'T')
                        _addText();

                    if (key === "u" || key === "U") {
                        document.getElementById("file-to-upload").click()
                    }

                    if (key === "p" || key === "P" || key === "w" || key === "P" || key == "W") {
                        _customToolbar.current._selectTool({ target: { value: "Pencil" } })
                    }
                    if (key === "h" || key === "H")
                        _customToolbar.current._selectTool({ target: { value: "Highlighter" } })
                    if (key === "e" || key === "E")
                        _customToolbar.current._selectTool({ target: { value: "Eraser" } })
                    if (key === "s" || key === "S")
                        _customToolbar.current._selectTool({ target: { value: "Select" } })
                    if (key === "l" || key === "L")
                        _customToolbar.current._selectTool({ target: { value: "Line" } })
                    if (!keyDownEvent.ctrlKey && (key === "c" || key === "C"))
                        _customToolbar.current._selectTool({ target: { value: "Circle" } })
                    if (key === "a" || key === "A")
                        _customToolbar.current._selectTool({ target: { value: "Arrow" } })
                    if ((key === "d" || key === "D") && window.lesson.isPanEnabled)
                        _customToolbar.current._selectTool({ target: { value: "Pan" } })
                }

                if ((key === "i" || key === "I") && props.isZoomEnabled) {
                    onNewZoom(zoomInFactor);
                }
                if ((key === "o" || key === "O") && props.isZoomEnabled) {
                    onNewZoom(zoomOutFactor);
                }
                if (key === "ArrowUp" && window.lesson.isPanEnabled) {
                    panUp(panFactorArrowShortcuts)
                }
                if (key === "ArrowRight" && window.lesson.isPanEnabled) {
                    panRight(panFactorArrowShortcuts)
                }
                if (key === "ArrowLeft" && window.lesson.isPanEnabled) {
                    panLeft(panFactorArrowShortcuts)
                }
                if (key === "ArrowDown" && window.lesson.isPanEnabled) {
                    panDown(panFactorArrowShortcuts)
                }
                if (key === "PageDown" && window.lesson.isPanEnabled) {
                    panDown(1)
                }
                if (key === "PageUp" && window.lesson.isPanEnabled) {
                    panUp(1)
                }
                if (keyDownEvent.ctrlKey && keyDownEvent.key === 'c') {
                    // console.log('Ctrl + C was pressed');
                    // Add your custom logic here
                    if (_sketch.current && _sketch.current._fc.current) {
                        let canvas = _sketch.current._fc.current
                        let activeObjects = canvas.getActiveObjects();
                        if (activeObjects.length === 1) {
                            // console.log(activeObjects)
                            for (let obj of activeObjects) {
                                //currently we dont delete any image on the canvas using the eraser tool
                                let newObj = obj.toObject(ToJSONFields)
                                newObj.left = obj.left + 10
                                newObj.top = obj.top + 10;
                                newObj.objectID = uuidv4();
                                newObj.clientID = undefined;
                                // newObj.set({
                                //     left: obj.left + 10,

                                // });
                                window.copiedObject = newObj
                                const newObjString = JSON.stringify(newObj);
                                // Copy the JSON string to the clipboard
                                navigator.clipboard.writeText(newObjString).then(() => {
                                    console.log('Object copied to clipboard', newObjString);
                                }).catch(err => {
                                    console.error('Could not copy object: ', err);
                                });

                            }
                        }

                    }
                }
            }
        }

        const handleMouseMove = (e) => {
            // console.log(e);

            e.pencil = false;
            e.mouseDown = mouseDown;
            if (_sketch.current._fc.current !== null && _sketch.current !== null) {
                e.width = _sketch.current._fc.current.getWidth();
                e.height = _sketch.current._fc.current.getHeight();
            }
            if (toolNameRef && toolNameRef.current) {
                e.toolName = toolNameRef.current;
            }
            if (currentTool.current === "Pencil") {
                e.pencil = true
                e.pencilWidth = lineWidthRef.current
                e.pencilColor = lineColorRef.current
                e.myPathUUID = myPathUUID.current

            }
            if (currentTool.current === "Highlighter") {
                e.pencil = true
                e.pencilWidth = highlighterWidthRef.current
                e.pencilColor = highlighterColorRef.current
                e.myPathUUID = myPathUUID.current


            }
            if (shouldSendDataToAllPeers) {
                props.sendDataToAllPeers(e);
            }
        }



        const handleMouseDown = (e) => {
            if (currentTool.current === "Pencil") {

                myPathUUID.current = uuidv4();

            }
            setMouseDown(true)


        };

        const handleMouseUp = (e) => {
            // console.log(e);
            setMouseDown(false)
        };





        const pasteListener = async (event) => {
            // console.log("trying to ", event.clipboardData.getData('Text'));
            if (!isSearchFocus.current && !props.isTextFocused.current) {
                // use event.originalEvent.clipboard for newer chrome versions
                if (event.clipboardData.getData('Text')) {
                    // console.log('not image')
                    const link = event.clipboardData.getData('Text')
                    // console.log(link)
                    // console.log(link.includes('scaleY'), link.includes('scaleX'))
                    if (link.includes('scaleX') && link.includes('scaleY')) {
                        let objTemp = JSON.parse(link)
                        _sketch.current.addObj(objTemp)
                    }
                    if (isValidUrl(link)) {
                        // console.log(link)
                        if (link.includes('youtube')) {
                            let id = getYoutubeLinkId(link)
                            let embeddedUrl = "//www.youtube.com/embed/" + id
                            _sketch.current.addIframeTag(embeddedUrl, false)
                        }
                        else if (link.includes('pdf')) {
                            _customToolbar.current.handleIsPdf(null, link)

                        }
                        else if (link.includes('ppt') && link.includes('pptx')) {
                            _customToolbar.current.handlePptxDocs(null, 'pptx', link)
                        }
                        else if (link.includes('doc') && link.includes('docx')) {
                            _customToolbar.current.handlePptxDocs(null, 'docx', link)
                        }
                        else if (link.includes('xls') && link.includes('xlsx')) {
                            _customToolbar.current.handlePptxDocs(null, 'xlsx', link)
                        }
                        else if (link.includes('ppt')) {
                            _customToolbar.current.handlePptxDocs(null, 'ppt', link)
                        }
                        else if (link.includes('doc')) {
                            _customToolbar.current.handlePptxDocs(null, 'doc', link)
                        }
                        else if (link.includes('xls')) {
                            _customToolbar.current.handlePptxDocs(null, 'xls', link)
                        }

                        else if (link.includes('png') || link.includes('jpg') || link.includes('jpeg')) {
                            _sketch.current.addImg(link, false)
                        }
                        else {
                            _sketch.current.addIframeTag(link, false)
                        }
                    }
                }
                else {
                    let items = (event.clipboardData || event.originalEvent.clipboardData).items;
                    // console.log(JSON.stringify(items)); // will give you the mime types
                    // find pasted image among pasted items
                    var blob = null;
                    for (var i = 0; i < items.length; i++) {
                        if (items[i].type.indexOf("image") === 0) {
                            blob = items[i].getAsFile();
                        }
                    }
                    // console.log("items: ", JSON.stringify(items));




                    if (blob !== null) {
                        // console.log({ blob });

                        await pasteImage(blob, _sketch.current.addImg, whiteBoardID, false)

                        // load image if there is a pasted image
                    }
                }


            }
        }

        if (!props.isWhiteboardPlayer) {
            if (!props.isViewer) {
                document.addEventListener("paste", pasteListener, true);
            }
            document.addEventListener("wheel", doScrollPan, { passive: false });


            document.addEventListener("wheel", debouncedDoScrollZoom, { passive: false });

            // document.addEventListener("mousedown", handleMouseDown);
            document.addEventListener("mousemove", handleMouseMove)
            // document.addEventListener("mouseup", handleMouseUp)

            const mediaSource = new MediaSource();
            const handleSourceOpen = (event) => {
                let sourceBuffer = mediaSource.addSourceBuffer('video/webm; codecs="vp8"');
                // console.log(sourceBuffer)
            }
            mediaSource.addEventListener('sourceopen', handleSourceOpen, false);
            document.addEventListener("keydown", keydownListener);
        }


        if (!props.isWhiteboardPlayer && !props.isViewer && props.isTeacher) {
            setInterval(async () => {
                uploadWhiteboardData()
            }, 300000);
        }





        /**
         * first all the events for the specific whiteboard are fetched from the database
         * the following function combines all the file added events.
         * It checks if any canvas cleared events are found and if found only events after the canvas cleared are processed
         * on obtaining all file added events they are sent to another function to enliven in async
         * Also if any canvas clear events are found the whiteboard from previous session is not loaded as canvas had been cleared in the
         * current session already

         */

        if (!props.isWhiteboardPlayer) {
            checkForFileAddedEventOrPreviousWhiteboard();
        }
        else {
            if (whiteboardPlayerRef.current) {
                whiteboardPlayerRef.current.setStartPlayerPopup(true)
            }
        }
        /**
         *
         * @param {boolean} hasPanEvent - to check if events array has pan event
         * @param {boolean} hasBackgroundEvent  - to check if events array has background event
         * this function checks if previous whiteboard id is available or not.
         * if prev whiteboard id is available then the previous whiteboard file is fetched by constructing the previous whiteboard url
         * On obtaining the previous whiteboard file, the link along with haspanevent and hasbackgroundevent is sent to the
         * uploadcanvastowhiteboard function
         */



        /**
         *
         * @param {array} allEvents - receives array of all events and processes them one by one in correct order
         * @returns - nothing
         */


        return () => {
            document.removeEventListener("wheel", doScrollPan, false);


            document.removeEventListener("wheel", debouncedDoScrollZoom, false);

            document.removeEventListener('mouseup', handleMouseUp, false);
            document.removeEventListener('mousedown', handleMouseDown, false);
            document.removeEventListener('mousemove', handleMouseMove, false);



            if (onCreateWhiteBoardEventSubscription) {
                onCreateWhiteBoardEventSubscription.unsubscribe();
            }
        }

    }, [])

    // useEffect(() => {
    //     if (props.isWhiteboardPlayer) {
    //         console.log(whiteboardPlayerRef)
    //         if (whiteboardPlayerRef.current) {
    //             whiteboardPlayerRef.current.setStartPlayerPopup(true)
    //         }
    //     }



    // }, [whiteboardPlayerRef, whiteboardPlayerRef.current])

    const playEventsInOrder = async (whiteBoardID) => {
        whiteboardPlayerRef.current.setIsPlayerLoading(true)
        const allEvents = await getAllEventsFromDB(whiteBoardID);
        console.log(allEvents)
        if (allEvents.length === 0) return;
        // console.log(window.lesson.session.start_time)
        const sessionStartTime = window.lesson.session.start_time
        console.log(sessionStartTime)
        let startTime = new Date(sessionStartTime).getTime();

        let audioRecordingBlobs;

        try {
            audioRecordingBlobs = await getAudioRecordingBlobsByRoomNameFromDB(whiteBoardID, clientID, props.accessToken);
        }
        catch (err) {
            console.log(err)
        }

        //var audioRecordingBlobs =
        // console.log("audioRecordingBlobs" + audioRecordingBlobs.length)
        let blobsGroupedByBlobIDInSequence = await getBlobsGroupedByBlobIDInSequence(audioRecordingBlobs);
        // let audioTimeIntervals = getAudioTimeIntervals(blobsGroupedByBlobIDInSequence);
        // let totalDuration = 0;
        // if (blobsGroupedByBlobIDInSequence.length > 0) {
        //     totalDuration = blobsGroupedByBlobIDInSequence[0].duration;
        // }
        for (let item of blobsGroupedByBlobIDInSequence) {
            let audioRecording = item
            const itemTimestamp = new Date(audioRecording.recordingStartTime)
            itemTimestamp.setHours(itemTimestamp.getHours());
            const itemTime = itemTimestamp.getTime();
            // Add 2 hours
            const delay = itemTime - startTime;
            console.log(itemTime, startTime, 'audio delay', delay)
            let newblob = new Blob(audioRecording.blobs, {
                type: 'audio/wav'
            });
            let url = window.URL.createObjectURL(newblob)
            let audioEl = document.createElement("audio");
            audioEl.src = url;
            console.log(audioEl)
            const callTimeoutFunc = (audioEl, currentTime, waitingTime) => (
                setTimeout(() => {
                    audioEl.currentTime = (currentTime / 1000);
                    audioEl.play();
                }, [waitingTime])
            )
            callTimeoutFunc(audioEl, 0, delay)
        }
        let hasPanEvent = false
        let hasBackgroundEvent = false;
        for (let i = 0; i < allEvents.length; ++i) {
            if (allEvents[i].eventType === "pan:changed") {
                hasPanEvent = true
            }
            if (allEvents[i].eventType === "backgroundColor:changed") {
                hasBackgroundEvent = true
            }
        }
        // console.log(hasPanEvent, hasBackgroundEvent)
        getCanvasFromPreviousWhiteboard(hasPanEvent, hasBackgroundEvent)
        // console.log(new Date(sessionStartTime))
        allEvents.forEach((event, index) => {
            const eventTimestamp = new Date(event.createdAt)
            eventTimestamp.setHours(eventTimestamp.getHours() + 2);
            const eventTime = eventTimestamp.getTime();
            // Add 2 hours
            // console.log(eventTimestamp)
            const delay = eventTime - startTime; // calculate time delay from the first event
            console.log(delay / 1000)
            console.log(event)
            setTimeout(() => {
                processReceivedEvent(event, false);
            }, delay);
        });
        whiteboardPlayerRef.current.setIsPlayerLoading(false)
        whiteboardPlayerRef.current.startPlayerTime()
    };

    async function getBlobsGroupedByBlobIDInSequence(blobs) {
        let blobSequence = [];
        let lastBlobBatchID = null;
        for (let blob of blobs) {
            if (blob.blobBatchID !== lastBlobBatchID) {
                let blobData = await fetch(blob.blobLink).then(r => r.blob());
                blobSequence.push({
                    recordingStartTime: blob.recordingStartTime,
                    duration: blob.duration,
                    blobBatchID: blob.blobBatchID,
                    blobs: [blobData]
                })
            }
            else {
                let blobData = await fetch(blob.blobLink).then(r => r.blob());
                blobSequence[blobSequence.length - 1].duration += blob.duration;
                blobSequence[blobSequence.length - 1].blobs.push(blobData);
            }
            lastBlobBatchID = blob.blobBatchID;
        }
        //console.log("blobsequence is",blobSequence)
        return blobSequence;
    }

    async function getAudioRecordingBlobsByRoomNameFromDB(roomName, clientId, accessToken) {

        let blobsArray = []
        let result = [];
        // console.log("room name " + roomName)
        let status, res;

        try {
            res = await getWhiteboardAudioBlob(accessToken, roomName);;
            status = res.status
        }

        catch (err) {
            console.log(err);
            status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            // console.log(res)
            result = res.data.whiteBoardAudioBlobs
            for (let index = 0; index < result.length; index++) {
                blobsArray.push({
                    id: result[index].id,
                    roomName: result[index].whiteboard_id,
                    blobLink: result[index].recording_file_link,
                    duration: result[index].duration,
                    blobBatchNumber: result[index].blob_batch_number,
                    blobBatchID: result[index].blob_batch_id,
                    clientID: result[index].client_id,
                    recordingStartTime: result[index].recording_start_time,
                    createdAt: result[index].created_at

                })

            }
        }
        else {
            console.log(status)
            // if (status === 400)
            //    alert('No Uploads Yet')
        }



        const blobArrayWithSameClientID = blobsArray.filter((obj) => {
            return obj.clientID === clientId
        })
        console.log("blobsArray is from db", blobArrayWithSameClientID);
        return blobArrayWithSameClientID;

    }

    const getCanvasFromPreviousWhiteboard = async (hasPanEvent, hasBackgroundEvent) => {
        // console.log('heyy', props.slateID)
        let time1 = moment().toISOString();
        // console.log('previous whiteboard file link generation load call started at:', time1)
        if (!props.isWhiteboardPlayer) {
            setIsPreviousWhiteboardFileLoading(true)
        }

        let previousWhiteboard;
        try {
            previousWhiteboard = await getPreviousWhiteboard(props.slateID, props.accessToken, whiteBoardID)
        }
        catch (err) {
            setIsPreviousWhiteboardFileLoading(false)
            setDownloadingProgress(0)
        }
        // console.log('heyyeyeye', previousWhiteboard)
        let previousWhiteboardID = null;
        if (previousWhiteboard) {
            previousWhiteboardID = previousWhiteboard.id
        }        // console.log('heyy', previousWhiteboardID)
        if (previousWhiteboardID) {
            const fileNameTemp = await getWhiteboardFileName(props.accessToken, previousWhiteboardID)
            let time2 = moment().toISOString();
            let diffInSeconds = moment(time2).diff(time1, 'seconds');
            // console.log('previous whiteboard file link generation load call completed at:', time2)
            // console.log('previous whiteboard file link generation load call timetaken:', diffInSeconds + ' seconds')
            // console.log('previous whiteboard link', previousWhiteboardFileLink)
            if (!fileNameTemp) {
                // console.log('No file found')
                setIsPreviousWhiteboardFileLoading(false)
                setDownloadingProgress(0)
            }
            else {
                let previousWhiteboardFileLink = UPLOAD_API_URL + 'uploads/whiteBoardStorage/' + previousWhiteboardID + '/JSON/' + fileNameTemp;
                await uploadCanvasToWhiteboard(previousWhiteboardFileLink, hasPanEvent, hasBackgroundEvent, true)
            }

        }
        else {
            setIsPreviousWhiteboardFileLoading(false)
            setDownloadingProgress(0)
        }

    }

    const setAllEvents = async (allEvents) => {
        // console.log("events from aws " + JSON.stringify(allEvents))
        //the whiteboard player does not load events if this condition is not there

        if (!isPlayer) {
            handleChangeLoading(false)
        }
        handleChangeLoading(true)
        // console.log(allEvents)
        if (allEvents.length === 0) {
            handleChangeLoading(false)
            return;
        }
        // allEvents = allEventsFromDB
        // handleChangeLoading(true)
        let firstEvent = JSON.parse(allEvents[0].eventData)
        let lastEvent = JSON.parse(allEvents[allEvents.length - 1].eventData);
        let lastUpdatedEvents = getLastUpdatedEventsInAscending(allEvents, firstEvent.timestamp, lastEvent.timestamp)
        let eventsIndex = lastUpdatedEvents.length - 1;

        while (eventsIndex >= 0) {
            if (lastUpdatedEvents[eventsIndex].eventType === "canvas:objects:cleared") {
                break;
            }
            eventsIndex--;
        }
        eventsIndex = Math.max(0, eventsIndex)

        let objectsArray = []
        let lastZoomEvent, lastPanEvent, lastNewZoomEvent;
        const setEventsUsingPromise = async (whiteBoardEvent) => {
            await new Promise((resolveP, rejectP) => {
                // var eventData = JSON.parse(whiteBoardEvent.eventData);

                if (whiteBoardEvent.eventType === 'zoomAndPan:changed') {
                    lastZoomEvent = whiteBoardEvent;
                    processReceivedEvent(whiteBoardEvent, true);
                    resolveP();
                }
                else if (whiteBoardEvent.eventType === 'pan:changed') {
                    // console.log('event pan', lastPanEvent)
                    lastPanEvent = whiteBoardEvent;
                    resolveP();
                }
                else if (whiteBoardEvent.eventType === 'newZoom:changed') {
                    // console.log('event pan', lastPanEvent)
                    lastNewZoomEvent = whiteBoardEvent;
                    resolveP();
                }
                else if (whiteBoardEvent.eventType === "object:added") {
                    let obj = JSON.parse(whiteBoardEvent.eventData)
                    objectsArray.push(obj)
                    resolveP();
                }
                else if (whiteBoardEvent.eventType === "object:modified") {
                    let obj = JSON.parse(whiteBoardEvent.eventData)
                    // console.log('object modified', obj)
                    databaseModifiedOrRemovedObjects.current = [...databaseModifiedOrRemovedObjects.current, obj]
                    objectsArray.filter((data) => data.objectID !== obj.objectID);
                    objectsArray.push(obj)
                    resolveP();
                }
                else if (whiteBoardEvent.eventType === "object:removed") {
                    let obj = JSON.parse(whiteBoardEvent.eventData)
                    // console.log('object deleted', obj)
                    databaseModifiedOrRemovedObjects.current = [...databaseModifiedOrRemovedObjects.current, obj]
                    objectsArray.filter((data) => data.objectID !== obj.objectID);
                    resolveP();

                }
                else {
                    processReceivedEvent(whiteBoardEvent, true);
                    resolveP();
                }
            })
        }
        for (let i = eventsIndex; i < lastUpdatedEvents.length; i++) {
            let whiteBoardEvent = lastUpdatedEvents[i];
            await setEventsUsingPromise(whiteBoardEvent)
        }
        let canvas = _sketch.current._fc.current
        if (objectsArray.length > 0) {
            if (objectsArray[0].createdAt) {
                objectsArray.sort(function (a, b) {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });
            }
            callEnlivenFunctionInSequence(canvas, objectsArray)
        }


        let lastPanTime, lastZoomTime;
        if (lastZoomEvent) {
            lastZoomTime = new Date((JSON.parse(lastZoomEvent.eventData)).timestamp);
        }
        if (lastPanEvent) {
            lastPanTime = new Date((JSON.parse(lastPanEvent.eventData)).timestamp);
            if (lastZoomTime) {
                if (lastPanTime >= lastZoomTime) {
                    processReceivedEvent(lastPanEvent, true);
                }
            }
            else {
                processReceivedEvent(lastPanEvent, true);
            }

        }
        //last new zoom event has to be after pan event because it contains zoom level and it resets it
        if (lastNewZoomEvent) {
            processReceivedEvent(lastNewZoomEvent, true);
        }
        // console.log('events loaded')
        handleChangeLoading(false)
    }

    const checkForFileAddedEventOrPreviousWhiteboard = async () => {
        const time1 = moment().toISOString();
        // console.log('db event load call started at:', time1)

        const allEventsTemp = await getAllEventsFromDB(whiteBoardID);
        const time2 = moment().toISOString();
        const diffInSeconds = moment(time2).diff(time1, 'seconds');
        // console.log('db event load call completed at:', time2)
        // console.log('db event load call timetaken:', diffInSeconds + ' seconds')
        // console.log('db events load call events loaded', allEventsTemp)
        recordSessionEvents(props.accessToken, props.whiteBoardID, props.clientID, WHITEBOARD_EVENTS_LOADED_EVENT_NAME, null)
        // let hasFileAddedEvent = false;
        // let fileAddedIndex = -1;
        let hasCanvasCleared = false;
        let canvasClearedIndex = -1;
        let listOfFileAddedEvents = [];
        let allEvents = [];
        for (let i = 0; i < allEventsTemp.length; ++i) {
            if (allEventsTemp[i].eventType === 'file:added') {
                listOfFileAddedEvents.push(allEventsTemp[i])
            }
            else {
                allEvents.push(allEventsTemp[i])
            }
            if (allEventsTemp[i].eventType === "canvas:objects:cleared") {
                listOfFileAddedEvents = []
            }
        }
        let hasPanEvent = false
        let hasBackgroundEvent = false;
        for (let i = 0; i < allEvents.length; ++i) {
            if (allEvents[i].eventType === "canvas:objects:cleared") {
                hasCanvasCleared = true;
                canvasClearedIndex = i
            }
            if (allEvents[i].eventType === "pan:changed") {
                hasPanEvent = true
            }
            if (allEvents[i].eventType === "backgroundColor:changed") {
                hasBackgroundEvent = true
            }
        }
        // console.log('compare', allEvents.length, allEventsTemp.length)
        if (hasCanvasCleared) {
            setAllEvents(allEvents.slice(canvasClearedIndex + 1))
        }
        else {
            getCanvasFromPreviousWhiteboard(hasPanEvent, hasBackgroundEvent);
            setAllEvents(allEvents)
        }
        // console.log('hey', listOfFileAddedEvents)
        // console.log(JSON.parse(allEvents[allEvents.length - 1].eventData))
        handleFileAddedEvents(listOfFileAddedEvents);
    }
    // useEffect(() => {
    //     setWhiteBoardID(props.whiteBoardID)
    // }, [props.whiteBoardID])

    // useEffect(() => {
    //     setClientID(props.clientID)
    // }, [props.clientID])

    useEffect(() => {
        if (props.playWhiteBoard) {
            const canvas = _sketch.current._fc.current;
            canvas.setViewportTransform([1, 0, 0, 1, 0, 0])
            canvas.clear()
            playAllEvents();
        }


        return () => {
            for (let timeout of timeouts) {
                clearTimeout(timeout);
            }
        }
    }, [props.playWhiteBoard])

    useEffect(() => {
        for (let timeout of timeouts) {
            clearTimeout(timeout);
        }
        if (props.playWhiteBoard) {
            // console.log("playWhiteBoard:calling playEvents")
            const canvas = _sketch.current._fc.current;
            canvas.setViewportTransform([1, 0, 0, 1, 0, 0])
            canvas.clear()
            playAllEvents();
        }

    }, [props.startTimeOffset])





    async function getCanvasImageObjects() {
        // const canvas = _sketch.current._fc.current
        const { handleChangeIsWhiteBoardLoaded = () => { } } = props;
        var allEvents = await getAllEventsFromDB(whiteBoardID);

        var imageObjects = []
        for (let whiteBoardEvent of allEvents) {
            let eventData = JSON.parse(whiteBoardEvent.eventData);
            if (eventData.type === "image") {
                await new Promise(async (resolveP, rejectP) => {

                    const oImg = await fabric.FabricImage.fromURL(eventData.src, { crossOrigin: 'anonymous' });
                    //console.log("promise",eventData.left,eventData.top)
                    var scaledJson = scaleJSON(eventData);
                    oImg.set({
                        ...scaledJson,
                        initialState: true,
                    })
                    imageObjects.push(oImg)
                    //canvas.add(oImg);
                    resolveP();

                });
            }
        }
        handleChangeIsWhiteBoardLoaded(true);
        return imageObjects;
    }

    function playAllEvents() {
        let waitingTimes = props.whiteBoardEventsTimeout;
        let startTimeOffset = props.startTimeOffset;
        if (isNaN(startTimeOffset))
            startTimeOffset = 0;
        // console.log("startTimeOffset is", startTimeOffset)
        // console.log("playWhiteBoard:playing allevents", waitingTimes, allEvents);
        const canvas = _sketch.current._fc.current;
        let timeoutsT = [];
        let allEvents = allEvents;
        let imageObjects = canvasImageObjects;
        let imageObjIndex = 0;
        for (let i = 0; i < waitingTimes.length; i++) {
            let eventData = JSON.parse(allEvents[i].eventData);
            if (eventData.type === "image") {
                const callTimeoutFunc = (obj, whiteBoardEvent) => (
                    setTimeout(() => {
                        let newImageObject = fabric.util.object.clone(obj);
                        if (whiteBoardEvent.eventType === "object:added")
                            canvas.add(newImageObject);
                        else if (whiteBoardEvent.eventType === "object:modified")
                            _modify(newImageObject)
                        else if (whiteBoardEvent.eventType === "object:removed")
                            _remove(newImageObject)
                        //canvas.add(newImageObject);
                    },
                        Math.max(waitingTimes[i] - startTimeOffset, 0))
                )
                timeoutsT.push(callTimeoutFunc(imageObjects[imageObjIndex], allEvents[i]))
                imageObjIndex++;
            }
            else {
                const callTimeoutFunc = (obj) => (
                    setTimeout(() => { processReceivedEvent(obj, true); },
                        Math.max(waitingTimes[i] - startTimeOffset, 0))
                )
                timeoutsT.push(callTimeoutFunc(allEvents[i]))
            }
        }
        // console.log("all events played");
        //console.log("playWhiteBoard:timeouts",timeouts);
        setTimeouts(timeoutsT)
        //handleChangeLoading(false)
    }


    /**
     *
     * @param {object} object receives object which needs to be scaled
     * @returns {object} -returns scaled object according to the current height and width of the canvas
     */
    function scaleJSON(object) {
        // console.log(object)
        let { offsetWidth = 1, clientHeight = 1 } = _sketch.current._container.current;
        let { containerWidth = 1, containerHeight = 1 } = object;
        // console.log(offsetWidth, clientHeight)
        // console.log(containerWidth, containerHeight)
        let wfactor = (offsetWidth / containerWidth);
        let hfactor = (clientHeight / containerHeight);
        let scaleX = object.scaleX;
        let scaleY = object.scaleY;
        let left = object.left;
        let top = object.top;
        // let strokeWidth = object.strokeWidth;
        let tempScaleX = scaleX * wfactor;
        let tempScaleY = scaleY * hfactor;
        let tempLeft = left * wfactor;
        let tempTop = top * hfactor;
        object.scaleX = tempScaleX;
        object.scaleY = tempScaleY;
        // var tmpAvg = (hfactor + wfactor)
        // object.strokeWidth = (strokeWidth / ((tempScaleX + tempScaleY) / 2));
        if (object.type === "circle") {
            object.scaleX = Math.min(tempScaleY, tempScaleX);
            object.scaleY = Math.min(tempScaleY, tempScaleX);
        }
        object.left = tempLeft;
        object.top = tempTop;
        object.containerHeight = clientHeight;
        object.containerWidth = offsetWidth;
        //console.log("factor",hfactor,wfactor);
        //console.log("scale x,y",scaleX,scaleY);
        //console.log("scaled object",object);
        return object;
    }


    const doScrollZoom = (e) => {
        // vertical offset      e.
        if (props.isZoomEnabled) {
            if (e.ctrlKey) {
                if (e.deltaY < 0) {
                    onNewZoom(zoomInFactor)
                }
                if (e.deltaY > 0) {
                    onNewZoom(zoomOutFactor)
                }
                // console.log(` y:${e.deltaY}`);
            }

            e.preventDefault();// disable the actual scrolling
        }
    }
    const debouncedDoScrollZoom = debounce(doScrollZoom, 15);

    const doScrollPan = (e) => {
        // positive deltas are top and left
        // down and right are negative

        // horizontal offset    e.deltaX
        // vertical offset      e.deltaY
        if (window.lesson.isPanEnabled) {
            if (!(window.whiteboardScrollDisable)) {
                if (!e.ctrlKey) {
                    panScroll(-e.deltaX, -e.deltaY)
                }
                e.preventDefault(); // disable the actual scrolling
            }
        }

    }
    //firepanevent function is debounced and called when pan position needs to be send to the other users with some delay in order to reduce pan events
    const firePanEvent = () => {
        const canvas = _sketch.current._fc.current;
        let { offsetWidth = 0, clientHeight = 0 } = _sketch.current._container.current;
        var currentPositionObj = {
            clientID: props.clientID,
            eventType: "pan:changed",
            viewportTransform: canvas.viewportTransform,
            containerWidth: offsetWidth,
            containerHeight: clientHeight,
        }
        canvas.fire("pan:changed", currentPositionObj)
        // console.log('pan changed')
        identifyCurrentPage()
    }
    const debouncedFirePanEvent =
        debounce(firePanEvent, 200);

    const panScroll = (x, y) => {
        const canvas = _sketch.current._fc.current;
        canvas.relativePan({
            x: x,
            y: y,
        });
        canvas.renderAll();
        _sketch.current.setActionBox()

        debouncedFirePanEvent();
        calculateNumberOfPages()
    }

    /**
*
* @param {number} panFactor - receives a pan factor and pans the canvas downwards by using that pan factor
*/
    const panDown = (panFactor) => {
        const canvas = _sketch.current._fc.current;
        var viewportTransform = canvas.viewportTransform.slice();
        let { offsetWidth = 10, clientHeight = 10 } = _sketch.current._container.current;
        viewportTransform[5] -= (panFactor * clientHeight);
        // let top = document.getElementById('bookingLayer').style.top
        // document.getElementById('bookingLayer').style.top = parseInt(top.slice(0, top.length - 2)) - (panFactor * clientHeight) + 'px'
        _changePan({
            clientID: clientID,
            viewportTransform: viewportTransform,
            containerHeight: clientHeight,
            containerWidth: offsetWidth,
        })
    }

    /**
     * returns -nothing
     * pans to the position of the object which has the maximum height of the canvas
     * this function is called when a nnew pdf is added and as the pdf is added after the object with max height the viewport is
     * shifted to that position by using this function before pasting pdf files
     */
    const panToNewPdf = async () => {
        const canvas = _sketch.current._fc.current;
        var viewportTransform = canvas.viewportTransform.slice();
        let { offsetWidth = 10, clientHeight = 10 } = _sketch.current._container.current;
        let res = await _sketch.current.calculateMaxHeight();
        let top = res.totalHeight;
        let left = res.currentLeft;
        //zoomfactor used to multiply to total height to get correct viewport
        viewportTransform[5] = (-top * viewportTransform[0]) + 200;
        viewportTransform[4] = (-left * viewportTransform[0]) + 200;
        // console.log('hey', viewportTransform)
        _changePan({
            clientID: clientID,
            viewportTransform: viewportTransform,
            containerHeight: clientHeight,
            containerWidth: offsetWidth,
        })
    }

    const panToLastPage = async () => {
        const canvas = _sketch.current._fc.current;
        var viewportTransform = canvas.viewportTransform.slice();
        let { offsetWidth = 10, clientHeight = 10 } = _sketch.current._container.current;
        let res = await _sketch.current.calculateMaxHeight();
        let top = res.totalHeight;
        let left = res.currentLeft;
        //zoomfactor used to multiply to total height to get correct viewport
        viewportTransform[5] = (-top * viewportTransform[0]) + res.lastObjectHeight * viewportTransform[0] + 100;
        viewportTransform[4] = (-left * viewportTransform[0]) + 100;
        // console.log('hey', viewportTransform)
        _changePan({
            clientID: clientID,
            viewportTransform: viewportTransform,
            containerHeight: clientHeight,
            containerWidth: offsetWidth,
        })
    }

    /**
  *
  * @param {number} panFactor - receives a pan factor and pans the canvas upwards by using that pan factor
  */
    const panUp = (panFactor) => {
        const canvas = _sketch.current._fc.current;
        var viewportTransform = canvas.viewportTransform.slice();
        let { offsetWidth = 10, clientHeight = 10 } = _sketch.current._container.current;
        viewportTransform[5] += (panFactor * clientHeight);
        // let top = document.getElementById('bookingLayer').style.top
        // document.getElementById('bookingLayer').style.top = parseInt(top.slice(0, top.length - 2)) + (panFactor * clientHeight) + 'px'
        // console.log('current y is', viewportTransform)
        _changePan({
            clientID: clientID,
            viewportTransform: viewportTransform,
            containerHeight: clientHeight,
            containerWidth: offsetWidth,
        })
    }

    /**
  *
  * @param {number} panFactor - receives a pan factor and pans the canvas to the left by using that pan factor
  */
    const panLeft = (panFactor) => {
        const canvas = _sketch.current._fc.current;
        var viewportTransform = canvas.viewportTransform.slice();
        let { offsetWidth = 10, clientHeight = 10 } = _sketch.current._container.current;
        viewportTransform[4] += (panFactor * offsetWidth);
        // console.log('current x is', viewportTransform[4])
        // let left = document.getElementById('bookingLayer').style.left
        // document.getElementById('bookingLayer').style.left = parseInt(left.slice(0, left.length - 2)) + (panFactor * offsetWidth) + 'px'
        _changePan({
            clientID: clientID,
            viewportTransform: viewportTransform,
            containerHeight: clientHeight,
            containerWidth: offsetWidth,
        })
    }

    /**
     *
     * @param {number} panFactor - receives a pan factor and pans the canvas to the right by using that pan factor
     */
    const panRight = (panFactor) => {
        const canvas = _sketch.current._fc.current;
        var viewportTransform = canvas.viewportTransform.slice();
        let { offsetWidth = 10, clientHeight = 10 } = _sketch.current._container.current;
        viewportTransform[4] -= (panFactor * offsetWidth);
        // let left = document.getElementById('bookingLayer').style.left
        // document.getElementById('bookingLayer').style.left = parseInt(left.slice(0, left.length - 2)) - (panFactor * offsetWidth) + 'px'
        _changePan({
            clientID: clientID,
            viewportTransform: viewportTransform,
            containerHeight: clientHeight,
            containerWidth: offsetWidth,
        })
    }

    /**
     *
     * @param {number} pageNumber - uses the page number to pan to a specific position
     */
    const panByPageNumber = (pageNumber) => {
        const canvas = _sketch.current._fc.current;
        var viewportTransform = canvas.viewportTransform.slice();;
        let { offsetWidth, clientHeight } = _sketch.current._container.current;

        viewportTransform[5] = 0 - ((pageNumber - 1) * clientHeight);

        // console.log('current y is', viewportTransform)
        _changePan({
            clientID: clientID,
            viewportTransform: viewportTransform,
            containerHeight: clientHeight,
            containerWidth: offsetWidth,
        })
    }

    /**
     * returns -nothing
     * identifies the current page number after every pan
     */
    const identifyCurrentPage = async () => {
        const canvas = _sketch.current._fc.current;
        let viewportTransform = canvas.viewportTransform;
        const screenHeight = canvas.getHeight();
        let tempCurrentPage;
        // const zoomLevel = viewportTransform[0]
        if (viewportTransform[5] <= 0) {
            tempCurrentPage = Math.ceil(((Math.abs(viewportTransform[5])) / screenHeight) + 1)
        }
        else {
            tempCurrentPage = Math.ceil(((-(viewportTransform[5])) / screenHeight) + 1)
            if (tempCurrentPage <= 0) {
                --tempCurrentPage
            }
        }

        // console.log('layla',tempCurrentPage)
        handlePageNumber(tempCurrentPage)
        // if (tempCurrentPage > totalNumberOfPages) {
        //     handleTotalNumberOfPages(tempCurrentPage)
        // }
    }

    const handlePageNumber = (value) => {
        if (_customToolbar && _customToolbar.current) {
            _customToolbar.current.setPageNumber(value)
            pageNumberRef.current = value;
        }
    }

    const handleTotalNumberOfPages = (value) => {
        if (_customToolbar && _customToolbar.current) {
            _customToolbar.current.setTotalNumberOfPages(value)
        }

    }

    /**
     *
     * @returns -nothing
     * sets total number of pages state by calculating the max height of the canvas and dividing it by clientheight to get number of pages
     */
    const calculateNumberOfPages = async () => {
        const canvas = _sketch.current._fc.current;
        const screenHeight = canvas.getHeight();
        const screenWidth = canvas.getWidth();

        var viewportTransform = canvas.viewportTransform
        // console.log("current viewportTrasform", viewportTransform);
        var currentTop = viewportTransform[5]
        let totalHeightObj = await _sketch.current.calculateMaxHeight();
        let totalHeight = totalHeightObj.totalHeight;

        const zoomLevel = viewportTransform[0]
        // console.log(totalHeight, 'total height', zoomLevel)
        // console.log('current', currentTop)
        let noOfPages = Math.ceil((Math.abs(totalHeight) * zoomLevel) / screenHeight)
        // console.log(noOfPages)
        if (noOfPages === 0) {
            noOfPages = 1
        }
        if (noOfPages < pageNumberRef.current) {
            noOfPages = pageNumberRef.current
        }
        handleTotalNumberOfPages(noOfPages)
    }


    const startRecording = async () => {
        var stream = await navigator.mediaDevices.getDisplayMedia({
            video: {
                mediaSource: 'screen',
            },
            audio: true,
        });
        let stream2
        try {
            stream2 = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
        }
        catch (err) {
            console.log(err)
            alert('Recording could not start as audio permissions were denied')
        }



        if (stream && stream2) {
            var OutgoingAudioMediaStream = new MediaStream();
            OutgoingAudioMediaStream.addTrack(stream.getAudioTracks()[0]);

            var IncomingAudioMediaStream = new MediaStream();
            IncomingAudioMediaStream.addTrack(stream2.getAudioTracks()[0]);

            const audioContext = new AudioContext();

            let audioIn_01 = audioContext.createMediaStreamSource(OutgoingAudioMediaStream);
            let audioIn_02 = audioContext.createMediaStreamSource(IncomingAudioMediaStream);

            let dest = audioContext.createMediaStreamDestination();

            audioIn_01.connect(dest);
            audioIn_02.connect(dest);

            var FinalStream = dest.stream;

            let combine = new MediaStream(
                [stream.getVideoTracks()[0], FinalStream.getAudioTracks()[0]])

            let options = { mimeType: 'video/webm' };

            recordedBlobs = [];

            try {
                mediaRecorder = new MediaRecorder(combine, options);
                // console.log('media recorder')
            } catch (e0) {
                // console.log('Unable to create MediaRecorder with options    Object: ', e0);
                try {
                    options = { mimeType: 'video/webm,codecs=vp9' };
                    mediaRecorder = new MediaRecorder(combine, options);
                } catch (e1) {
                    // console.log('Unable to create MediaRecorder');
                    try {
                        options = 'video/vp8'; // Chrome 47
                        mediaRecorder = new MediaRecorder(combine, options);
                    } catch (e2) {
                        alert('MediaRecorder is not supported by this browser');
                        return;
                    }
                }
            }
            mediaRecorder.onstop = (event) => {
                allClips.push(recordedBlobs)
                var superBuffer = new Blob(recordedBlobs, { type: 'video/webm' });
                var video = document.createElement('canvas_video');
                video.src = window.URL.createObjectURL(superBuffer);
                var reader = new FileReader();
                reader.readAsDataURL(superBuffer);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    video.setAttribute('data-base64', base64data)
                }
            };
            // console.log('attaching events')
            mediaRecorder.ondataavailable = (event) => {
                // console.log('ondataavailable')
                if (event.data && event.data.size > 0) {
                    // console.log('pushing data')
                    recordedBlobs.push(event.data);
                }
            };
            // console.log('starting media recording')
            mediaRecorder.start(100); // collect 100ms of data
        }
    }

    const downloadVideoStream = (file_name) => {
        for (let i = 0; i < allClips.length; ++i) {
            const name = file_name || 'recording_' + i + '.webm';
            const blob = new Blob(allClips[i], { type: 'video/webm' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 100);
        }
    }
    const handleStop = (event) => {
        var superBuffer = new Blob(recordedBlobs, { type: 'video/webm' });
        var video = document.createElement('canvas_video');
        video.src = window.URL.createObjectURL(superBuffer);
        var reader = new FileReader();
        reader.readAsDataURL(superBuffer);
        reader.onloadend = function () {
            var base64data = reader.result;
            video.setAttribute('data-base64', base64data)
        }
    }

    const handleDataAvailable = (event) => {
        if (event.data && event.data.size > 0) {
            // console.log('pushing data')
            recordedBlobs.push(event.data);
        }
    }











    function getBrush(previousPeerData, xyCoord) {
        //get a brush if it is already there
        if (previousPeerData && previousPeerData[4]) {
            //      console.log("returning existing brush");
            return previousPeerData[4]
        }
        if (previousPeerData && xyCoord[3] === "true" && previousPeerData[3] === "false" && xyCoord[2] === "true") {
            // console.log("creating new brush");
            var brush = new PeerBrush(_sketch.current._fc.current, xyCoord[6]);
            //console.log(JSON.stringify(brush))
            previousPeerData[4] = brush;
            var w = _sketch.current._fc.current.getWidth();
            var h = _sketch.current._fc.current.getHeight();
            //  console.log("brush width" + parseInt(xyCoord[4]))
            brush.width = parseInt(xyCoord[4])

            brush.color = xyCoord[5]
            //     console.log("_sketch.current._fc.current.viewportTransform" + _sketch.current._fc.current.viewportTransform);
            var mInverse = fabric.util.invertTransform(_sketch.current._fc.current.viewportTransform);
            var oldP = new fabric.Point(xyCoord[0] * w, xyCoord[1] * h)
            //console.log("old x " + xyCoord[0]*w + "old y" + xyCoord[1]*h )
            var newStartP = fabric.util.transformPoint(oldP, mInverse);
            //console.log("new x " + newStartP.x + "new y" + newStartP.y )

            brush.onMouseDown({ x: newStartP.x, y: newStartP.y }, { e: {} })

            return brush;

        }
    }


    function initialisePathObjectUUID() {
        myPathUUID = uuidv4();

    }

    function receivedDataFromPeer(peerSocketId, xyCoord) {
        var w = _sketch.current._fc.current.getWidth();
        var h = _sketch.current._fc.current.getHeight();
        //  console.log("message from peerid" + xyCoord);
        if (!peerRefs.current) {
            // console.log("initialising peer refs")
            peerRefs.current = [];
        }
        if (!peerRefs.current[peerSocketId]) {
            peerRefs.current[peerSocketId] = []
        }
        var previousPeerData = peerRefs.current[peerSocketId]

        if (previousPeerData && xyCoord[3] === "false" && previousPeerData[3] === "true" && xyCoord[2] === "true") {
            setTimeout(() => {
                _sketch.current.removePeerObjectsBySocketId(peerSocketId)
            }, 500);
            //    console.log("previous brush " + JSON.stringify(previousPeerData[4]))
            if (previousPeerData[4]) {
                previousPeerData[4].onMouseUp({ e: {} });
            }
            previousPeerData[4] = null

            //  console.log("received mouse up");
        }





        //    console.log("x co " + Math.ceil(xyCoord[0]*w))
        //    console.log("y co " + Math.ceil(xyCoord[1]*h))
        if (xyCoord[3] === "true" && xyCoord[2] === "true") {

            // draw only if the pencil is selected and mouse down
            var brush = getBrush(previousPeerData, xyCoord);
            if (brush) {
                brush.width = parseInt(xyCoord[4])
                brush.color = xyCoord[5]
            }
            //    console.log("calling create path");

            var mInverse = fabric.util.invertTransform(_sketch.current._fc.current.viewportTransform);
            var oldP = new fabric.Point(xyCoord[0] * w, xyCoord[1] * h)
            //       console.log("old x " + xyCoord[0]*w + "old y" + xyCoord[1]*h )
            var newStartP = fabric.util.transformPoint(oldP, mInverse);
            //      console.log("new x " + newStartP.x + "new y" + newStartP.y )

            if (brush) {
                _sketch.current.createPath(previousPeerData[0] * w, previousPeerData[1] * h, newStartP.x, newStartP.y, "black", 2.1, peerSocketId, brush);
            }


            //  console.log("after create path");
        }
        //   console.log("setting previous data to new now" +xyCoord[0]);
        previousPeerData[0] = xyCoord[0];
        previousPeerData[1] = xyCoord[1];
        previousPeerData[2] = xyCoord[2];
        previousPeerData[3] = xyCoord[3];
    }



    const handleText = (val) => {
        // console.log('we are here ', val)
        setText(val)
    }

    const handleBackgroundColor = (val) => {
        if (_customToolbar && _customToolbar.current) {
            _customToolbar.current.setBackgroundColor(val)
        }
    }



    const handleChangeLoading = (val) => {
        setIsLoading(val)
    }

    const checkIfObjectAlreadyExists = (objectArray, object_id) => {
        for (let i = 0; i < objectArray.length; ++i) {
            if (objectArray[i].objectID === object_id) {
                return true
            }
        }
        return false
    }
    /**
     *
     * @param {*} fileUrl - Link to the json file onm the backend server
     * @param {boolean} hasPanEvent - to check if events array has pan event
     * @param {boolean} hasBackgroundEvent  - to check if events array has background event
     * sends previous whiteboard json to canvasloadfromjson and adds loading source property to all objects so that event is not passed
     */
    const uploadCanvasToWhiteboard = async (fileUrl, hasPanEvent, hasBackgroundEvent, isPreviousWhiteboardFile) => {
        let canvas = _sketch.current._fc.current;
        try {
            // console.log('yo')
            // console.log(fileUrl)
            let flag = false;
            let resWithLoadingSource;
            let tempCanvas
            try {
                let time1 = moment().toISOString();
                console.log('file fetch load call started at:', time1)

                const response = await axios.get(fileUrl, {
                    responseType: 'text',
                    onDownloadProgress: (progressEvent) => {
                        const total = progressEvent.total;
                        const loaded = progressEvent.loaded;
                        // console.log(loaded, total)
                        if (total !== undefined) {
                            const percentCompleted = Math.floor((loaded / total) * 100);
                            console.log(`Progress: ${percentCompleted}%`);
                            if (percentCompleted < 96) {
                                setDownloadingProgress(percentCompleted)
                            }
                            // setProgress(percentCompleted);
                        } else {
                            console.log(`Loaded: ${loaded} bytes (Total size not available)`);
                        }

                        // setProgress(percentCompleted);
                    }
                });

                const data = response.data;
                const t = JSON.parse(data);
                let time2 = moment().toISOString();
                let diffInSeconds = moment(time2).diff(time1, 'seconds');
                // console.log('file load call completed at:', time2)
                // console.log('file load call timetaken:', diffInSeconds + ' seconds')
                recordSessionEvents(props.accessToken, props.whiteBoardID, props.clientID, PREVIOUS_WHITEBOARD_FILE_LOADED_EVENT_NAME, null)
                // console.log(r)


                // console.log(t)
                tempCanvas = t
                // console.log(tempCanvas)
                //for testing if canvas file has containerHeight which in turns mean the file has beeen coded in new way and can be loaded
                for (let i = 0; i < (tempCanvas.objects).length; ++i) {
                    tempCanvas.objects[i].loadingSource = 'file'
                    if (tempCanvas.objects[i].containerHeight) {
                        flag = true
                    }
                }
                // console.log('hey', tempCanvas)
                resWithLoadingSource = JSON.stringify(tempCanvas)
                console.log('file loaded')
            }
            catch (err) {
                console.log("could not load the file")
                console.log(err)
            }
            // console.log('flag value is ', flag)
            if (flag) {
                const objectsTemp = (JSON.parse(resWithLoadingSource)).objects
                const lastViewport = (JSON.parse(resWithLoadingSource)).lastViewport
                let objects = []
                for (let i = 0; i < objectsTemp.length; ++i) {
                    if (!(checkIfObjectAlreadyExists(databaseModifiedOrRemovedObjects.current, objectsTemp[i].objectID))) {
                        objects.push(objectsTemp[i])
                    }
                }
                let background = '';
                if (!hasBackgroundEvent) {
                    if ((JSON.parse(resWithLoadingSource)).background) {
                        background = (JSON.parse(resWithLoadingSource)).background
                    }
                }
                // console.log(objects)
                if (objects[0].createdAt) {
                    objects.sort(function (a, b) {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    if (!hasPanEvent) {
                        let { offsetWidth = 10, clientHeight = 10 } = _sketch.current._container.current;
                        if (lastViewport) {
                            _changePan({
                                clientID: clientID,
                                viewportTransform: lastViewport,
                                containerHeight: clientHeight,
                                containerWidth: offsetWidth,
                            }, true)
                        }
                        else {
                            let lastObj = objects[0]
                            // console.log(lastObj)
                            // console.log(lastObj.viewportCenter())

                            let newTop = lastObj.top - clientHeight / 2
                            let newLeft = lastObj.left - offsetWidth / 2
                            let firstObjectPosition = [1, 0, 0, 1, -newLeft, -newTop]


                            _changePan({
                                clientID: clientID,
                                viewportTransform: firstObjectPosition,
                                containerHeight: clientHeight,
                                containerWidth: offsetWidth,
                            }, false)
                        }

                    }

                }
                // console.log('heyyy', isPreviousWhiteboardFile)
                callEnlivenFunctionInSequence(canvas, objects, background, isPreviousWhiteboardFile)
            }
            else {
                if (isPreviousWhiteboardFile) {
                    setIsPreviousWhiteboardFileLoading(false)
                    setDownloadingProgress(0)
                }
            }

            // console.log('hey', flag)
            // canvas.renderAll();
        }
        catch (err) {
            console.log(err)
        }

    }

    /**
     *
     *  @param {object} event - contains upload input data as object from which file to be used is extracted
     *  @returns - nothing
     *  file extracted is uploaded to canvas after checking position of last object by calculating max height
        and then uploading json obtained from whiteboard txt file below the last object in the whiteboard which is
        zidentified by the max height
     */
    const handleUploadWhiteboard = async (event) => {
        // handleChangeLoading(true);
        // console.log('pasting to whiteboard')
        _customToolbar.current.handleChangeUploading(true)
        let canvas = _sketch.current._fc.current;
        const file = event.target.files[0];
        let flag = false;
        let t;
        let tempCanvas;
        try {
            t = await file.text()
            tempCanvas = JSON.parse(t)
        }
        catch (err) {
            alert('File could not be processed')
            _customToolbar.current.handleChangeUploading(false)
            document.getElementById('file-to-upload').value = null;
            return
        }
        // console.log(tempCanvas)
        let totalHeightObj = await _sketch.current.calculateMaxHeight();
        let totalHeightOfObjects = totalHeightObj.totalHeight;
        //for testing if canvas file has containerHeight which in turns mean the file has beeen coded in new way and can be loaded
        for (let i = 0; i < (tempCanvas.objects).length; ++i) {
            tempCanvas.objects[i].loadingSource = 'file'
            tempCanvas.objects[i].top = tempCanvas.objects[i].top + totalHeightOfObjects
            if (tempCanvas.objects[i].containerHeight) {
                flag = true
            }
        }
        // console.log('hey', (tempCanvas.objects))
        let data = JSON.stringify(tempCanvas)
        if (!flag) {
            alert('File not valid for upload')
            _customToolbar.current.handleChangeUploading(false)
            document.getElementById('file-to-upload').value = null;
            return
        }
        const filePathArray = event.target.value.split("\\");
        // console.log("file path array " + filePathArray)
        let fileId = uuidv4();
        const uploadFileName = filePathArray[filePathArray.length - 1] + fileId;
        // console.log(uploadFileName)
        let storageFileLink;
        const blob = new Blob([data], { type: 'text/plain' });
        // console.log(blob)
        const whiteboardData = new FormData();
        whiteboardData.append('file', blob, uploadFileName);

        //storing to userID
        let status, res;
        try {
            res = await uploadFile(props.accessToken, whiteboardData);
            status = res.status
        }

        catch (err) {
            console.log(err);
            // status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            //uploaded
        }
        else {
            // console.log(status)
        }

        storageFileLink = UPLOAD_API_URL + 'uploads/' + clientID + '/' + uploadFileName

        // console.log(storageFileLink)
        // console.log('file uploaded')
        uploadCanvasToWhiteboardUsingFileUrl(canvas, data, storageFileLink)
        // console.log('uploadcanvasfrom url ended')
        _customToolbar.current.handleChangeUploading(false)
        document.getElementById('file-to-upload').value = null;
    }

    /**
     * downloads the current whiteboard as json in a txt file
     */

    const handleDownloadWhiteboard = async () => {
        // handleChangeLoading(true);
        setIsFileDownloading(true)
        uploadWhiteboardData()
        let canvas = _sketch.current._fc.current;
        let canvasData = await getCanvasData(canvas)
        // console.log(canvasData)
        const fileName = 'whiteBoardID:' + whiteBoardID + '.whiteboardData';
        const a = document.createElement("a");
        const file = new Blob([canvasData], { type: "text/plain" });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
        setIsFileDownloading(false)
        setDownloadingProgress(0)
    }

    /**
     *
     * @param {string} fileUrl  - Link to the json file onm the backend server
     * handles the whiteboard file uploaded as txt
     * file is extracted from the fileurl and uploaded to canvas after checking position of last object by calculating
       max height and then uploading json obtained from whiteboard below the last object in the whiteboard which is
       identified by the max height
     *
     */
    const uploadCanvasFromContentLibrary = async (fileUrl) => {
        let canvas = _sketch.current._fc.current;
        try {
            // console.log('yo')
            // console.log(fileUrl)
            const r = await fetch(fileUrl)
            // console.log(r)
            const t = await r.json()
            // console.log(t)
            let tempCanvas = t
            // console.log(tempCanvas)
            let totalHeightObj = await _sketch.current.calculateMaxHeight();
            let totalHeightOfObjects = totalHeightObj.totalHeight;
            for (let i = 0; i < (tempCanvas.objects).length; ++i) {
                tempCanvas.objects[i].loadingSource = 'file'
                tempCanvas.objects[i].top = tempCanvas.objects[i].top + totalHeightOfObjects
            }
            let data = JSON.stringify(tempCanvas)
            let fileId = uuidv4();
            const uploadFileName = fileId;
            // console.log(uploadFileName)
            let storageFileLink;
            const blob = new Blob([data], { type: 'text/plain' });
            // console.log(blob)
            const whiteboardData = new FormData();
            whiteboardData.append('file', blob, uploadFileName);

            //storing to userID
            let status, res;
            try {
                res = await uploadFile(props.accessToken, whiteboardData);
                status = res.status
            }

            catch (err) {
                console.log(err);
                status = err.response.status;
            }
            // console.log(index)
            if (status === 200) {
                //uploaded
            }
            else {
                console.log(status)
            }

            storageFileLink = UPLOAD_API_URL + 'uploads/' + clientID + '/' + uploadFileName

            // console.log(storageFileLink)
            uploadCanvasToWhiteboardUsingFileUrl(canvas, data, storageFileLink)
            // console.log('hey', tempCanvas)
            // console.log('hey', flag)
            // canvas.renderAll();
        }
        catch (err) {
            console.log(err)
        }


    }

    /**
     *
     * @param {object} canvas - this is the present canvas which is reference of the currrent whiteboard
     * @param {object} data - Contains objects to be pasted to the whiteboard
     * @param {string} fileUrl  - Link to the json file onm the backend server
     * @returns nothing
     * objects obtained from data sent to enliven function and file added event is created which contains file url
       and object id so that other client also receives filed added event and can upload json to their canvas
     */

    const uploadCanvasToWhiteboardUsingFileUrl = async (canvas, data, fileUrl) => {
        // _clear({ clientID: clientID })
        // console.log('calling enliven function', new Date())
        const objects = (JSON.parse(data)).objects
        let background = '';
        if ((JSON.parse(data)).background) {
            background = (JSON.parse(data)).background
        }
        let objIdSuffix = uuidv4()
        objects.forEach((element) => {
            // element.top = element.top
            element.objectID = (element.objectID).substring(0, 24) + objIdSuffix.substring(24, 36)
        })
        callEnlivenFunctionInSequence(canvas, objects, background)
        // console.log('enliven function ended', new Date())
        const eventType = 'file:added'
        const eventData = {
            canvasFileUrl: fileUrl,
            objectIdSuffix: objIdSuffix
        }
        let objectID = uuidv4();
        createWhiteBoardEventFunc(whiteBoardID, clientID, eventType, JSON.stringify(eventData), objectID)
    }

    /**
     *
     * @param {array} events - contains an array of objects of file added events
     * fetches array of all objects combined from events array and calls enliven function to paste objects to whiteboard
     */
    const handleFileAddedEvents = async (events) => {
        // console.log(_sketch)
        let canvas = _sketch.current._fc.current;
        let objects = await getObjectsFromFileAddedEvents(events)
        // console.log('file objects', objects)
        callEnlivenFunctionInSequence(canvas, objects)

    }

    const checkIfObjectOfFileEventAlreadyExists = (objectArray, object_id, timeStampFileEvent) => {
        for (let i = 0; i < objectArray.length; ++i) {
            if (objectArray[i].objectID === object_id) {
                if ((new Date(timeStampFileEvent)).getTime() < (new Date(objectArray[i].timestamp)).getTime())
                    return true
            }
        }
        return false
    }
    /**
     *
     * @param {array} events - contains an array of objects of file added events
     * @returns {array} - returns array of a all objects of file added events combined toigether
     * Combines objects of all file added events by fetching objetcs from the url and the accumulated objs are sent to enliven
       function so that they can be added to canvas together
     */
    const getObjectsFromFileAddedEvents = async (events) => {
        let objects = [];
        for (let i = 0; i < events.length; ++i) {
            let eventdata = JSON.parse(events[i].eventData);
            // console.log('file added event', eventdata)
            const fileUrl = eventdata.canvasFileUrl
            try {
                // console.log('yo')
                // console.log(fileUrl)
                const r = await fetch(fileUrl)
                // console.log(r)
                const t = await r.json()
                // console.log(t)

                const objsTemp = t.objects
                let objs = []
                objsTemp.forEach((element) => {
                    if (!(checkIfObjectAlreadyExists(databaseModifiedOrRemovedObjects.current, element.objectID, eventdata.timestamp))) {
                        element.objectID = (element.objectID).substring(0, 24) + (eventdata.objectIdSuffix).substring(24, 36)
                        objs.push(element)
                    }
                })
                // console.log(objs)
                // console.log('hey', objects)
                objects = [...objects, ...objs]
                // console.log('hey', flag)
                // canvas.renderAll();
            }
            catch (err) {
                console.log(err)
            }
        }
        return objects
    }

    /**
     *
     * @param {string} fileUrl - fileurl to fetch the objects to be pasted to canvas
     * @param {uuid} objectIdSuffix -if a file is uploaded 2 times the objects have different object ids so that
        they are treated as dieffent objects in the canvas
     *  This function is used to process file added events received from websockets or the database when reloading
     */
    const processCanvasToWhiteboardEvent = async (fileUrl, objectIdSuffix) => {
        let canvas = _sketch.current._fc.current;
        try {
            // console.log('yo')
            // console.log(fileUrl)
            const r = await fetch(fileUrl)
            // console.log(r)
            const t = await r.json()
            // console.log(t)
            let tempCanvas = t
            let data = JSON.stringify(tempCanvas)
            const objects = (JSON.parse(data)).objects
            for (let i = 0; i < (objects).length; ++i) {
                objects[i].objectID = (objects[i].objectID).substring(0, 24) + objectIdSuffix.substring(24, 36)
            }
            // console.log('hey', objects)
            let background;
            if ((JSON.parse(data)).background) {
                background = (JSON.parse(data)).background
            }
            callEnlivenFunctionInSequence(canvas, objects, background)

            // console.log('hey', flag)
            // canvas.renderAll();
        }
        catch (err) {
            console.log(err)
        }

    }

    /**
      //This is an intermediate function called before  using enliven code to print objects to canvas
      //@params include
      //
   */
    /**
     *
     * @param {object} canvas - this is the present canvas which is reference of the currrent whiteboard
     * @param {object} data - Contains objects to be pasted to the whiteboard
     * @param {string/object} background - background so that it can be set before pasting objects to canvas
     * This is an intermediate function called before  using enliven code to print objects to canvas
     * this function ensures that 1000 objects are enlivened at a time so that the system is able to handle the process
       so if there are more than 1000 objects it breaks thenm into sets of 1000 to enliven one by one using a timeout of
       5 seconds.
     */

    const callEnlivenFunctionInSequence = async (canvas, objects, background, isPreviousWhiteboardFile) => {
        let j = 0;
        // console.log(canvas, objects)
        if (background) {

            //to check if background is image or hex code
            if (typeof background === "string" && background !== '') {
                _sketch.current.setBackgroundColorForCanvas(canvas, background)
                handleBackgroundColor(background)
            }
            else if (typeof background === "object") {
                _sketch.current.setBackgroundImageForCanvas(canvas, background.source)
            }
        }
        for (let i = 0; i < objects.length; i += 1000) {
            if (isPreviousWhiteboardFile && downloadingProgress > 70) {
                setDownloadingProgress(downloadingProgress + 1)
            }
            if (i === 1000) {
                handleChangeLoading(false)
            }
            if (i === 3000) {
                break;
            }
            await canvasLoadingFromJsonEnliven(canvas, objects.slice(i, i + 1000))
            console.log(i + 1000, ' loaded')
        }

        if (isPreviousWhiteboardFile) {
            // console.log('prev all loaded')
            setDownloadingProgress(100)
            setTimeout(() => {
                setIsPreviousWhiteboardFileLoading(false)
                setDownloadingProgress(0)
            }, 500)
        }
        else {
            console.log('all loaded')
        }

    }

    /**
     *
     * @param {object} canvas - this is the present canvas which is reference of the currrent whiteboard
     * @param {object} data - Contains objects to be pasted to the whiteboard
     * function receives canvas and objects and params and pastes the objects to canvas using fabrics enliven method
        While uenlivening the objects checks for different type  of objects are made to ensure they behave in a desired way.
     */

    const canvasLoadingFromJsonEnliven = async (canvas, objects) => {
        await new Promise(async (resolveP, rejectP) => {
            // console.log('enliving')
            // console.log(fabric)

            fabric.util.enlivenObjects(objects, {})
                .then(objs => {
                    // console.log(objs)
                    objs.forEach((item) => {
                        // console.log(item)
                        scaleJSON(item)
                        item.dirty = false
                        canvas.add(item);
                        // item.set('crossOrigin', 'anonymous');
                        if (item.notVisibleTo) {
                            if (!props.isTeacher) {
                                if (item.notVisibleTo.includes('Student')) {
                                    // console.log('invisible object')

                                    item.visible = false
                                }
                            }

                        }

                        if (item.isPdf) {
                            canvas.sendObjectToBack(item)
                            // item.sendObjectToBack()
                            item.selectable = false
                            item.evented = false
                            item.hasBorders = false
                            item.hasControls = false
                            item.hasRotatingPoint = false
                            item.lockMovementY = false
                            item.lockMovementX = false
                        }
                        if (item.objectType) {

                            //figures out positionn of audio video objects and opens a media player on top of them
                            if (item.objectType === 'audio' || item.objectType === 'video') {
                                // console.log('audio video')
                                item.subTargetCheck = true
                                item.on('mousedown', _sketch.current.onClickAudioVideo);
                                if (item.objectState) {
                                    let positionDimensionObj = findObjectAndGetPositionAndDimensions(item.objectID)
                                    openMediaPlayerAndSendEvent(item.mediaFileUrl, item.objectType, positionDimensionObj.left, positionDimensionObj.top, positionDimensionObj.width, positionDimensionObj.height, item.objectID)
                                }
                            }
                            //figures out positionn of iframe objects which are transparent and opens an iframe on the desired position
                            else if (item.objectType === 'iframe') {
                                item.subTargetCheck = true
                                let positionDimensionObj = findObjectAndGetPositionAndDimensions(item.objectID)
                                createIframeUsingJavascript(positionDimensionObj, item.objectID, item.mediaFileUrl, _sketch.current.changeObjectPositionOnDrag, item.iframeType, props.clientID, whiteBoardID, props.accessToken)
                                let removeObjectByIdRef = _sketch.current.removeObjectById
                                document.getElementById(item.objectID + '-close').addEventListener('click', function (e) {
                                    e.preventDefault();
                                    document.getElementById(item.objectID).remove();
                                    // console.log(this)
                                    removeObjectByIdRef(item.objectID)
                                }, false)
                            }
                            //figures out positionn of pdftoview objects which are transparent and opens an iframe on the desired position
                            else if (item.objectType === 'pdfToView') {
                                item.subTargetCheck = true
                                item.on('mousedown', _sketch.current.onClickPdfViewer);

                            }
                        }

                        // item.moveTo(-10000)
                    });
                    canvas.requestRenderAll();
                    resolveP();
                });
            // canvas.renderAll();
            // Make sure to call this once you're ready!

        })
    }



    /**
     *
     * @param {object} canvas - this is the present canvas which is reference of the currrent whiteboard
     * @returns {object} - This function returns the canvas as a json object and properties wich need to included in the json file are specified here
     */

    const getCanvasData = async (canvas) => {
        let tempCanvasString = JSON.stringify(canvas.toObject(['iframeType', 'objectState', 'hoverCursor', 'mediaFileUrl', 'objectType', 'objectID', 'containerHeight', 'containerWidth', 'scaleX', 'scaleY', 'isPdf', 'createdAt', 'notVisibleTo']))
        let tempCanvas = JSON.parse(tempCanvasString)
        // console.log(tempCanvas)
        for (let i = 0; i < (tempCanvas.objects).length; ++i) {
            tempCanvas.objects[i].loadingSource = 'file'
        }
        // console.log(tempCanvas)
        let viewportTransform = canvas.viewportTransform.slice();
        tempCanvas.lastViewport = viewportTransform;
        let res = JSON.stringify(tempCanvas);
        return res;
    }

    /**
     *  This function uploads the whiteboard as a txt file to the server so that it can be accessed later
     */

    const uploadWhiteboardData = async () => {
        let canvas = _sketch.current._fc.current;
        let canvasData = await getCanvasData(canvas)
        const sessionStartTime = await getSessionStartTimeByID(whiteBoardID, props.accessToken)
        const fileName = props.slateRef.current.title + '_' + sessionStartTime + '_whiteBoardID:' + whiteBoardID + '.whiteboardData';
        const blob = new Blob([canvasData], { type: 'application/octet-stream' });
        // console.log(blob)
        const whiteboardData = new FormData();
        whiteboardData.append('file', blob, fileName);

        let res1, status1

        //storing to whiteboardID

        try {
            res1 = await uploadWhiteboardFiles(props.accessToken, whiteBoardID, 'JSON', whiteboardData);
            status1 = res1.status
        }

        catch (err) {
            console.log(err);
            status1 = err.response.status;
        }
        // console.log(index)
        if (status1 === 200) {
            // alert('file uploaded')
        }
        else {
            console.log(status1)
        }

        //storing to userID

        let status, res;
        try {
            res = await uploadFile(props.accessToken, whiteboardData);
            status = res.status
        }

        catch (err) {
            console.log(err);
            status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            //uploaded
        }
        else {
            console.log(status)
        }

    }

    /**
     *  Restores zoom to original level 1
     */

    const handleCanvasZoomLevel = (value) => {
        if (_customToolbar && _customToolbar.current) {
            _customToolbar.current.setCanvasZoomLevel(value)
        }
    }


    const onNewZoomRestore = () => {
        handleCanvasZoomLevel(1)
        _sketch.current.newZoom({ zoomLevel: 1, clientID: clientID })
    }


    const onNewZoomAbsolute = (zoomLevelTemp) => {
        handleCanvasZoomLevel(zoomLevelTemp)
        _sketch.current.newZoom({ zoomLevel: zoomLevelTemp, clientID: clientID })
    }
    /**
     *
     * @param {number} zoomFactor - Receives a zoom factor and multiplies it with current zoom level to get new zoom level
     * Also checks if new zoom level is in between the max and min value constants set by us
     */




    const onNewZoom = (zoomFactor) => {
        let canvas = _sketch.current._fc.current
        let currentZoom = canvas.getZoom();
        // canvas.setZoom(1.5 * zoom);
        let newZoomLevel = currentZoom * zoomFactor
        // console.log(newZoomLevel)
        handleCanvasZoomLevel(newZoomLevel)
        if (newZoomLevel <= MAX_ZOOM && newZoomLevel >= MIN_ZOOM) {
            _sketch.current.newZoom({ zoomLevel: newZoomLevel, clientID: clientID })
        }
    }

    /**
     *
     * @param {object} event - contains upload input data as object from which file to be used is extracted
     * @param {string} accessToken - used to access data from database
     * @param {function} handleChangeLoading - used to toggle state of loader in the whiteboard
     * @param {string} fileType - identify the type of file uploaded
     * Adds audio video files to the canvas and stores them in the database as objects
     */

    const uploadMediaAndPlay = async (event, accessToken, handleChangeLoading, fileType) => {
        handleChangeLoading(true)
        const file = event.target.files[0];
        const filePathArray = event.target.value.split("\\");
        // console.log("file path array " + filePathArray)
        const uploadFileName = filePathArray[filePathArray.length - 1];
        // console.log("file name " + uploadFileName)
        let uploadFileUrl = UPLOAD_API_URL + 'uploads/' + clientID + '/' + uploadFileName;
        uploadFileUrl = uploadFileUrl.replace(/ /g, '%20')
        // console.log(uploadFileUrl)

        let streamFileUrl = STREAM_FILE_SERVER + 'uploads/' + clientID + '/' + uploadFileName;
        const fileData = new FormData();
        fileData.append('file', file);
        let status, res;
        try {
            res = await uploadFile(accessToken, fileData);
            status = res.status
        }

        catch (err) {
            console.log(err);
            status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            // openMediaPlayerAndSendEvent(uploadFileUrl, fileType)
            console.log(fileType)
            if (fileType.includes('audio')) {
                _sketch.current.addAudioVideoTag(uploadFileUrl, 'audio', uploadFileName)
            }
            else {
                _sketch.current.addAudioVideoTag(uploadFileUrl, 'video', uploadFileName)
            }
        }
        else {
            console.log(status)
        }
        // console.log('hey')
        handleChangeLoading(false);
        document.getElementById('file-to-upload').value = null;
    }

    /**
     *
     * @param {string} uploadFileUrl - contains url of media to be played
     * @param {string} fileType - file type of media to identify if it is audio or video
     * @param {number} left - position of media object from left
     * @param {number} top - position of media object from top
     * @param {number} height - height  of media object
     * @param {number} width - width of media object
     * @param {uuid} objectID - unique id of the object
     * Code to operate the the audio video tags which when clicked cause a media player to appear and
       an event is sent to the other clients to open media player and play the file
     */

    const openMediaPlayerAndSendEvent = (uploadFileUrl, fileType, left, top, height, width, objectID) => {
        props.mediaPlayerRef.current.handleMediaUrl(uploadFileUrl)
        props.mediaPlayerRef.current.handlePositionOfPlayer(left, top)
        props.mediaPlayerRef.current.handleSizeOfPlayer(width, height)
        // console.log(left, top)
        if (fileType.includes('audio')) {
            props.mediaPlayerRef.current.handleisAudio(true)
        }
        else {
            props.mediaPlayerRef.current.handleisAudio(false)
        }
        props.mediaPlayerRef.current.handleOpenMediaPlayer(true)
        props.sendLessonEventViaWebSocket({
            eventType: 'handleMediaPlayer',
            eventData: {
                'openMediaPlayer': true,
                'sentBy': props.userName,
                'mediaUrl': uploadFileUrl,
                'fileType': fileType,
                'objectID': objectID
            }
        })
    }

    /**
    *
    * @param {string} uploadFileUrl - contains url of iframe to be played
    * @param {number} left - position of iframe object from left
    * @param {number} top - position of iframe object from top
    * @param {number} height - height  of iframe object
    * @param {number} width - width of iframe object
    * @param {uuid} objectID - unique id of the object
    * Code to operate the the iframe tags which are transparent and when clicked cause an iframe to appear and
      an event is sent to the other clients to open the same iframe there
   */

    const openIframeAndSendEvent = (uploadFileUrl, left, top, height, width, objectID) => {
        props.iframeBoxRef.current.handleMediaUrl(uploadFileUrl)
        props.iframeBoxRef.current.handlePositionOfPlayer(left, top)
        props.iframeBoxRef.current.handleSizeOfPlayer(width, height)
        // console.log(left, top)
        props.iframeBoxRef.current.handleOpenIframeBox(true)
        props.sendLessonEventViaWebSocket({
            eventType: 'handleIframe',
            eventData: {
                'openIframeBox': true,
                'sentBy': props.userName,
                'mediaUrl': uploadFileUrl,
                'objectID': objectID
            }
        })
    }

    /**
    *
    * @param {string} uploadFileUrl - contains url of pdf to be played
    * @param {number} left - position of pdf object from left
    * @param {number} top - position of pdf object from top
    * @param {number} height - height  of pdf object
    * @param {number} width - width of pdf object
    * @param {uuid} objectID - unique id of the object
    * Code to operate the the iframe tags which are transparent and when clicked cause a pdf viewer to appear and
      an event is sent to the other clients to open the same pdf viewerthere
   */

    const openPdfViewerAndSendEvent = (uploadFileUrl, left, top, height, width, objectID) => {
        props.pdfViewerRef.current.handleMediaUrl(uploadFileUrl)
        props.pdfViewerRef.current.handlePositionOfPlayer(left, top)
        props.pdfViewerRef.current.handleSizeOfPlayer(width, height)
        // console.log(left, top)
        props.pdfViewerRef.current.handleOpenIframeBox(true)
        props.sendLessonEventViaWebSocket({
            eventType: 'handlePdfViewer',
            eventData: {
                'openIframeBox': true,
                'sentBy': props.userName,
                'mediaUrl': uploadFileUrl,
                'objectID': objectID
            }
        })
    }

    /**
     *
     * @param {uuid} objectID - used to identify a particular object on the canvas
     * @returns {object} - returns the position and dimensions of the object
     */

    const findObjectAndGetPositionAndDimensions = (objectID) => {
        let canvas = _sketch.current._fc.current
        let objects = canvas.getObjects().slice();
        let activeObj
        for (const obj of objects) {
            if (obj.objectID === objectID) {
                activeObj = obj
            }
        }
        if (activeObj) {
            return {
                'left': activeObj.left * canvas.viewportTransform[0] - (-canvas.viewportTransform[4]),
                'top': activeObj.top * canvas.viewportTransform[0] - (-canvas.viewportTransform[5]),
                'height': activeObj.height * activeObj.scaleY * canvas.viewportTransform[0],
                'width': activeObj.width * activeObj.scaleX * canvas.viewportTransform[0]
            }
        }
    }


    window.pasteImageToWhiteboard = pasteImage
    window.findObjectAndGetPositionAndDimensions = findObjectAndGetPositionAndDimensions
    console.log('whiteboard2 rerendering')
    return (
        <>
            {


                <ThemeProvider theme={theme}>

                    <Box sx={{ height: "100%", width: "100%", display: "block", flexDirection: "row", alignItems: "center", visibility: props.isWhiteboard ? "visible" : "hidden" }} >
                        {!props.isWhiteboardPlayer && <Snackbar
                            open={isPreviousWhiteboardFileLoading}
                            // open={true}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            style={{ top: '80px', left: '10px' }}
                        >
                            <Box
                                severity="info"
                                sx={{
                                    width: '100%',
                                    padding: '12px 12px',
                                    borderRadius: '5px',
                                    backgroundColor: THEME_COLOR,
                                    color: '#ffffff',
                                    display: 'flex',
                                    // height: '30px'
                                }}
                            >
                                <Typography sx={{ fontSize: '0.9rem', marginRight: '10px' }}>
                                    Loading previous whiteboard file&nbsp;{downloadingProgress > 0 ? downloadingProgress + '%' : ''}
                                </Typography>
                                <CircularProgress sx={{ height: '17.5px !important', width: '17.5px !important', color: 'white' }} />
                            </Box>
                        </Snackbar>}
                        {!props.isWhiteboardPlayer && <Snackbar
                            open={isFileDownloading}
                            // open={true}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            style={{ top: '80px', left: '10px' }}
                        >
                            <Box
                                severity="info"
                                sx={{
                                    width: '100%',
                                    padding: '12px 12px',
                                    borderRadius: '5px',
                                    backgroundColor: THEME_COLOR,
                                    color: '#ffffff',
                                    display: 'flex',
                                    // height: '30px'
                                }}
                            >
                                <Typography sx={{ fontSize: '0.9rem', marginRight: '10px' }}>
                                    Downloading&nbsp;{downloadingProgress > 0 ? downloadingProgress + '%' : ''}
                                </Typography>
                                <CircularProgress sx={{ height: '17.5px !important', width: '17.5px !important', color: 'white' }} />
                            </Box>
                        </Snackbar>}

                        {/* {isPreviousWhiteboardFileLoading && <Box


                                    sx={{
                                        position: 'fixed',
                                        top: '10%', // Move 100 pixels from the top
                                        // transform: 'translateX(-50%)', // Center horizontally
                                        left: '40%', // Center horizontally
                                        // width: '100%',
                                        boxShadow: 1,
                                        borderRadius: '10px',
                                        padding: "7.5px 10px",
                                        backgroundColor: '#ffffff',
                                        color: THEME_COLOR
                                    }}
                                >

                                    Loading previous whiteboard file...

                                </Box>} */}
                        {props.isWhiteboardPlayer && <WhiteboardPlayer
                            ref={whiteboardPlayerRef}
                            playEventsInOrder={playEventsInOrder}
                            whiteBoardID={whiteBoardID}
                        />}
                        <Box id='whiteboard-screenshot-box' sx={{ width: 1, height: 1 }} >
                            {<SketchField
                                name="sketch"
                                className={classes.canvasArea}
                                ref={_sketch}
                                isWhiteboardPlayer={props.isWhiteboardPlayer}
                                width={controlledSize ? sketchWidth : null}
                                height={controlledSize ? sketchHeight : null}
                                defaultValue={null}
                                value={null}
                                forceValue
                                onChange={_onSketchChange}
                                clientID={clientID}
                                isPlayer={isPlayer}
                                isTeacher={props.isTeacher}
                                onObjectMoving={onObjectMoving}
                                onObjectAdded={onObjectAdded}
                                onObjectModified={onObjectModified}
                                onCanvasCleared={onCanvasCleared}
                                onObjectRemoved={onObjectRemoved}
                                onMouseUp={onMouseUp}
                                onPanChanged={onPanChanged}
                                onBackgroundColorChanged={onBackgroundColorChanged}
                                onBeforePathCreated={onBeforePathCreated}
                                isViewer={props.isViewer}
                                calculateNumberOfPages={calculateNumberOfPages}
                                // onPathCreated ={onPathCreated} No need as the object added is always called
                                identifyCurrentPage={identifyCurrentPage}
                                onNewZoomChanged={onNewZoomChanged}
                                openMediaPlayerAndSendEvent={openMediaPlayerAndSendEvent}
                                openIframeAndSendEvent={openIframeAndSendEvent}
                                openPdfViewerAndSendEvent={openPdfViewerAndSendEvent}
                                findObjectAndGetPositionAndDimensions={findObjectAndGetPositionAndDimensions}
                                whiteBoardID={whiteBoardID}
                                accessToken={props.accessToken}
                                scaleJSON={scaleJSON}
                                onNewZoomAbsolute={onNewZoomAbsolute}
                                firePanEvent={firePanEvent}
                                sendLessonEventViaWebSocket={props.sendLessonEventViaWebSocket}
                                currentTool={currentTool}
                                _selectedTool={_selectedTool}
                                setIsCanvasInitialized={setIsCanvasInitialized}
                                _customToolbar={_customToolbar}
                            />}
                            {!props.isWhiteboardPlayer && isLoading &&
                                <Grid sx={{
                                    position: "absolute", width: "100%", height: "100vh", opacity: "1", top: "0", left: "0",
                                    backgroundColor: THEME_COLOR, display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center"
                                }}>
                                    <Box sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img style={{ height: '60px', width: 'auto', marginBottom: '10px' }} src='/loader_title.png' />
                                        <Typography sx={{
                                            color: '#ffffff',
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            fontSize: '1.25rem'
                                        }}>
                                            Loading the whiteboard.It may take a while..
                                        </Typography>
                                        <Box sx={{
                                            marginTop: '10px',
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                        }}>
                                            <CustomLoader />
                                        </Box>
                                    </Box>
                                </Grid>
                            }
                        </Box>
                        {!props.isWhiteboardPlayer && !props.isViewer && isCanvasInitialized && <CustomToolbar
                            ref={_customToolbar}
                            isWhiteboard={props.isWhiteboard}
                            isZoomEnabled={props.isZoomEnabled}
                            isDeleteAllEnabled={props.isDeleteAllEnabled}
                            isLoading={isLoading}
                            clientID={clientID}
                            handleBackgroundColor={handleBackgroundColor}
                            _changeBackgroundColor={_changeBackgroundColor}
                            _addText={_addText}
                            _addPolyline={_addPolyline}
                            _removeSelected={_removeSelected}
                            isCanvasInitialized={isCanvasInitialized}
                            zoomInFactor={zoomInFactor}
                            zoomOutFactor={zoomOutFactor}
                            convertPdfToImages={convertPdfToImages}
                            // convertPdfToBox={convertPdfToBox}
                            pasteImagesAsPdf={pasteImagesAsPdf}
                            addImageUrl={addImageUrl}
                            handleChangeLoading={handleChangeLoading}
                            enqueueSnackbar={props.enqueueSnackbar}
                            panToNewPdf={panToNewPdf}
                            _sketch={_sketch}
                            whiteBoardID={whiteBoardID}
                            handleText={handleText}
                            text={text}
                            filesDownloadFolder={props.filesDownloadFolder}
                            isTeacher={props.isTeacher}
                            _clear={_clear}
                            _changePan={_changePan}
                            panLeft={panLeft}
                            panDown={panDown}
                            panUp={panUp}
                            panRight={panRight}
                            panToLastPage={panToLastPage}
                            panByPageNumber={panByPageNumber}
                            panFactorIcon={panFactorIcon}
                            displayNotesFromLink={displayNotesFromLink}
                            recordingTimeRef={props.recordingTimeRef}
                            accessToken={props.accessToken}
                            slateID={props.slateID}
                            isSearchFocus={isSearchFocus}
                            uploadCanvasToWhiteboard={uploadCanvasToWhiteboard}
                            uploadWhiteboardData={uploadWhiteboardData}
                            isTourEnabled={props.isTourEnabled}
                            slateRef={props.slateRef}
                            sendFileSharedEventViaWebSocket={props.sendFileSharedEventViaWebSocket}
                            userName={props.userName}
                            sendSmileyEventViaWebSocket={props.sendSmileyEventViaWebSocket}
                            mediaPlayerRef={props.mediaPlayerRef}
                            sendLessonEventViaWebSocket={props.sendLessonEventViaWebSocket}
                            isTextFocused={props.isTextFocused}
                            saveScreenshotToNotes={saveScreenshotToNotes}
                            handlePageNumber={handlePageNumber}
                            handleTotalNumberOfPages={handleTotalNumberOfPages}
                            downloadCanvasAsImage={downloadCanvasAsImage}
                            uploadCanvasFromContentLibrary={uploadCanvasFromContentLibrary}
                            handleUploadWhiteboard={handleUploadWhiteboard}
                            handleDownloadWhiteboard={handleDownloadWhiteboard}
                            onNewZoom={onNewZoom}
                            onNewZoomRestore={onNewZoomRestore}
                            uploadMediaAndPlay={uploadMediaAndPlay}
                            openMediaPlayerAndSendEvent={openMediaPlayerAndSendEvent}
                            onNewZoomAbsolute={onNewZoomAbsolute}
                            autoRecord={props.autoRecord}
                            sessionDataRef={props.sessionDataRef}
                            currentTool={currentTool}
                            _selectedTool={_selectedTool}
                            lineWidthRef={lineWidthRef}
                            lineColorRef={lineColorRef}
                            highlighterWidthRef={highlighterWidthRef}
                            highlighterColorRef={highlighterColorRef}
                            toolNameRef={toolNameRef}
                        />
                        }

                        {/* {props.isViewer && <Box sx={[{ display: "flex", flexGrow: 0, flexWrap: "wrap", justifyContent: "center", position: 'fixed', bottom: 0, width: "100%" }, isLoading && { visibility: "hidden" }]}>
                                {props.isWhiteboard &&
                                    <>
                                        {!isLoading &&
                                            <Grid sx={{ position: "fixed", left: 0, bottom: { lg: 0, xs: '150px', sm: '110px', md: "80px" }, }}>
                                                <Grid container item justifyContent="center">
                                                    <IconButton sx={{ color: THEME_COLOR }} onClick={() => { panUp(panFactorIcon) }} size={"small"}>
                                                        <ArrowDropUpIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton
                                                        sx={{ color: THEME_COLOR }}
                                                        onClick={() => { panLeft(panFactorIcon) }}
                                                        size={"small"}>
                                                        <ArrowLeftIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        sx={{ color: THEME_COLOR }}
                                                        onClick={() => _changePan({
                                                            clientID: clientID,
                                                            viewportTransform: [1, 0, 0, 1, 0, 0]
                                                        })}
                                                        size={"small"}
                                                    >
                                                        <RotateLeftIcon />
                                                    </IconButton>
                                                    <IconButton sx={{ color: THEME_COLOR }} onClick={() => { panRight(panFactorIcon) }} size={"small"}>
                                                        <ArrowRightIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item container justifyContent="center">
                                                    <IconButton sx={{ color: THEME_COLOR }} onClick={() => { panDown(panFactorIcon) }} size={"small"}>
                                                        <ArrowDropDownIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        }
                                        {props.isZoomEnabled && <Tooltip title="Zoom in">
                                            <IconButton
                                                sx={{ color: THEME_COLOR }}
                                                onClick={(e) => {
                                                    onNewZoom(zoomInFactor);
                                                }}
                                                size="large"
                                                disabled={canvasZoomLevel * zoomInFactor > MAX_ZOOM}
                                            >
                                                <ZoomInIcon />
                                            </IconButton>
                                        </Tooltip>
                                        }
                                        {props.isZoomEnabled &&
                                            <Tooltip title="Restore zoom">
                                                <IconButton sx={{ color: THEME_COLOR }} onClick={onNewZoomRestore} size="large">
                                                    <YoutubeSearchedForIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {props.isZoomEnabled &&
                                            <Tooltip title="Zoom out">
                                                <IconButton
                                                    id="zoomOut-button"
                                                    sx={{ color: THEME_COLOR }}
                                                    onClick={(e) => {
                                                        onNewZoom(zoomOutFactor);
                                                    }}
                                                    size="large"
                                                    disabled={canvasZoomLevel * zoomOutFactor < MIN_ZOOM}
                                                >
                                                    <ZoomOutIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {<Tooltip title={"Pan"}>
                                            <IconButton
                                                sx={{ color: '#5pasteListener850EC' }}
                                                onClick={(event) => {
                                                    _customToolbar.current._selectTool({ target: { value: "Pan" } })
                                                }}
                                                style={toolName === "Pan" ? styles.selected : {}}
                                                size="large">
                                                <PanToolIcon />
                                            </IconButton>
                                        </Tooltip>
                                        }
                                    </>}
                            </Box>} */}
                    </Box>


                </ThemeProvider>
            }

            {props.screenRecording && <Box sx={{
                position: 'fixed',
                top: '5px',
                right: '305px',
                width: '200px',
                height: '40px',
                background: '#f8f8f8',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'row',
                padding: '2px 10px 2px',
                justifyContent: "right",
                boxShadow: '2',

            }}><Tooltip title={"Start"}>
                    <IconButton
                        sx={{ color: THEME_COLOR, fontSize: '1rem' }}
                        onClick={() => {
                            startRecording();

                        }}
                        size="medium">
                        Start
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Stop"}>
                    <IconButton
                        sx={{ color: THEME_COLOR, fontSize: '1rem' }}
                        onClick={() => {
                            mediaRecorder.stop();

                        }}
                        size="medium">

                        Stop
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Download Screen Recordings"}>
                    <IconButton
                        sx={{ color: THEME_COLOR, fontSize: '1rem' }}
                        onClick={() => {
                            downloadVideoStream()

                        }}
                        size="medium">
                        REC
                        <DownloadIcon />
                    </IconButton>
                </Tooltip></Box>}
        </>
    );

}


// async function convertBlobToFileBuffer(blob) {
//     const arrayBuffer = await blob.arrayBuffer();
//     const fileBuffer = Buffer.from(arrayBuffer);
//     return fileBuffer
// }

function getQueryStringParams(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');

    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1];
        }
    }
}

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

/**
 *
 * @param {string} folderName - contains folderName in which file will be stored
 * @param {string} screenCapture -contains image data captured by screencapture
 * @param {number} width - width of image captured
 * @param {number} height - height of image captured
* @param {string} accessToken - used to access data from database
 */
const saveClippedImage = async (folderName, screenCapture, width, height, accessToken) => {
    if (screenCapture !== 'data:,') {
        let block = screenCapture.split(";");
        // Get the content type
        let contentType = block[0].split(":")[1];
        // get the real base64 content of the file
        let realData = block[1].split(",")[1];// In this case "iVBORw0KGg...."

        // Convert to blob
        let blob = b64toBlob(realData, contentType);
        // const fileLink = "https://" + awsmobile.aws_user_files_s3_bucket + ".s3.ap-south-1.amazonaws.com/public/";
        let currentTime = moment().toISOString();
        const whiteBoardID = getQueryStringParams("whiteBoardID");
        let storageFileLink;
        let filename = currentTime + "_" + width + "_" + height;
        const imageData = new FormData();
        // console.log(blob)
        const myFile = new File([blob], filename, {
            type: blob.type,
        });
        imageData.append('file', myFile);
        let status, res;
        try {
            res = await uploadWhiteboardFiles(accessToken, whiteBoardID, folderName, imageData);
            status = res.status
        }

        catch (err) {
            console.log(err);
            status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            // alert('file uploaded')
        }
        else {
            console.log(status)
        }
        storageFileLink = UPLOAD_API_URL + "uploads/whiteBoardStorage/" + whiteBoardID + '/' + folderName + filename
    }
};

/**
 *
 * @param {string} folderName - contains folderName in which file will be stored
 * @param {string} imageUrl - contains image data stored in url
 * @param {string} accessToken - used to access data from database
 * adds screenshot to notes whcih can be downloaded later
 */
const saveScreenshotToNotes = async (imageUrl, folderName, accessToken) => {

    const blob = await fetch(imageUrl).then(res => res.blob())
    var currentTime = moment().toISOString();
    const whiteBoardID = getQueryStringParams("whiteBoardID");
    let storageFileLink;
    let width = document.body.clientWidth;
    let height = document.body.clientHeight;
    let filename = currentTime + "_" + width + "_" + height;
    const imageData = new FormData();
    // console.log(blob)
    const myFile = new File([blob], filename, {
        type: blob.type,
    });
    imageData.append('file', myFile);
    let status, res;
    try {
        res = await uploadWhiteboardFiles(accessToken, whiteBoardID, folderName, imageData);
        status = res.status
    }

    catch (err) {
        console.log(err);
        status = err.response.status;
    }
    // console.log(index)
    if (status === 200) {
        // alert('file uploaded')
    }
    else {
        console.log(status)
    }
    storageFileLink = UPLOAD_API_URL + "uploads/whiteBoardStorage/" + whiteBoardID + '/' + folderName + filename
    // console.log("storage file link is " + storageFileLink);

};

/**
 *
 * @param {object} event - contains upload input data as object from which file to be used is extracted
 * @param {function} addImg -function of sketchfield to add image to canvas
 * @param {string} accessToken - used to access data from database
 * @param {uuid} whiteBoardID - whiteboard id of current session
 * @param {function} handleChangeLoading - used to toggle state of loader in the whiteboard
 * adds image to canvas
 */
const addImageUrl = async (event, addImg, handleChangeLoading, whiteBoardID, enqueueSnackbar, accessToken) => {
    handleChangeLoading(true);
    const file = event.target.files[0];
    const filePathArray = event.target.value.split("\\");
    // console.log("file path array " + filePathArray)
    const uploadFileName = filePathArray[filePathArray.length - 1];

    console.log(uploadFileName)
    if (file && file.size > 10000000) {
        handleChangeLoading(false);
        alert("file size limit exceeded!(file size less than 10MB is allowed)");
        return
    }
    if (file) {
        let storageFileLink;
        // const fileLink = "https://" + awsmobile.aws_user_files_s3_bucket + ".s3.ap-south-1.amazonaws.com/public/";
        const imageData = new FormData();
        imageData.append('file', file);
        let status, res;
        try {
            res = await uploadWhiteboardFiles(accessToken, whiteBoardID, 'images', imageData);
            status = res.status
        }

        catch (err) {
            console.log(err);
            // status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            // alert('file uploaded')
        }
        else {
            console.log(status)
        }
        storageFileLink = UPLOAD_API_URL + "uploads/whiteBoardStorage/" + whiteBoardID + '/images/' + uploadFileName

        // console.log("storage file link is " + storageFileLink);
        await addImg(storageFileLink, false);

    }
    document.getElementById('file-to-upload').value = null;
    handleChangeLoading(false);

}

const displayNotesFromLink = async (fileUrl, uploadFileNameTemp, addImg, changePan, handleChangeLoading, accessToken) => {
    let uploadFileName = uploadFileNameTemp.replace('+', '_').replace('&', '_');
    axios({
        method: 'GET',
        url: fileUrl,
        responseType: 'arraybuffer',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    })
        .then(async (response) => {
            // console.log(response.data)
            let data = response.data
            const pdf = await pdfjs.getDocument(data).promise;
            // console.log(pdf)
            const canvas = document.createElement("canvas");
            var pannedToNewPdf = false;
            // console.log(uploadFileName)
            for (let i = 0; i < pdf.numPages; i++) {
                if (i === 1) {
                    handleChangeLoading(false);
                }
                const page = await pdf.getPage(i + 1);
                const viewport = page.getViewport({ scale: 2 });
                const context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                await page.render({ canvasContext: context, viewport: viewport }).promise;
                const daturl = canvas.toDataURL("image/png")
                const blob = await fetch(daturl).then(res => res.blob())

                const whiteBoardID = getQueryStringParams("whiteBoardID");
                let storageFileLink;

                const imageData = new FormData();
                // console.log(blob)
                const myFile = new File([blob], i + '.png', {
                    type: blob.type,
                });
                imageData.append('file', myFile);
                let status, res;
                try {
                    res = await uploadWhiteboardFiles(accessToken, whiteBoardID, 'pdf/' + uploadFileName, imageData);
                    status = res.status
                }

                catch (err) {
                    console.log(err);
                    // status = err.response.status;
                }
                // console.log(index)
                if (status === 200) {
                    // alert('file uploaded')
                }
                else {
                    // console.log(status)
                }
                storageFileLink = UPLOAD_API_URL + "uploads/whiteBoardStorage/" + whiteBoardID + '/pdf/' + uploadFileName + '/' + i + '.png'
                // console.log("storage file link is " + storageFileLink);



                if (!pannedToNewPdf) {
                    pannedToNewPdf = true;
                    changePan();
                }
                await addImg(storageFileLink);

            }
            canvas.remove();
            handleChangeLoading(false);
        });



}





const readFileData = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            resolve(e.target.result);
        };
        reader.onerror = (err) => {
            reject(err);
        };
        reader.readAsDataURL(file);
    });
};

//param: file -> the input file (e.g. event.target.files[0])
//return: images -> an array of images encoded in base64
const convertPdfToImages = async (event, addImg, changePan, handleChangeLoading, folderName, enqueueSnackbar, accessToken) => {
    //console.log("here");
    //const images = [];

    handleChangeLoading(true);
    const file = event.target.files[0];
    // console.log('uploaded file', file)
    if (file && file.size > 50000000) {
        handleChangeLoading(false);
        alert("file size limit exceeded!(file size less than 50MB is allowed)");
        return
    }
    const data = await readFileData(file);
    // console.log('uploaded file', data)
    const pdf = await pdfjs.getDocument(data).promise;
    // console.log(pdf)
    const canvas = document.createElement("canvas");
    var pannedToNewPdf = false;

    const filePathArray = event.target.value.split("\\");
    const uploadFileName = (filePathArray[filePathArray.length - 1].split('.')[0]).replace('+', '_').replace('&', '_');
    // console.log(uploadFileName)
    for (let i = 0; i < pdf.numPages; i++) {
        if (i === 1) {
            handleChangeLoading(false);
        }
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({ scale: 2 });
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport: viewport }).promise;
        const daturl = canvas.toDataURL("image/png")
        // var fileName = folderName + uploadFileName + '$' + key + '/' + (i + 1);
        const blob = await fetch(daturl).then(res => res.blob())

        const whiteBoardID = getQueryStringParams("whiteBoardID");
        let storageFileLink;
        const imageData = new FormData();
        // console.log(blob)
        const myFile = new File([blob], i + '.png', {
            type: blob.type,
        });
        imageData.append('file', myFile);
        let status, res;
        try {
            res = await uploadWhiteboardFiles(accessToken, whiteBoardID, 'pdf/' + uploadFileName, imageData);
            status = res.status
        }

        catch (err) {
            console.log(err);
            status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            // alert('file uploaded')
        }
        else {
            console.log(status)
        }
        storageFileLink = UPLOAD_API_URL + "uploads/whiteBoardStorage/" + whiteBoardID + '/pdf/' + uploadFileName + '/' + i + '.png'
        console.log("storage file link is " + storageFileLink)


        if (!pannedToNewPdf) {
            pannedToNewPdf = true;
            await changePan();
        }
        await addImg(storageFileLink);
        // console.log(result, i)

    }
    canvas.remove();
    document.getElementById('file-to-upload').value = null;
    handleChangeLoading(false);
    //console.log(JSON.stringify(images));
    //return images;
};

/**
 *
 * @param {[array]} pdfImagesArray - contains blobs of images to be pasted to the whiteboard one after another vertically in correct order
 * @param {function} addImg -function of sketchfield to add image to canvas
 * @param {function} changePan -function to pan to last element of canvas vertically
 * @param {function} handleChangeLoading - used to toggle state of loader in the whiteboard
 * @param {string} accessToken - used to access data from database
 * @param {uuid} whiteBoardID - whiteboard id of current session
 * @param {string} uploadFileName - contains name of directory to which these images will be uploaded
 */
const pasteImagesAsPdf = async (pdfImagesArray, addImg, changePan, handleChangeLoading, accessToken, whiteBoardID, uploadFileName, handleTotalPagesToUpload, handlePagesUploaded) => {
    handleChangeLoading(true)
    handleTotalPagesToUpload(pdfImagesArray.length)
    let pannedToNewPdf = false;
    for (let i = 0; i < pdfImagesArray.length; i++) {
        if (i == 1) {
            // handleChangeLoading(false)
        }

        let storageFileLink;
        if (pdfImagesArray[i].type && pdfImagesArray[i].type === 'pdf') {
            const blob = await fetch(pdfImagesArray[i].src).then(res => res.blob())
            const imageData = new FormData();
            // console.log(blob)
            const myFile = new File([blob], i + '.png', {
                type: blob.type,
            });
            imageData.append('file', myFile);
            let status, res;
            try {
                res = await uploadWhiteboardFiles(accessToken, whiteBoardID, 'pdf/' + uploadFileName, imageData);
                status = res.status
            }

            catch (err) {
                console.log(err);
                status = err.response.status;
            }
            // console.log(index)
            if (status === 200) {
                // alert('file uploaded')
            }
            else {
                console.log(status)
            }
            storageFileLink = UPLOAD_API_URL + "uploads/whiteBoardStorage/" + whiteBoardID + '/pdf/' + uploadFileName + '/' + i + '.png'

            // console.log(result, i)

        }
        else {
            storageFileLink = pdfImagesArray[i].src
        }
        // console.log("storage file link is " + storageFileLink);

        if (!pannedToNewPdf) {
            pannedToNewPdf = true;
            changePan();
        }
        await addImg(storageFileLink);
        handlePagesUploaded(i + 1);
    }
    handleChangeLoading(false);
    handlePagesUploaded(0);
    handleTotalPagesToUpload(0);
}


/**
 *
 * @param {object} canvas - this is the present canvas which is reference of the currrent whiteboard
 * @param {function} handleChangeLoading - used to toggle state of loader in the whiteboard
 * @param {uuid} whiteBoardID - whiteboard id of current session
 * downloads the current whiteboard as a pdf
 */

async function downloadCanvasAsImage(canvas, handleChangeLoading, whiteboardID, setDownloadingProgress) {
    handleChangeLoading(true);
    const screenHeight = canvas.getHeight();
    const screenWidth = canvas.getWidth();
    var objects = canvas.getObjects().slice();
    objects.sort((a, b) => {
        if (a.top < b.top)
            return -1;
        if (a.top > b.top)
            return 1;
        return 0;
    })
    // console.log("sorted objects", objects);
    if (objects.length === 0) {
        handleChangeLoading(false);
        alert('Canvas is empty')
        return;
    }

    var viewportTransform = canvas.viewportTransform
    // console.log("current viewportTrasform", viewportTransform);
    var currentTop = viewportTransform[5]
    var currentLeft = viewportTransform[4];
    var minY = 0;
    var maxY = 0;
    var minX = 0;
    var maxX = 0;
    for (const obj of objects) {
        minY = Math.min(obj.top, minY);
        maxY = Math.max(obj.top + obj.height * obj.scaleY, maxY);
        minX = Math.min(obj.left, minX);
        let tempRight = obj.left + obj.width
        maxX = Math.max(tempRight, maxX);
    }
    let totalHeight;
    if (minY % screenHeight === 0)
        totalHeight = minY;
    else {
        totalHeight = Math.floor(minY / screenHeight) * screenHeight;
    }

    let pageParts;
    let totalHeightOfCanvas = maxY - minY;
    if (totalHeightOfCanvas / screenHeight <= 5) {
        pageParts = 1
    }
    else {
        pageParts = 5
    }

    let imageSources = [];
    for (let i = 0; i < totalHeightOfCanvas; i = i + (totalHeightOfCanvas / pageParts)) {
        setDownloadingProgress(parseInt((i / totalHeightOfCanvas) * 100))
        console.log(parseInt((i / totalHeightOfCanvas) * 100))
        let dt
        try {
            // console.log(canvas)
            dt = canvas.toDataURL({
                format: 'png',
                height: totalHeightOfCanvas / pageParts,
                top: minY + i + currentTop,
                left: minX + currentLeft,
                width: Math.max(maxX - minX, screenWidth),
            })
            // console.log(dt)
        }
        catch (err) {
            console.log(err)
            handleChangeLoading(false);
            setDownloadingProgress(0);
            alert('Canvas could not be processed');
            return;
        }
        // console.log(dt)
        dt = dt.replace(/^data:image\/[^;]*/, 'data:application/octet-stream')
        dt = dt.replace(
            /^data:application\/octet-stream/,
            'data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=Canvas.png',
        )
        // imageSources.push(dt);
        imageSources.push({
            'url': dt,
            'height': totalHeightOfCanvas / pageParts,
            'width': Math.max(maxX - minX, screenWidth),
        });

    }
    setDownloadingProgress(99);
    var doc = MyDoc(imageSources);
    // console.log("blob loading")
    const blob = await pdf(doc).toBlob();
    setDownloadingProgress(100);
    // handleChangeLoading(false);
    var href = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = href;
    a.download = whiteboardID + "_download.pdf";
    a.click();

    handleChangeLoading(false);
    setDownloadingProgress(0);
}

/**
 *
 * @param {array} imageSources -contains array of image urls
 * @returns {document} -returns the images combined as a document which can be downloaded as pdf
 */

const MyDoc = (imageSources) => (
    <Document>
        {imageSources.map((src, key) =>
            <Page size={{ height: src.height, width: src.width }} orientation={"portrait"} style={{
                display: "flex", marginTop: "auto", marginBottom: "auto", position: 'relative'
            }} key={key}>
                <Image src={src.url}
                    style={{
                        objectFit: "fill",
                        display: "block",
                        height: src.height,
                        width: src.width,
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}
                />
            </Page>)}

    </Document>
);


export default React.forwardRef(WhiteBoard);
