import * as React from "react";
import ChatIcon from '@mui/icons-material/Chat';
import { Tooltip, IconButton, Chip, Drawer, Box, AlertTitle } from '@mui/material';
import { useState } from "react";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useAtom, useSetAtom } from "jotai";
import { messageDataReceivedAtom, updateMessageNotificationAtom } from "../components/jotai/atom/notificationAtom";
import { isChatOpenAtom } from "../components/jotai/atom/isChatOpenAtom";
import { findNameFromSlateUsingUserId } from "../components/helperFunctions";
import { THEME_COLOR, UPLOAD_API_URL } from "../whiteboard/src/config/constants";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { isFileDownloadingInChatAtom } from '../components/jotai/atom/isFileDownloadingInChatAtom';
import { checkAndMarkAsRead } from "../components/utils";
import { messageArrayAtom } from "../components/jotai/atom/messageArrayAtom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Chat = ({ userName, slateRef, accessToken, clientID, isTextFocused, handleIsPdf, handlePptxDocs, addImage, uploadCanvasFromContentLibrary, isTeacher }) => {
    const [isChatOpen, setChatOpen] = useAtom(isChatOpenAtom)
    const [isChatLoading, setIsChatLoading] = useState(false)
    const [updateMessageNotification, setUpdateMessageNotification] = useAtom(updateMessageNotificationAtom)
    const [messageDataReceived, setMessageDataReceived] = useAtom(messageDataReceivedAtom)
    const [isAudioNoteLoading, setIsAudioNoteLoading] = useState(false)
    const setIsDownloading = useSetAtom(isFileDownloadingInChatAtom)
    const setMessageArray = useSetAtom(messageArrayAtom)
    const [messageList] = useAtom(messageArrayAtom)

    let user = {
        'id': clientID,
        'email': userName
    }
    const startChat = () => {
        setChatOpen(true);
        let user = {
            id: clientID
        }
        checkAndMarkAsRead(accessToken, slateRef.current.id, messageList, user)
        let dataTemp = {
            slateId: slateRef.current.id,
            userId: user.id
        }
        MarkInMessageArray(dataTemp, 'read')
        handleCloseUpdateMessageNotification()
    };

    const MarkInMessageArray = (data, markType) => {
        // console.log(data)
        setMessageArray(prev => {

            let tempArray = [];
            if (markType === 'read') {
                tempArray = getReadList(prev, data.userId)
            }
            // console.log(tempArray)
            return tempArray
        }
        )

    }

    const getReadList = (messageList, userId) => {
        let msgArray = JSON.parse(JSON.stringify(messageList))
        for (let msg of msgArray) {
            if (msg.message_read_user_ids) {
                if (!(msg.message_read_user_ids.includes(userId))) {
                    msg.message_read_user_ids.push(userId)
                }
            }
            else {
                msg.message_read_user_ids = [userId]
            }

        }
        return msgArray
    }


    const closeChat = () => {
        setChatOpen(false);
        setIsDownloading(false);
    };

    const handleDrawerEnter = () => {
        // Do something when the mouse enters the drawer
        window.whiteboardScrollDisable = true;
    };

    const handleDrawerLeave = () => {
        // Do something when the mouse leaves the drawer
        window.whiteboardScrollDisable = false;
    };

    const chatButton = (
        <Tooltip title="Chat">
            <IconButton
                // id="chat-button-livekit"
                sx={{ position: 'fixed', left: '10px', bottom: '22.5%', color: THEME_COLOR, zIndex: 100 }}
                onClick={startChat}
                size="small">
                {/* <ChatIcon /> */}
                {/* {<Chip
                    sx={{
                        height: 18,
                        width: 28,
                        backgroundColor: 'rgb(211, 47, 47)',
                        color: '#fff'
                    }}
                    color="secondary"
                    size="small"
                // label={numUnread}
                />} */}
                <img alt='chat_bubble_icon' height='47.5px' width='47.5px' src='/chat-bubble-icon.png'></img>

            </IconButton>
        </Tooltip>
    );

    function scrollMessagesToBottom() {
        // console.log(document.getElementById('last-message-inside-list'))
        if (document.getElementById('last-message-inside-list')) {
            document.getElementById('last-message-inside-list').scrollIntoView(false)
        }
        // console.log(document.getElementById('last-message-inside-list-loader'))
        if (document.getElementById('last-message-inside-list-loader')) {
            document.getElementById('last-message-inside-list-loader').scrollIntoView(false)
        }
    }

    const handleCloseUpdateMessageNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setUpdateMessageNotification(false);
        setMessageDataReceived('')
    };

    return (<>

        <Snackbar open={updateMessageNotification} autoHideDuration={6000} onClose={handleCloseUpdateMessageNotification}>
            <Alert onClose={handleCloseUpdateMessageNotification}
                severity="info"
                sx={{ width: '100%', backgroundColor: '#ffffff', color: THEME_COLOR }}
            >
                <a
                    style={{ cursor: 'pointer', textTransform: 'none', width: '100%' }}
                    onClick={() => {
                        startChat()
                        handleCloseUpdateMessageNotification()
                    }}>
                    <AlertTitle sx={{ color: "black" }}> Message Received</AlertTitle>
                    <span style={{ color: THEME_COLOR }}>
                        {findNameFromSlateUsingUserId(slateRef.current, messageDataReceived.creator_id)}&nbsp;:&nbsp;
                    </span>
                    <span style={{ color: '#000000' }}>
                        {messageDataReceived && messageDataReceived.message_type === 'text' ?
                            (messageDataReceived.text && (messageDataReceived.text).length > 20 ? (messageDataReceived.text.replaceAll('$%$%$', ' ')).substring(0, 20) + ' ...' : messageDataReceived.text.replaceAll('$%$%$', ' ')) :
                            (messageDataReceived.file_name && (messageDataReceived.file_name).length > 20 ? (messageDataReceived.file_name).substring(0, 20) + ' ...' : messageDataReceived.file_name)}
                    </span>
                </a>
            </Alert>
        </Snackbar>

        {chatButton}
        <Drawer
            sx={{
                width: { xs: '100%', sm: '100%', md: '100%', lg: 400, xl: 400 },
                flexShrink: 0,
                zIndex: 2000,
                '& .MuiDrawer-paper': {
                    width: { xs: '100%', sm: '100%', md: '100%', lg: 400, xl: 400 },
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={isChatOpen}
            onClose={closeChat}
            onMouseEnter={handleDrawerEnter}
            onMouseLeave={handleDrawerLeave}
        >
            <Box sx={{ width: '100%', height: '100%', background: 'white', position: 'relative' }}>
                <MessageList
                    user={user}
                    scrollMessagesToBottom={scrollMessagesToBottom}
                    slateID={slateRef.current.id}
                    isChatLoading={isChatLoading}
                    slateObj={slateRef.current}
                    setChatOpen={setChatOpen}
                    closeChat={closeChat}
                    isAudioNoteLoading={isAudioNoteLoading}
                    handleIsPdf={handleIsPdf}
                    handlePptxDocs={handlePptxDocs}
                    addImage={addImage}
                    uploadCanvasFromContentLibrary={uploadCanvasFromContentLibrary}
                    isTeacher={isTeacher}
                />
                <MessageInput user={user} slateID={slateRef.current.id} setIsChatLoading={setIsChatLoading} accessToken={accessToken} isTextFocused={isTextFocused} setIsAudioNoteLoading={setIsAudioNoteLoading} />

            </Box>

        </Drawer>
        {isChatOpen && <Box
            sx={{
                position: 'absolute',
                display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' },
                top: 'calc(50% - 15px)',
                left: 400,
                boxShadow: 2,
                borderRadius: '0 5px 5px 0',
            }}
        >
            <IconButton sx={{
                color: '#000000',
                backgroundColor: '#ffffff',
                borderRadius: '0 5px 5px 0',
                zIndex: 1001,
                width: '10px',
                padding: '35px 10px',
                "&:hover": { backgroundColor: "rgb(255,255,255,0.8)" }
            }}

                onClick={() => {
                    closeChat()
                }}
            >
                <ArrowLeftIcon sx={{ fontSize: '1.75rem', }} />
            </IconButton>
        </Box>}

    </>)

}

export default Chat;