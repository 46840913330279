import { forwardRef, useImperativeHandle } from 'react';
import { useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from '@mui/material';
import pan_icon from '../../images/pan_icon.png'
import eraser_cursor from '../../images/eraser_cursor.png'
import { THEME_COLOR } from '../../whiteboard/src/config/constants';

const MousePointer = forwardRef((props, ref) => {

  const [peerMap, setPeerMap] = useState(new Map());


  useImperativeHandle(ref, () => ({
    setMouseCoords(xCoord, yCoord, peerID, canvasWidth, canvasHeight, color, name, pencilWidth, toolName) {
      // console.log("got data from peer" + xCoord, yCoord, peerID, canvasWidth, canvasHeight, color, pencilWidth, toolName);
      setPeerMap(new Map(peerMap.set(peerID, { x: xCoord * canvasWidth, y: yCoord * canvasHeight, color: color, name: name, pencilWidth: parseInt(pencilWidth), toolName: toolName })));
    }
    ,
    handleDeletePeerMap

  }));

  const handleDeletePeerMap = (peerID) => {
    // console.log('peer id is', peerID)
    // console.log('peer id is', peerMap)
    // Get a copy of the current peerMap state
    // const peerData = peerMap.get(peerID);
    const updatedPeerMap = new Map(peerMap);
    updatedPeerMap.delete(peerID)

    setPeerMap(updatedPeerMap);
    // console.log('peer id is', peerMap)
    // setPeerMap((prevState) => {
    //   return prevState.filter((obj) => obj.peerID !== id)
    // });
  }

  return (
    //zIndex increased so that the mouse pointers are visible when something is drawn on the whiteboard canvas such as background color

    ([...peerMap.keys()].map(peerID => (<>

      {peerMap.get(peerID).toolName == 'Pan' ? <Box
        sx={{
          position: "fixed",
          zIndex: "1",
          top: `${peerMap.get(peerID).y}px`,
          left: `${peerMap.get(peerID).x}px`,

        }}
      >
        <img
          src={pan_icon}
          style={{
            width: "30px",
            height: "30px",
          }}
        />
        {peerMap.get(peerID).name && (
          <Box sx={{
            backgroundColor: THEME_COLOR,
            borderRadius: "10px",
            marginLeft: '.75px',
            padding: '2px 5px',
            alignSelf: 'center',  // Ensures the second box doesn't stretch to the height of the first box
            position: 'relative'  // Prevents the second box from taking the same layout height as the first box
          }}>
            <Typography sx={{
              fontSize: '0.75rem',
              color: peerMap.get(peerID).color == '#ffffff' ? '#000000' : '#ffffff'
            }}>
              {peerMap.get(peerID).name}
            </Typography>
          </Box>
        )}
      </Box> : <> {

        peerMap.get(peerID).toolName == 'Eraser' ? <Box
          sx={{
            position: "fixed",
            zIndex: "1",
            top: `${peerMap.get(peerID).y}px`,
            left: `${peerMap.get(peerID).x}px`,

          }}
        >
          <img
            src={eraser_cursor}
            style={{
              width: "30px",
              height: "30px",
            }}
          />
          {peerMap.get(peerID).name && (
            <Box sx={{
              backgroundColor: THEME_COLOR,
              borderRadius: "10px",
              marginLeft: '.75px',
              padding: '2px 5px',
              alignSelf: 'center',  // Ensures the second box doesn't stretch to the height of the first box
              position: 'relative'  // Prevents the second box from taking the same layout height as the first box
            }}>
              <Typography sx={{
                fontSize: '0.75rem',
                color: peerMap.get(peerID).color == '#ffffff' ? '#000000' : '#ffffff'
              }}>
                {peerMap.get(peerID).name}
              </Typography>
            </Box>
          )}
        </Box> :
          <>
            {peerMap.get(peerID).toolName == 'Pencil' || peerMap.get(peerID).toolName == 'Highlighter' ? <Box sx={{
              zIndex: "1",
              position: "fixed",
              top: peerMap.get(peerID).y - (peerMap.get(peerID).pencilWidth == 9 ? 40 : peerMap.get(peerID).pencilWidth == 6 ? 34 : 28) + 4 + "px",
              left: peerMap.get(peerID).x - 4 + "px",
              display: 'flex',
              flexDirection: 'row'

            }}>
              <Box sx={{
                // height: ,
                // width: ,
                // backgroundColor: peerMap.get(peerID).color,
                // borderRadius: "50%",
                // boxShadow: "0 0 0 2px rgb(220,220,220) "
              }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={peerMap.get(peerID).pencilWidth == 9 ? "40px" : peerMap.get(peerID).pencilWidth == 6 ? "34px" : "28px"}
                  height={peerMap.get(peerID).pencilWidth == 9 ? "40px" : peerMap.get(peerID).pencilWidth == 6 ? "34px" : "28px"}
                  fill="none"
                  stroke={peerMap.get(peerID).color}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18.5 3.5l2 2-12 12-4 1 1-4 12-12z" />
                </svg>
              </Box>
              {peerMap.get(peerID).name && (
                <Box sx={{
                  backgroundColor: peerMap.get(peerID).color,
                  // backgroundColor: THEME_COLOR,
                  borderRadius: "10px",
                  marginLeft: '.75px',
                  padding: '2px 5px',
                  alignSelf: 'center',  // Ensures the second box doesn't stretch to the height of the first box
                  position: 'relative'  // Prevents the second box from taking the same layout height as the first box
                }}>
                  <Typography sx={{
                    fontSize: '0.75rem',
                    color: peerMap.get(peerID).color == '#ffffff' ? '#000000' : '#ffffff'
                  }}>
                    {peerMap.get(peerID).name}
                  </Typography>
                </Box>
              )}
            </Box> :
              <Box
                sx={{
                  position: "fixed",
                  zIndex: "1",
                  top: `${peerMap.get(peerID).y}px`,
                  left: `${peerMap.get(peerID).x - 4}px`,

                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="white"
                  stroke="black"
                  stroke-width="0.5"
                  stroke-linejoin="round"
                  transform="rotate(10)"
                >
                  <path d="M3 2l7 18 3-5 5 5 1-1-5-5 6-2L3 2z" />
                </svg>
                {peerMap.get(peerID).name && (
                  <Box sx={{
                    backgroundColor: THEME_COLOR,
                    borderRadius: "10px",
                    marginLeft: '.75px',
                    padding: '2px 5px',
                    alignSelf: 'center',  // Ensures the second box doesn't stretch to the height of the first box
                    position: 'relative'  // Prevents the second box from taking the same layout height as the first box
                  }}>
                    <Typography sx={{
                      fontSize: '0.75rem',
                      color: peerMap.get(peerID).color == '#ffffff' ? '#000000' : '#ffffff'
                    }}>
                      {peerMap.get(peerID).name}
                    </Typography>
                  </Box>
                )}
              </Box>
            }
          </>
      }
      </>
      }
    </>
    )))
  );

});
export default MousePointer;