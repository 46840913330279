import axios from 'axios';
import { BACKEND_API_URL, UPLOAD_API_URL } from '../config/constants';

function getUrlWithToken(url, token) {
    return url + 'token=' + token;
}

export async function deactivateSessionForSlate(token, requestBody) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'session/deactivateSessionForSlate?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    // console.log(res)
    return res;

}
export async function getSlate(token, requestBody) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'slate/getSlate?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    // console.log(res)
    return res;

}
export async function getSession(token, sessionID) {

    const url = BACKEND_API_URL + 'session/getSession?'
    const res = await axios.get(getUrlWithToken(url, token) + '&id=' + sessionID);
    // console.log(res)
    return res;


}

export async function uploadWhiteboardFiles(token, sessionId, subFolder, requestBody) {
    // console.log(requestBody)
    const url = UPLOAD_API_URL + 'upload/slate/?'
    const res = await axios.post(getUrlWithToken(url, token) + '&sessionId=' + sessionId + '&subFolder=' + subFolder, requestBody);
    // console.log(res)
    return res;

}

export async function convertFileToImage(token, requestBody) {
    // console.log(requestBody)
    const url = UPLOAD_API_URL + 'convertFile/?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    // console.log(res)
    return res;

}


export async function listWhiteboardFiles(token, sessionId, subFolder) {
    // console.log(requestBody)
    const url = UPLOAD_API_URL + 'slate/files?'
    const res = await axios.get(getUrlWithToken(url, token) + '&sessionId=' + sessionId + '&subFolder=' + subFolder);
    // console.log(res)
    return res;

}

export async function getUserFiles(token) {

    const url = UPLOAD_API_URL + 'files?'
    const res = await axios.get(getUrlWithToken(url, token));
    // console.log(res)
    return res;

}

export async function uploadFile(token, requestBody) {
    // console.log(requestBody)
    const url = UPLOAD_API_URL + 'upload/?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    console.log(res)
    return res;

}
export async function getSessionsBySlate(token, requestBody) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'session/getSessionsBySlate?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    // console.log(res)
    return res;

}

export async function shareFile(token, requestBody) {
    // console.log(requestBody)
    const url = UPLOAD_API_URL + 'shareFilesOnEmail/?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    // console.log(res)
    return res;

}

export async function sendMessage(token, slateId, message) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'slate/sendMessage?'
    const res = await axios.post(getUrlWithToken(url, token) + '&slateId=' + slateId + '&message=' + message);
    // console.log(res)
    return res;

}

export async function getProfilePicture(token, email) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'user/getUserProfileUrl?'
    const res = await axios.get(getUrlWithToken(url, token) + '&email=' + email);
    // console.log(res)
    return res;

}

export async function updateSlate(token, requestBody) {

    const url = BACKEND_API_URL + 'slate/updateSlate?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    // console.log(res)
    return res;
}

export async function updateSlateVisibility(token, requestBody) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'slate/updateSlateVisibility?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    // console.log(res)
    return res;

}
export async function getSlates(token) {
    try {
        const url = BACKEND_API_URL + 'slate/getSlates?'
        const res = await axios.get(getUrlWithToken(url, token));
        // console.log(res)
        return res;
    }
    catch (err) {
        console.log(err);
        return null;
    }
}

export async function getUser(token) {

    const url = BACKEND_API_URL + 'user/getUser?'
    const res = await axios.get(getUrlWithToken(url, token));
    // console.log(res)
    return res;


}

export async function addParticipantToSlate(token, requestBody) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'slate/addParticipantsToSlate?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    // console.log(res)
    return res;

}

export async function addHostToSlate(token, requestBody) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'slate/addHostsToSlate?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    // console.log(res)
    return res;

}

export async function removeParticipantFromSlate(token, requestBody) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'slate/removeParticipantFromSlate?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    console.log(res)
    return res;

}

export async function removeHostFromSlate(token, requestBody) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'slate/removeHostFromSlate?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    // console.log(res)
    return res;

}

export async function moveParticipantToHost(token, requestBody) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'slate/moveParticipantToHost?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    // console.log(res)
    return res;

}

export async function moveHostToParticipant(token, requestBody) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'slate/moveHostToParticipant?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    // console.log(res)
    return res;

}

export async function updateSlateTitle(token, requestBody) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'slate/updateSlateTitle?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    // console.log(res)
    return res;

}

export async function sendChatMessage(token, slateId, text, fileName, messageType, fileLink, id) {
    // console.log(requestBody)
    let encodedText = encodeURIComponent(text);
    const url = BACKEND_API_URL + 'message/sendMessage?'
    const res = await axios.post(getUrlWithToken(url, token) + '&slateId=' + slateId + '&text=' + encodedText + '&fileName=' + fileName + '&messageType=' + messageType + '&fileLink=' + fileLink + '&id=' + id);
    // console.log(res)
    return res;
}

export async function getSlateMessages(token, slateId) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'message/getMessages?'
    const res = await axios.get(getUrlWithToken(url, token) + '&slateId=' + slateId);
    // console.log(res)
    return res;
}

export async function updateSessionState(token, sessionId, state, slateId) {
    try {
        const url = BACKEND_API_URL + 'session/updateSessionState?';
        const res = await axios.get(getUrlWithToken(url, token) + '&sessionId=' + sessionId + '&state=' + state + '&slateId=' + slateId);
        // console.log(res)
        return res;
    }
    catch (err) {
        console.log(err);
        return null;
    }
}


export async function recordSessionEvent(token, requestBody) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'session/recordSessionEvent?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    // console.log(res)
    return res;

}

// export async function getContentBySearch(subject, grade, board) {

//     const query = `board:${board} AND subject:${subject} AND grade:${grade}`;

//     // Solr search endpoint
//     const solrEndpoint = 'https://search.teachmatter.com:3002/solr/content_core/select';

//     // Perform the Solr search query
//     axios.get(solrEndpoint, {
//         params: {
//             q: query
//         }
//     })
//         .then(response => {
//             console.log(response.data);
//             return
//         })
//         .catch(error => {
//             console.error('Error:', error);
//         });


// }

// export async function getContentBySearch(searchInput) {

//     const url = 'https://search.teachmatter.com:3002/solr/content_core/select?q=_text_:'
//     const res = await axios.get(url + searchInput + '&wt=json&indent=true%27');
//     console.log(res)
//     return res;


// }

export async function markSlateMessageAsDelivered(token, slateId,) {
    const url = BACKEND_API_URL + 'message/markSlateMessagesAsDelivered?'
    let res
    try {
        res = await axios.post(getUrlWithToken(url, token) + '&slateId=' + slateId);
    }
    catch (err) {
        console.log(err)
    }
    console.log(res)
    // return res;
}


export async function markSlateMessageAsRead(token, slateId) {
    const url = BACKEND_API_URL + 'message/markSlateMessagesAsRead?'
    console.log(token, slateId)
    let res
    try {
        res = await axios.post(getUrlWithToken(url, token) + '&slateId=' + slateId);
    }
    catch (err) {
        console.log(err)
    }
    console.log(res)
    // return res;
}

export async function markMessageAsDeliveredByUser(token, messageId, slateId, creatorId) {
    const url = BACKEND_API_URL + 'message/markMessageAsDeliveredByUser?'
    console.log(token, messageId)
    let res
    try {
        res = await axios.post(getUrlWithToken(url, token) + '&messageId=' + messageId + '&slateId=' + slateId + '&creatorId=' + creatorId);
    }
    catch (err) {
        console.log(err)
    }
    console.log(res)
    // return res;
}

export async function markMessageAsReadByUser(token, messageId, slateId, creatorId) {
    const url = BACKEND_API_URL + 'message/markMessageAsReadByUser?'
    console.log(token, messageId)
    let res
    try {
        res = await axios.post(getUrlWithToken(url, token) + '&messageId=' + messageId + '&slateId=' + slateId + '&creatorId=' + creatorId);
    }
    catch (err) {
        console.log(err)
    }
    console.log(res)
    // return res;
}

export const updateSlateConfig = async (id, updates) => {
    console.log(updates)
    try {
        const response = await axios.put(BACKEND_API_URL + `slate/${id}/config`, updates, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log(response)
    } catch (error) {
        console.error('Error updating slate_config:', error);

    }
};
