// import Joyride from 'react-joyride';
import { THEME_COLOR } from '../whiteboard/src/config/constants';

const steps = [

    {
        target: "#pencil-button-whiteboard",
        content: "Pencil is selected as the default tool",
        disableBeacon: true,
        placement: 'top',
    },
    {
        target: "#upload-files-button-whiteboard",
        content: "Upload files directly to the whiteboard",
        disableBeacon: true,
        placement: 'top',
    },
    {
        target: "#clipping-button",
        content: "Clip your notes from the whiteboard using the clipping tool",
        disableBeacon: true,
        placement: 'right',
    },
    {
        target: "#download-notes-button-whiteboard",
        content: "Download your clipped notes as a pdf",
        disableBeacon: true,
        placement: 'top',
    },

    {
        target: "#chat-button-livekit",
        content: "Chat with other members in the session",
        disableBeacon: true,
        placement: 'right',
    },

]

export default function StudentTour(isLoading) {

    return (
        // <Joyride
        //     autoStart={true}
        //     steps={steps}
        //     scrollToSteps={false}
        //     continuous={true}
        //     showSkipButton={true}
        //     run={isLoading}
        //     disableScrolling={true}
        //     locale={{
        //         last: "End tour",
        //         skip: "Skip"
        //     }}
        //     styles={{
        //         maxHeight: '100%',
        //         options: {
        //             arrowColor: '#e3ffeb',
        //             backgroundColor: '#e3ffeb',
        //             overlayColor: '	#CBC3E3',
        //             primaryColor: THEME_COLOR,
        //             textColor: '#000',
        //             width: 300,
        //             zIndex: 100000,
        //         }
        //     }}
        // />
        <></>
    )
}